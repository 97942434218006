import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";

export function isTokenDateExpired(date: string | undefined) {
    if (!date) {
        return true
    }

    const currentTime = new Date().getTime()
    const expiryTime = new Date(date).getTime()

    return currentTime > expiryTime
}

export function resetAllFiltersToDefault(resetFilterToDefault: any) {
    for (const key of Object.values(TableFilterKeys)) {
        resetFilterToDefault(key)
    }
}