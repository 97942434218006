import React from "react";
import {TableColumn} from "../tables/Table";
import {useNavigate} from "react-router-dom";
import {TechnologicalProcessChart} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {TablePage} from "../../model/utils/TablePage";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {Table} from "../tables/Table";

export interface TableProps {
    data: TablePage<TechnologicalProcessChart>
}

export function TechnologicalProcessChartTable(props: TableProps) {
    const {updateFilterPage} = useFiltersActions()

    const idColumn: TableColumn<TechnologicalProcessChart> = {
        columnName: 'ID',
        extractCellContent: row => row.id
    }

    const targetProductNameColumn: TableColumn<TechnologicalProcessChart> = {
        columnName: 'Наименование целевого изделия',
        extractCellContent: row => row.targetProductName
    }

    const targetProductMainSKUColumn: TableColumn<TechnologicalProcessChart> = {
        columnName: 'Основной артикул целевого изделия',
        extractCellContent: row => row.targetProductMainSKU
    }

    const navigate = useNavigate()

    function actionOnRowClick(row: TechnologicalProcessChart) {
        navigate(`/bill-of-materials?id=${row.id}`)
    }

    return (
        <Table<TechnologicalProcessChart>
            tableColumns={[idColumn, targetProductNameColumn, targetProductMainSKUColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}