import {useDispatch} from "react-redux";
import {useMemo} from "react";
import {bindActionCreators} from "@reduxjs/toolkit";
import {
    filtersActions,
    resetFilterToDefaultAndClearDataAction,
    updateFilterAndClearDataAction
} from "../store/slices/filters.slice";


export const useFiltersActions = () => {
    const dispatch = useDispatch()

    return useMemo(() =>
        bindActionCreators({
            ...filtersActions,
            updateFilterAndClearData: updateFilterAndClearDataAction,
            resetFilterToDefault: resetFilterToDefaultAndClearDataAction
        }, dispatch), [dispatch])
}