import React, {useEffect} from "react";
import {BackButton} from "../../ui/buttons/BackButton";
import {SubmitHandler, useForm} from "react-hook-form";
import {
    useCreateMeasurementUnitMutation,
    useDeleteMeasurementUnitMutation,
    useUpdateMeasurementUnitMutation
} from "../../../store/backend-api/backend.api";
import {MeasurementUnit, UpdateOrCreateMeasurementUnit} from "../../../model/measurement/MeasurementUnit";
import {useNavigate} from "react-router-dom";
import {ControlledInputField} from "../../ui/form-components/controlled/ControlledInputField";
import {Button} from "../../ui/buttons/Button";
import {SeparatingLine} from "../../ui/common/SeparatingLine";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useSelectedMeasurementOkeiActions} from "../../../hooks/useSelectedMeasurementOkeiActions";
import {formButtonGroupStyle, formColumnsStyle, formHeaderStyle, formStyle} from "../../../utils/TailwindStyles";

export interface FormProps {
    data?: MeasurementUnit,
    readonly?: boolean,
    buttonName?: string
}

interface FormFields {
    name: string,
    abbreviation: string,
    okeiCode: string,
    okeiType: string,
    comment: string
}

export function MeasurementUnitFormTemplate(props: FormProps) {
    const [doCreateMeasurementUnit, {
        data: createdMeasurementUnitId,
        isSuccess: isSuccessCreate
    }] = useCreateMeasurementUnitMutation()
    const [doUpdateMeasurementUnit, {isSuccess: isSuccessUpdate}] = useUpdateMeasurementUnitMutation()
    const [doDeleteMeasurementUnit, {isSuccess: isSuccessDelete}] = useDeleteMeasurementUnitMutation()

    const {resetSelectedMeasurementOkei} = useSelectedMeasurementOkeiActions()
    const selectedMeasurementOkei = useAppSelector(state => state.selectedMeasurementOkei.measurementOkei)

    const navigate = useNavigate()
    const defaultValues: FormFields = props.data ? {
        name: props.data.name,
        abbreviation: props.data.abbreviation,
        okeiCode: props.data.okeiCode ? String(props.data.okeiCode) : "–",
        okeiType: props.data.okeiType || "–",
        comment: props.data.comment || ""
    } : {
        name: "",
        abbreviation: "",
        okeiCode: "",
        okeiType: "",
        comment: ""
    }
    const {handleSubmit, control, setValue, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: defaultValues
    })
    const onSubmit: SubmitHandler<FormFields> = data => {
        const measurementUnit: UpdateOrCreateMeasurementUnit = {
            name: data.name,
            abbreviation: data.abbreviation,
            okeiCode: isNaN(+data.okeiCode) ? null : +data.okeiCode,
            okeiType: data.okeiType === "–" ? null : data.okeiType,
            comment: data.comment || null
        }
        if (props.data && isDirty) {
            doUpdateMeasurementUnit({id: props.data.id, body: measurementUnit})
        } else if (!props.data) {
            doCreateMeasurementUnit(measurementUnit)
        }
    }

    useEffect(() => {
        if (isSuccessCreate && createdMeasurementUnitId) {
            navigate(`/measurement-unit?id=${createdMeasurementUnitId}`)
        }
        if (isSuccessUpdate) {
            navigate(`/measurement-unit?id=${props.data!.id}`)
        }
        if (isSuccessDelete) {
            navigate(`/measurement-unit/guide`)
        }
    }, [isSuccessCreate, isSuccessUpdate, isSuccessDelete]);

    useEffect(() => {
        if (selectedMeasurementOkei) {
            setValue("name", selectedMeasurementOkei.name)
            setValue("abbreviation", selectedMeasurementOkei.abbreviation)
            setValue("okeiCode", selectedMeasurementOkei.id.toString())
            setValue("okeiType", selectedMeasurementOkei.okeiType)
            resetSelectedMeasurementOkei()
        }
    }, [selectedMeasurementOkei]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
            <div className={formHeaderStyle}>
                <p>ID: {props.data?.id ? props.data.id : "не определен"}</p>
                <BackButton/>
            </div>
            <div className={formColumnsStyle}>
                <ControlledInputField control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} name={"name"} label={"Наименование"} additionalStyles={"w-[423px]"} readonly={props.readonly}/>
                <ControlledInputField control={control} rules={{}} name={"okeiCode"} label={"Код в ОКЕИ"}
                                      additionalStyles={"w-[423px]"} readonly={true}/>
                <ControlledInputField control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} name={"abbreviation"} label={"Условное обозначение"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
                <ControlledInputField control={control} rules={{}} name={"okeiType"} label={"Группа в ОКЕИ"}
                                      additionalStyles={"w-[423px]"} readonly={true}/>
                <ControlledInputField control={control} rules={{}} name={"comment"} label={"Комментарий"}
                                      additionalStyles={"w-[423px]"} readonly={props.readonly}/>
            </div>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {!props.data && <Button buttonName={"Выбрать из ОКЕИ"} onClickButton={() => {
                    navigate(`/measurement-okei/guide`)
                }}/>}
                {props.data &&
                    <Button buttonName={"Удалить"} onClickButton={() => doDeleteMeasurementUnit(props.data!.id)}/>}
                {!props.readonly &&
                    <Button buttonName={props.buttonName!} onClickButton={handleSubmit(onSubmit)}/>}
                {props.readonly &&
                    <Button buttonName={"Редактировать"} onClickButton={() => {
                        navigate(`/measurement-unit/edit?id=${props.data!.id}`)
                    }}/>}
            </div>
        </form>
    )
}