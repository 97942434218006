import React, {useEffect, useState} from "react";
import {AdministrativeRolesFormRead} from "../../components/administrative-roles/AdministrativeRolesFormRead";
import {useSearchParams} from "react-router-dom";
import {useGetAdministrativeRoleQuery} from "../../store/backend-api/backend.api";

interface FormProps{

}

export function ReadAdministrativeRolesForm(props: FormProps) {
    const [searchParams] = useSearchParams()
    const id = Number(searchParams.get("id"))
    const {data, isSuccess} = useGetAdministrativeRoleQuery(id)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [data])

    return (<div key={key}>
        {isSuccess && <AdministrativeRolesFormRead data={data}/>}
</div>)
}