import {MeasurementUnit} from "../../../model/measurement/MeasurementUnit";
import {MeasurementUnitFormTemplate} from "./MeasurementUnitFormTemplate";

interface FormProps {
    data: MeasurementUnit
}

export function MeasurementUnitFormEdit(props: FormProps) {
    return (
        <MeasurementUnitFormTemplate data={props.data} buttonName={"Сохранить"}/>
    )
}