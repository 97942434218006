import React from "react";
import {ProductionOrderFormTemplate} from "./ProductionOrderFormTemplate";
import {ProductionOrder} from "../../../model/production-order/ProductionOrder";

export interface FormProps {
    data: ProductionOrder
}

export function ProductionOrderFormEdit(props: FormProps) {

    return (<ProductionOrderFormTemplate data={props.data}/>
    )
}