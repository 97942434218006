import React, {useEffect} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {NavBarComponent} from "./components/main-components/NavBarComponent";
import {MainComponent} from "./components/main-components/MainComponent";
import {FilterMenuComponent} from "./components/main-components/FilterMenuComponent";
import {useAppSelector} from "./hooks/useAppSelector";
import {NewFilterMenuComponent} from "./components/main-components/NewFilterMenuComponent";
import {ToastList} from "./components/toasts/ToastList";
import {createLocationFilterName} from "./utils/CommonUtils";
import {useLocation} from "react-router-dom";
import {TableFilterKeys} from "./model/filters/TableFiltersDefinitions";
import {LoadingIndicator} from "./components/ui/common/LoadingIndicator";
import {UpdateFiltersPages} from "./utils/UpdateFiltersPages";
import {useFiltersActions} from "./hooks/useFiltersActions";

function App() {
    const {updateFilterPage} = useFiltersActions()

    const filterMenuExists = useAppSelector(state => state.filtersForMenuFilters.isExist)
    const locationFilterName = createLocationFilterName(useLocation().pathname)
    const tableFilterKey = Object.keys(TableFilterKeys).find(k => TableFilterKeys[k as keyof typeof TableFilterKeys] === locationFilterName) as keyof typeof TableFilterKeys;
    const toasts = useAppSelector(state => state.toast.toasts)
    const isLoading = useAppSelector(state => state.loading.isLoading)

    useEffect(() => {
        window.addEventListener('beforeunload', () => UpdateFiltersPages(updateFilterPage));

        return () => {
            window.removeEventListener('beforeunload', () => UpdateFiltersPages(updateFilterPage));
        };
    }, []);

    return (

            <div className="flex max-w-full overflow-hidden">
                <LoadingIndicator isLoading={isLoading}/>
                <NavBarComponent/>
                <MainComponent/>
                {filterMenuExists && <FilterMenuComponent/>}
                {tableFilterKey && <NewFilterMenuComponent tableFilterKey={tableFilterKey}/>}
                {toasts.length > 0 && <ToastList/>}
            </div>

    );
}

export default App;
