import type {BaseQueryFn, FetchArgs, FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import {backendApi, defBaseUrl} from "./backend.api";
import {tokensActions} from "../slices/tokens.slice";
import {RootState} from "../index";
import {RefreshTokenResponse} from "../../model/security/Tokens";
import {isTokenDateExpired, resetAllFiltersToDefault} from "../utils/TokenUtils";
import {resetFilterToDefaultAndClearDataAction} from "../slices/filters.slice";

const baseQuery = fetchBaseQuery({
    baseUrl: defBaseUrl(),
    prepareHeaders: (headers, {getState}) => {
        headers.set(
            'Time-Zone',
            Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        const tokens = (getState() as RootState).localStorage.tokens
        if (!headers.has('Authorization') && tokens) {
            headers.set('Authorization', `Bearer ${tokens.accessToken}`)
        }

        return headers;
    },
})

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const refreshToken = (api.getState() as RootState).localStorage.tokens?.refreshToken
    const refreshTokenExpiry = (api.getState() as RootState).localStorage.tokens?.refreshTokenExpiry

    let result = await baseQuery(args, api, extraOptions)

    if (result.error && result.error.status === 401 && refreshToken) {
        if (!isTokenDateExpired(refreshTokenExpiry)) {
            const refreshedTokens = await baseQuery({
                url: '/api/v1/jwt/refresh',
                method: 'POST',
                headers: {'Authorization': `Bearer ${refreshToken}`}
            }, api, extraOptions)
            if (refreshedTokens.data) {
                const tokens = refreshedTokens.data as RefreshTokenResponse
                api.dispatch(tokensActions.setRefreshedToken(tokens))
                result = await baseQuery(args, api, extraOptions)
            } else {
                api.dispatch(tokensActions.invalidateTokens())
                api.dispatch(backendApi.util.resetApiState())
                resetAllFiltersToDefault(api.dispatch(resetFilterToDefaultAndClearDataAction))
            }
        } else {
            api.dispatch(tokensActions.invalidateTokens())
            api.dispatch(backendApi.util.resetApiState())
            resetAllFiltersToDefault(api.dispatch(resetFilterToDefaultAndClearDataAction))
        }
    } else if (result.error && result.error.status === 401) {
        api.dispatch(tokensActions.invalidateTokens())
    }

    return result
}