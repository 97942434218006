import {PiArrowLineDownLight, PiTrashLight, PiX} from "react-icons/pi";
import {
    useDeleteNomenclatureAttachmentMutation,
    useGetNomenclatureAttachmentsQuery,
    useLazyGetFileQuery
} from "../../store/backend-api/backend.api";
import {FilesUploader} from "../ui/common/FilesUploader";
import ReactModal from "react-modal";
import React, {useEffect, useState} from "react";
import {isFileOpenable, ModalForOpenFile} from "./ModalForOpenFile/ModalForOpenFile";
import {NomenclatureAttachment} from "../../model/nomenclature/NomenclatureForm";
import {useHasAuthority} from "../../hooks/TokensHooks";
import {DownloadFilesButton} from "../storage/DownloadFilesButton";

interface ModalProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    nomenclatureId: number
}

export function ModalForWorkingWithNomenclatureAttachments(props: ModalProps) {
    const {
        data: nomenclatureAttachments,
        isSuccess: isSuccessGetNomenclatureAttachment
    } = useGetNomenclatureAttachmentsQuery(props.nomenclatureId)
    const [doDeleteNomenclatureAttachment] = useDeleteNomenclatureAttachmentMutation()
    const [downloadFile, {data: downloadedFile, isSuccess: isSuccessDownloadFile}] = useLazyGetFileQuery()
    const [downloadableFileName, setDownloadableFileName] = useState<string | null>(null)
    const [modalForOpenFileIsOpen, setModalForOpenFileIsOpen] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<NomenclatureAttachment | null>(null)
    const hasAuthority = useHasAuthority(["NOMENCLATURE_MANAGE", "TECHNOLOGICAL_PROCESS_CHART_MANAGE"])
    const [key, setKey] = useState(0)

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [props.nomenclatureId]);

    useEffect(() => {
        if (isSuccessDownloadFile && downloadedFile && downloadableFileName) {
            const url = window.URL.createObjectURL(downloadedFile);
            const a = document.createElement('a');
            a.href = url;
            a.download = downloadableFileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            setDownloadableFileName(null);
        }

    }, [downloadedFile, downloadableFileName]);

    return (
        <ReactModal key={key} isOpen={props.isOpen}
                    onRequestClose={() => props.setIsOpen(false)}>
            <div className="flex flex-row justify-between">
                <p className="font-light text-lg -mt-1">Загруженные файлы</p>
                <button type={"button"}
                        title="Закрыть"
                        onClick={() => props.setIsOpen(false)}
                        className="-mr-1 -mt-2 text-black/[.30] rounded-[5px]">
                    <PiX className="h-7 w-7"/>
                </button>
            </div>
            <table className="font-light text-base border border-black mt-5">
                <thead>
                <tr className="text-left">
                    <th className="text-base font-normal border border-black px-2">Наименование файла</th>
                    <th className="text-base font-normal border border-black px-2">Загружен</th>
                    <th className="border border-black"></th>
                    {hasAuthority && <th className="border border-black"></th>}
                </tr>
                </thead>
                <tbody>
                {isSuccessGetNomenclatureAttachment && nomenclatureAttachments.map(file =>
                    <tr>
                        {isFileOpenable(file.fileName) &&
                            <td className="border border-black px-2 hover:bg-blue-50/[.70] cursor-pointer"
                                onClick={() => {
                                    setSelectedFile(file)
                                    setModalForOpenFileIsOpen(true)
                                }}>{file.fileName}</td>}
                        {!isFileOpenable(file.fileName) &&
                            <td className="border border-black px-2">{file.fileName}</td>}
                        <td className="border border-black px-2">{file.creationTime}</td>
                        <td className="border border-black px-0.5">
                            <button type='button' onClick={() => {
                                downloadFile(file.fileId);
                                setDownloadableFileName(file.fileName);
                            }}
                               title={"Скачать файл"}><PiArrowLineDownLight
                                className="mt-[2px] h-[18px] w-[18px] hover:text-blue-800"/></button></td>
                        {hasAuthority && <td className="border border-black px-0.5"
                                             onClick={() => doDeleteNomenclatureAttachment({
                                                 nomenclatureId: props.nomenclatureId,
                                                 fileId: file.fileId
                                             })}>
                            <PiTrashLight className="mt-[2px] h-5 w-5 hover:text-red-900" title="Удалить файл"/>
                        </td>}
                    </tr>)}
                </tbody>
            </table>
            <div className="border-b-[1px] border-slate-400/[.80] border-dotted h-1 w-full my-6"></div>
            <div className="flex flex-row justify-end">
                {nomenclatureAttachments && <DownloadFilesButton name={"Скачать все файлы"}
                                                                 filesIds={nomenclatureAttachments.map(it => it.fileId)}/>}
                {hasAuthority && <FilesUploader nomenclatureId={props.nomenclatureId}/>}
            </div>
            {selectedFile && <ModalForOpenFile modalForOpenFileIsOpen={modalForOpenFileIsOpen}
                                               setModalForOpenFileIsOpen={setModalForOpenFileIsOpen}
                                               selectedFile={selectedFile}
                                               setSelectedFile={setSelectedFile}/>
            }
        </ReactModal>
    )
}