import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {OptionType} from "../../model/option-type/OptionType";
import {InternalMaterialsForm} from "./InternalMaterialsForm";
import {InternalProductsForm} from "./InternalProductsForm";
import {SaveButton} from "../ui/buttons/SaveButton";
import {Material, Product} from "../../model/technological-process-chart/TechnologicalProcessChart";
import isEqual from "lodash.isequal";
import {
    useCreateMaterialOfBillOfMaterialsMutation,
    useCreateProductOfBillOfMaterialsMutation,
    useDeleteMaterialOfBillOfMaterialsMutation,
    useDeleteProductOfBillOfMaterialsMutation,
    useUpdateMaterialOfBillOfMaterialsMutation,
    useUpdateProductOfBillOfMaterialsMutation
} from "../../store/backend-api/backend.api";

interface FormProps {
    stepId: number,
    products: Product[],
    materials: Material[],
    billOfMaterialsId: number
}

interface FormFields {
    materials: {
        materialId: number | null,
        materialName: OptionType<number> | null,
        materialQuantity: string,
        materialStep: number | null,
        targetBillOfMaterialsId: OptionType<number> | null
    }[],
    products: {
        productId: number | null,
        myProductId: string,
        isTarget: boolean,
        productName: OptionType<number> | null,
        productQuantity: string,
        productSize: string,
        sizeMeasurementUnit: OptionType<number> | null,
        drawingNumber: string,
        outgoingInStep: number | null,
        includedInStep: number | null,
        tariff: string,
        defaultProductNomenclatureId: number | null
    }[]
}

export function SelectMaterialsForStepForm(props: FormProps) {
    const [doUpdateProductOfBillOfMaterials] = useUpdateProductOfBillOfMaterialsMutation()
    const [doCreateProductOfBillOfMaterials] = useCreateProductOfBillOfMaterialsMutation()
    const [doDeleteProductOfBillOfMaterials] = useDeleteProductOfBillOfMaterialsMutation()
    const [doUpdateMaterialOfBillOfMaterials] = useUpdateMaterialOfBillOfMaterialsMutation()
    const [doCreateMaterialOfBillOfMaterials] = useCreateMaterialOfBillOfMaterialsMutation()
    const [doDeleteMaterialOfBillOfMaterials] = useDeleteMaterialOfBillOfMaterialsMutation()
    const [deletedProductsIds, setDeletedProductsIds] = useState<number[]>([])
    const [deletedMaterialsIds, setDeletedMaterialsIds] = useState<number[]>([])
    const {control, handleSubmit, formState: {isDirty, defaultValues}, setValue, resetField} = useForm<any>({
        mode: "onChange", defaultValues: {
            products: props.products.filter(product => product.isMaterialIn === null || product.isMaterialIn === props.stepId).map(product => ({
                productId: product.id,
                myProductId: product.id.toString(),
                isTarget: product.isTarget,
                productName: {
                    label: `${product.nomenclature.fullName}, ${product.nomenclature.measurementUnit.name}`,
                    value: product.nomenclature.id
                },
                productQuantity: product.quantity,
                productSize: product.size.value !== null ? product.size.value : "",
                sizeMeasurementUnit: product.size.measurementUnit !== null ? {
                    label: `${product.size.measurementUnit.name}`,
                    value: product.size.measurementUnit.id
                } : null,
                drawingNumber: product.nomenclature.drawingNumber || "",
                outgoingInStep: product.isProductIn,
                includedInStep: product.isMaterialIn,
                tariff: product.tariff !== null ? product.tariff : "",
                defaultProductNomenclatureId: product.nomenclature.id
            })),
            materials: props.materials.filter(material => material.isMaterialIn === props.stepId || material.isMaterialIn === null).map(material => ({
                materialId: material.id,
                materialName: ({
                    label: `${material.nomenclature.fullName}, ${material.nomenclature.measurementUnit.name}`!,
                    value: material.nomenclature.id
                }!)!,
                materialQuantity: material.quantity !== null ? material.quantity : "",
                materialStep: material.isMaterialIn,
                targetBillOfMaterialsId: material.targetBillOfMaterialsId !== null ? {
                    label: `${material.nomenclature.name}`,
                    value: material.targetBillOfMaterialsId
                } : null
            }))
        }
    })
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        if (!isEqual(data, defaultValues)) {
            if (!isEqual(data.products, defaultValues!.products)) {
                data.products.forEach((product) => {
                    if (product.productId !== null && !isEqual(product, defaultValues!.products.find((value: any) => value.productId === product.productId))) {
                        doUpdateProductOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            productId: product.productId,
                            body: {
                                quantity: product.productQuantity,
                                nomenclatureId: product.productName!.value,
                                size: {
                                    value: product.productSize !== "" ? product.productSize : null,
                                    measurementUnitId: product.sizeMeasurementUnit !== null ? product.sizeMeasurementUnit.value : null
                                },
                                isMaterialIn: product.includedInStep ? product.includedInStep : null,
                                isProductIn: product.outgoingInStep ? +product.outgoingInStep : null,
                                tariff: product.tariff !== "" ? product.tariff : null
                            }
                        })
                    }
                    if (product.productId === null) {
                        doCreateProductOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            body: {
                                quantity: product.productQuantity,
                                nomenclatureId: product.productName!.value,
                                size: {
                                    value: product.productSize !== "" ? product.productSize : null,
                                    measurementUnitId: product.sizeMeasurementUnit !== null ? product.sizeMeasurementUnit.value : null
                                },
                                isMaterialIn: product.includedInStep ? +product.includedInStep : null,
                                isProductIn: product.outgoingInStep ? +product.outgoingInStep : null,
                                tariff: product.tariff !== "" ? product.tariff : null
                            }
                        })
                    }
                })
                if (deletedProductsIds.length !== 0) {
                    deletedProductsIds.forEach(id => doDeleteProductOfBillOfMaterials({
                        billOfMaterialsId: props.billOfMaterialsId,
                        productId: id
                    }))
                }
            }
            if (!isEqual(data.materials, defaultValues!.materials)) {
                data.materials.forEach(material => {
                    if (material.materialId !== null && !isEqual(material, defaultValues!.materials.find((value: any) => value.materialId === material.materialId))) {
                        doUpdateMaterialOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            materialId: material.materialId,
                            body: {
                                quantity: material.materialQuantity !== "" ? material.materialQuantity : null,
                                nomenclatureId: material.materialName!.value,
                                isMaterialIn: material.materialStep,
                                targetBillOfMaterialsId: material.targetBillOfMaterialsId !== null ? material.targetBillOfMaterialsId.value : null
                            }
                        })
                    }
                    if (material.materialId === null) {
                        doCreateMaterialOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            body: {
                                quantity: material.materialQuantity !== "" ? material.materialQuantity : null,
                                nomenclatureId: material.materialName!.value,
                                isMaterialIn: material.materialStep,
                                targetBillOfMaterialsId: material.targetBillOfMaterialsId !== null ? material.targetBillOfMaterialsId.value : null
                            }
                        })
                    }
                })
                if (deletedMaterialsIds.length !== 0) {
                    deletedMaterialsIds.forEach(id => doDeleteMaterialOfBillOfMaterials({
                        billOfMaterialsId: props.billOfMaterialsId,
                        materialId: id
                    }))
                }
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)}>
            <InternalMaterialsForm control={control} setValue={setValue} resetField={resetField}
                                   deletedMaterialsIds={deletedMaterialsIds}
                                   setDeletedMaterialsIds={setDeletedMaterialsIds} editFromModal={true}
                                   stepId={props.stepId}/>
            <div className="border-b-[1px] border-slate-400/[.80] border-dotted h-1 w-full mt-3 mb-5"></div>
            <InternalProductsForm control={control} setValue={setValue} resetField={resetField}
                                  deletedProductsIds={deletedProductsIds} setDeletedProductsIds={setDeletedProductsIds}
                                  editFromModalForChoiceMaterials={true} stepId={props.stepId}/>
            {isDirty && <>
                <div className="border-b-[1px] border-slate-400/[.80] border-dotted h-1 w-full mt-3 mb-6"></div>
                <div className="flex flex-row justify-end">
                    <div>
                        <SaveButton onClick={handleSubmit(onClickSaveButton)}/>
                    </div>
                </div>
            </>}
        </form>
    )
}