import {
    DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
    DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER,
    DEFAULT_INDIVIDUALS_GUIDE_FILTER, DEFAULT_MEASUREMENT_OKEI_GUIDE_FILTER, DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER,
    DEFAULT_NOMENCLATURE_GUIDE_FILTER, DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER,
    DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER, DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER,
    DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER,
    DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
    DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER,
    DEFAULT_USER_GUIDE_FILTER
} from "./DefaultFilters";
import {UserGuideFilter} from "../users/UserGuideFilter";
import {NomenclatureGuideFilter} from "../nomenclature/NomenclatureGuideFilter";
import {ProductionOrderExecutionJournalFilter} from "../production-order-execution/ProductionOrderExecution";
import {
    AdministrativeAndFunctionalZoneGuideFilter
} from "../administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {MeasurementGuideFilter} from "../measurement/MeasurementGuideFilter";
import {IndividualsGuideFilter} from "../individuals/Individuals";
import {GuideFilter} from "./GuideFilter";
import {TechnologicalProcessChartGuideFilter} from "../technological-process-chart/TechnologicalProcessChart";
import {AdministrativeRoleGuideFilter} from "../administrative-roles/AdministrativeRole";
import {ProductionStageGuideFilter} from "../production-stage/ProductionStage";
import {TechnologicalOperationGuideFilter} from "../technological-operation/TechnologicalOperationGuideFilter";
import {ProductionOrderJournalFilter} from "../production-order/ProductionOrder";
import {MeasurementOkeiGuideFilter} from "../measurement/MeasurementOkeiGuideFilter";

export enum TableFilterKeys {
    NOMENCLATURE_GUIDE_FILTER = 'nomenclatureGuideFilter',
    USER_GUIDE_FILTER = 'userGuideFilter',
    PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER = 'production-orderExecutionDocument-journalFilter',
    INDIVIDUALS_GUIDE_FILTER = 'individualsGuideFilter',

    MEASUREMENT_UNIT_GUIDE_FILTER = 'measurement-unitGuideFilter',
    MEASUREMENT_OKEI_GUIDE_FILTER = 'measurement-okeiGuideFilter',
    ADMINISTRATIVE_ROLE_GUIDE_FILTER = 'administrative-rolesGuideFilter',
    TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER = 'technological-process-chartGuideFilter',
    PRODUCTION_STAGE_GUIDE_FILTER = 'production-stagesGuideFilter',
    TECHNOLOGICAL_OPERATION_GUIDE_FILTER = 'technological-operationGuideFilter',
    ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER = 'administrative-and-functional-zoneGuideFilter',
    PRODUCTION_ORDER_JOURNAL_FILTER = 'production-orderDocument-journalFilter',
    PRODUCTION_ORDER_ARCHIVE_FILTER = 'production-orderArchiveReportFilter',
}

export function getFilterType(filterKey: TableFilterKeys) {
    if (filterKey === TableFilterKeys.USER_GUIDE_FILTER) {
        return DEFAULT_USER_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.NOMENCLATURE_GUIDE_FILTER) {
        return DEFAULT_NOMENCLATURE_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER) {
        return DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER
    }
    if (filterKey === TableFilterKeys.INDIVIDUALS_GUIDE_FILTER) {
        return DEFAULT_INDIVIDUALS_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER) {
        return DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER) {
        return DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER) {
        return DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER) {
        return DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER) {
        return DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER) {
        return DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER
    }
    if (filterKey === TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER) {
        return DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER) {
        return DEFAULT_MEASUREMENT_OKEI_GUIDE_FILTER
    }
    if (filterKey === TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER) {
        return DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER
    }
}

export type TableFilterType = UserGuideFilter | NomenclatureGuideFilter | ProductionOrderExecutionJournalFilter | AdministrativeAndFunctionalZoneGuideFilter | MeasurementGuideFilter | IndividualsGuideFilter | TechnologicalProcessChartGuideFilter | GuideFilter | AdministrativeRoleGuideFilter | ProductionStageGuideFilter | TechnologicalOperationGuideFilter | ProductionOrderJournalFilter | MeasurementOkeiGuideFilter

export type AllTableFilterType = UserGuideFilter | NomenclatureGuideFilter | ProductionOrderExecutionJournalFilter | AdministrativeAndFunctionalZoneGuideFilter | MeasurementGuideFilter | IndividualsGuideFilter | TechnologicalProcessChartGuideFilter | GuideFilter | AdministrativeRoleGuideFilter | ProductionStageGuideFilter | TechnologicalOperationGuideFilter | ProductionOrderJournalFilter | MeasurementOkeiGuideFilter
