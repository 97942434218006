import React, {useEffect, useState} from "react";
import {NomenclatureFormEdit} from "../../components/nomenclature/form/NomenclatureFormEdit";
import {useSearchParams} from "react-router-dom";
import {useGetNomenclatureQuery} from "../../store/backend-api/backend.api";

export function EditNomenclatureForm () {
    const [searchParams] = useSearchParams()
    const id = Number(searchParams.get("id"))
    const {data, isSuccess} = useGetNomenclatureQuery(id)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [data])

    return (<div key={key}>
        {isSuccess && (<NomenclatureFormEdit data={data}/>)}
    </div>)
}