import {
    AdditionalEquipmentForPosition,
    PositionsForProductionOrderExecution
} from "../../../model/production-order-execution/ProductionOrderExecution";
import {SeparatingLine} from "../../ui/common/SeparatingLine";

interface ComponentProps {
    positions: PositionsForProductionOrderExecution
}

export function PositionsComponent(props: ComponentProps) {
    const sortPositions: AdditionalEquipmentForPosition[] = [...props.positions.additionalEquipment].sort((position1: AdditionalEquipmentForPosition, position2: AdditionalEquipmentForPosition) => position1.number - position2.number)
    return (
        <div className="pt-[18px]">
            <SeparatingLine label={"Готовая продукция"}/>
            <p className="text-base font-light">{`${props.positions.mainProduct.number}. ${props.positions.mainProduct.details.name}, ${props.positions.mainProduct.details.mainSKU} – ${props.positions.mainProduct.quantity} ${props.positions.mainProduct.details.measurementUnit.name}`}</p>
            <SeparatingLine label={"Дополнительная комплектация"}/>
            <div className="flex flex-col gap-y-2">
                {sortPositions.map(position =>
                    <p key={position.number} className="text-base font-light">{`${position.number}. ${position.details.name}, ${position.details.mainSKU} – ${position.quantity} ${position.details.measurementUnit.name}`}</p>)}
            </div>
        </div>
    )
}