import React, {useEffect} from "react";
import {useLazyGetUserCardQuery} from "../../store/backend-api/backend.api";
import {useAppSelector} from "../../hooks/useAppSelector";
import {ProfileEdit} from "../../components/profile/ProfileEdit";

export function EditProfile() {
    const [doGetUserCard, {data: userCard, isSuccess: isSuccessGetUserCard}] = useLazyGetUserCardQuery()
    const tokens = useAppSelector(state => state.localStorage.tokens)

    useEffect(() => {
        if (tokens) {
            doGetUserCard()
        }
    }, [tokens]);

    return (<>
        {isSuccessGetUserCard && userCard && <ProfileEdit data={userCard}/>}
    </>)
}