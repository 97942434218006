import React from "react";
import {NomenclatureFormTemplate} from "./NomenclatureFormTemplate";
import {NomenclatureForm} from "../../../model/nomenclature/NomenclatureForm";
import {useGetMeasurementUnitQuery, useUpdateNomenclatureMutation} from "../../../store/backend-api/backend.api";

interface FormProps {
    data: NomenclatureForm,
    editFromBillOfMaterialInterface?: boolean,
    onSaveFromBillOnMaterialsUI?: (state: boolean) => void
}

export function NomenclatureFormEdit(props: FormProps) {
    const {
        data: defaultMeasurementUnit,
        isSuccess: isSuccessGetDefaultMeasurementUnit
    } = useGetMeasurementUnitQuery(props.data.measurementUnitId)
    const [doUpdateNomenclature, {isSuccess: isSuccessUpdate}] = useUpdateNomenclatureMutation()

    function onSubmit(nomenclature: NomenclatureForm) {
        doUpdateNomenclature({id: nomenclature.id!, body: nomenclature})
    }

    return (<>
        {isSuccessGetDefaultMeasurementUnit && (
            <NomenclatureFormTemplate data={props.data}
                                      editFromBillOfMaterialsUI={props.editFromBillOfMaterialInterface}
                                      onSaveFromBillOnMaterialsUI={props.onSaveFromBillOnMaterialsUI}
                                      onSubmit={onSubmit} buttonName={"Сохранить"} isSuccessUpdate={isSuccessUpdate}
                                      defaultMeasurementUnit={{
                                          label: defaultMeasurementUnit.name!,
                                          value: defaultMeasurementUnit.id!
                                      }}
            />)}
    </>)
}