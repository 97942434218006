import React from "react";
import {NomenclatureFormTemplate} from "./NomenclatureFormTemplate";
import {NomenclatureForm} from "../../../model/nomenclature/NomenclatureForm";
import {useGetMeasurementUnitQuery} from "../../../store/backend-api/backend.api";

interface FormProps {
    data: NomenclatureForm
}

export function NomenclatureFormRead(props: FormProps) {
    const {
        data: defaultMeasurementUnit,
        isSuccess: isSuccessGetDefaultMeasurementUnit
    } = useGetMeasurementUnitQuery(props.data.measurementUnitId)

    return (<>
        {isSuccessGetDefaultMeasurementUnit && (
            <NomenclatureFormTemplate data={props.data} readonly={true}
                                      defaultMeasurementUnit={{
                                          label: defaultMeasurementUnit.name!,
                                          value: defaultMeasurementUnit.id!
                                      }}/>)}
    </>)
}