import React from "react";
import {Tab, Tabs} from "../tabs/Tabs";
import {BasicInformationFormTemplate} from "./BasicInformationFormTemplate";
import {Material, Product, Stage, Step} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {NomenclatureForm} from "../../model/nomenclature/NomenclatureForm";
import {MaterialsFormTemplate} from "./MaterialsFormTemplate";
import {ProductsFormTemplate} from "./ProductsFormTemplate";
import {useNavigate} from "react-router-dom";
import {StagesAndStepsFormTemplate} from "./StagesAndStepsFormTemplate";
import {BackButton} from "../ui/buttons/BackButton";
import {formStyle} from "../../utils/TailwindStyles";

interface FormProps {
    products: Product[],
    nomenclature: NomenclatureForm,
    materials: Material[],
    steps: Step[],
    stages: Stage[],
    billOfMaterialsId: number,
    tabId: number | null
}

export function TechnologicalProcessChartFormReadAndEdit(props: FormProps) {
    const selectedTabId: number = props.tabId !== null ? props.tabId : 0
    const navigate = useNavigate()
    function onClickTab (id: number) {
        navigate(`/bill-of-materials?id=${props.billOfMaterialsId}&tab=${id}`)
    }
    const tabs: Tab[] = [{
        id: 0,
        label: "Основная информация"
    },
        {
            id: 1,
            label: "Материалы"
        },
        {
            id: 2,
            label: "Целевые и промежуточные изделия"
        },
        {
            id: 3,
            label: "Этапы"
        }]

    return (<div className={formStyle}>
        <div className="flex flex-row justify-between">
            <Tabs tabs={tabs} selectedTabId={selectedTabId} onClick={onClickTab}/>
            <BackButton onClick={() => navigate('/technological-process-chart/guide')}/>
        </div>
        {selectedTabId === 0 && <div>
            <BasicInformationFormTemplate targetProduct={props.products.find(product => product.isTarget)} nomenclature={props.nomenclature}/>
        </div>}
        {selectedTabId === 1 && <div>
            <MaterialsFormTemplate materials={props.materials} billOfMaterialsId={props.billOfMaterialsId}/>
        </div>}
        {selectedTabId === 2 && <div>
            <ProductsFormTemplate products={props.products} billOfMaterialsId={props.billOfMaterialsId}/>
        </div>}
        {selectedTabId === 3 && <div>
            <StagesAndStepsFormTemplate billOfMaterialsId={props.billOfMaterialsId} stages={props.stages} steps={props.steps} materials={props.materials} products={props.products}/>
        </div>}
    </div>)
}