import React from "react";
import Select, {GroupBase, StylesConfig} from "react-select";
import {Control, Controller} from "react-hook-form";
import {OptionType} from "../../../../model/option-type/OptionType";

interface SelectProps<T> {
    control: Control<any>,
    rules: {},
    name: string,
    label?: string,
    options: OptionType<T>[],
    placeholder?: string,
    isDisabled?: boolean,
    isClearable?: boolean,
    width?: string,
    height?: string,
    defaultValue?: OptionType<T>
}


export function ControlledSelect<T>(props: SelectProps<T>) {
    const selectStyles: StylesConfig<OptionType<T>, false, GroupBase<OptionType<T>>> = {
        control: (styles: any, state) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                borderRadius: '6px',
                minHeight: '36px',
                maxHeight: "36px",
                width: props.width ? props.width : "423px",
                backgroundColor: state.isDisabled ? "hsl(0, 0%, 100%)" : "hsl(0, 0%, 100%)",
                border: state.isDisabled ? "2px solid rgb(209 213 219)" : "2px solid rgb(156 163 175 / 80%)",
                marginTop: "2px",
                marginBottom: "-1px",
                flexGrow: "1"

            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                visibility: "hidden"
            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                paddingTop: "0px",
                paddingBottom: "0px",
                height: props.height || styles.height
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                marginLeft: "1px",
            };
        },
        singleValue: (styles: any, state) => {
            return {
                ...styles,
                color: state.isDisabled ? 'black' : 'black',
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                color: "rgba(156, 163, 175, 0.8)"
            };
        },
        option: (styles: any) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
            };
        },
        menu: (styles: any) => {
            return {
                ...styles,
                width: props.width ? props.width : "423px"
            }
        }
    }

    return (
        <Controller control={props.control} name={props.name} rules={props.rules}
                    defaultValue={props.defaultValue !== undefined ? props.defaultValue : undefined}
                    render={({field: {onChange, value}, fieldState: {error}}) =>
                        <>
                            <label className="text-base font-normal"> {props.label}
                                <Select options={props.options}
                                        placeholder={props.placeholder !== undefined ? props.placeholder : "Не выбрано"}
                                        isDisabled={props.isDisabled !== undefined ? props.isDisabled : false}
                                        isClearable={props.isClearable !== undefined ? props.isClearable : true}
                                        styles={selectStyles}
                                        onChange={onChange}
                                        value={value}/>
                                {error && <span className="text-sm font-normal text-red-400">{error.message}</span>}
                            </label>
                        </>
                    }/>
    )
}