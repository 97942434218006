import React, {useEffect, useState} from "react";
import {useUploadIndividualPhotoMutation} from "../../../store/backend-api/backend.api";
import {useUserPhotoActions} from "../../../hooks/useUserPhotoActions";

interface ComponentProps {
    individualId: number,
    isUserIndividual: boolean,
    label: string
}

export function FileUploader(props: ComponentProps) {
    const [doUploadIndividualPhoto] = useUploadIndividualPhotoMutation()
    const [file, setFile] = useState<File | null>(null)
    const {setUserPhoto} = useUserPhotoActions()

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    }

    useEffect(() => {
        if (file) {
            const formData = new FormData()
            formData.append('file', file)
            doUploadIndividualPhoto({id: props.individualId, body: formData, isUserIndividual: props.isUserIndividual})
            if (props.isUserIndividual) {
                setUserPhoto(null)
            }
        }
    }, [file]);

    return (
        <label className="h-6 mt-[6px] mb-[2px] text-sm text-black/[.60] font-normal rounded px-1 pb-1 underline underline-offset-[3px] decoration-[0.5px] transition hover:decoration-blue-400 hover:bg-blue-50/[.70]">
            {props.label}
            <input type="file" name="inputName" className="hidden" onChange={handleFileChange}/>
        </label>
    )
}