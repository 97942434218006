import React, {useState} from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {GroupBase, OptionsOrGroups, StylesConfig} from "react-select";
import {GuideFilter} from "../../../../model/filters/GuideFilter";
import isEqual from 'lodash.isequal';
import {Option} from "./NewPaginateAsyncSelect";
import {TablePage} from "../../../../model/utils/TablePage";
import {OptionType} from "../../../../model/option-type/OptionType";
import {DEFAULT_GUIDE_FILTER} from "../../../../model/filters/DefaultFilters";
import {AllTableFilterType} from "../../../../model/filters/TableFiltersDefinitions";

const defaultFilter: GuideFilter = {
    pageNumber: 0,
    pageSize: 10,
    search: null,
    sortOrder: null
}

interface AsyncSelectProps<T> {
    defaultOptions?: OptionType<T>[] | null,
    placeholder?: string,
    onChange: Function,
    request: Function,
    mapFunc: (page: TablePage<any>) => OptionType<T>[]
    filter?: GuideFilter
    isClearable?: boolean,
    width?: string
}

export function PaginateAsyncMultiSelect<T> (props: AsyncSelectProps<T>) {
    const selectStyles: StylesConfig<Option<T>, true, GroupBase<OptionType<T>>> = {
        control: (styles: any, state) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                borderRadius: '6px',
                width: props.width ? props.width : "423px",
                border: "2px solid rgba(156, 163, 175, 0.8)",
                marginTop: "2px",
                flexGrow: "1"

            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                visibility: "hidden"
            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                paddingTop: "0px",
                paddingBottom: "0px"
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                marginLeft: "1px"
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                color: "rgba(156, 163, 175, 0.8)"
            };
        },
        option: (styles: any) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
            };
        },
        menu: (styles: any) => {
            return {
                ...styles
            }
        }
    };

    const [selectedOption, setSelectedOption] = useState<readonly Option<T>[]>(props.defaultOptions ?? []);

    const loadOptions = async (searchQuery: string, prevOptions: OptionsOrGroups<OptionType<T>, GroupBase<OptionType<T>>>, {page}: {
        page?: number
    } = {}) => {
        const templateFilter = props.filter !== undefined ? props.filter : DEFAULT_GUIDE_FILTER
        const filter = {
            ...templateFilter,
            search: searchQuery.length > 0 ? searchQuery : null,
            pageNumber: page!
        } as AllTableFilterType

        const options: OptionType<T>[] = []

        if (prevOptions.length === 0) {

            if (props.defaultOptions) {
                options.concat(props.defaultOptions)
            }
        }

        const data = (await props.request(filter).unwrap());
        const optionsFromData = props.mapFunc(data)
            .filter((option: OptionType<T>) => !props.defaultOptions?.find(defaultOption => option.value === defaultOption.value))
        options.push(...optionsFromData)

        return {
            options,
            hasMore: data!.pageNumber < data!.pageCount,
            additional: {
                page: page! + 1
            }
        }
    }

    return (
        <AsyncPaginate
            menuPosition="fixed"
            loadOptions={loadOptions}
            value={selectedOption}
            onChange={(value) => {
                setSelectedOption(value)
                props.onChange(value)
            }}
            additional={{
                page: 0
            }}
            placeholder={props.placeholder ?? "Не выбрано"}
            styles={selectStyles}
            isMulti
            closeMenuOnSelect={false}

        />
    );
}
