import {useDispatch} from "react-redux";
import {useMemo} from "react";
import {bindActionCreators} from "@reduxjs/toolkit";
import {filterNodesActions} from "../store/slices/filterNodes.slice";


export const useFilterNodesActions = () => {
    const dispatch = useDispatch()

    return useMemo(() =>
        bindActionCreators(filterNodesActions, dispatch), [dispatch])
}