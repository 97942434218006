import React from "react";

interface TabsProps {
    tabs: Tab[],
    selectedTabId: number,
    onClick: (id: number) => void,
}

export interface Tab {
    id: number,
    label: string,
    readonly?: boolean
}

export function Tabs(props: TabsProps) {
    function stylesForTab(tab: Tab) {
        if (!(tab.readonly !== undefined && tab.readonly)) {
            return tab.id === props.selectedTabId ? "border-[1.5px] border-black/[.30] cursor-pointer text-black/[.67]" : "border-[1.5px] border-white hover:underline hover:underline-offset-[3px] hover:decoration-[0.5px] cursor-pointer text-black/[.67]"
        }
        return "text-black/[.45] "
    }

    return (
        <div className="flex flex-row">
            {props.tabs.map(tab => {
                    const tabStyles: string = stylesForTab(tab);
                    return (<button
                        key={tab.id}
                        type="button"
                        disabled={tab.readonly !== undefined ? tab.readonly : false}
                        className={`${tabStyles} min-h-9 rounded-md px-3 py-[9px] text-base font-normal leading-none`}
                        onClick={() => props.onClick(tab.id)}>
                        {tab.label}
                    </button>)
                }
            )}
        </div>
    )
}