import React from "react";
import {NomenclatureFormTemplate} from "./NomenclatureFormTemplate";
import {OptionType} from "../../../model/option-type/OptionType";
import {NomenclatureForm} from "../../../model/nomenclature/NomenclatureForm";
import {useCreateNomenclatureMutation} from "../../../store/backend-api/backend.api";

interface FormProps {
    data?: NomenclatureForm,
    defaultMeasurementUnit?: OptionType<number>,
    editFromBillOfMaterialsUI?: boolean,
    onSaveFromBillOnMaterialsUI?: (state: boolean) => void,
    setCreatedNomenclature?: (state: OptionType<number>) => void
}

export function NomenclatureFormCreate(props: FormProps) {
    const [doCreateNomenclature, {
        data: createdNomenclatureId,
        isSuccess: isSuccessCreate
    }] = useCreateNomenclatureMutation()

    const nomenclature =  props.data ? {...props.data, id: null} : undefined
    function onSubmit(nomenclature: NomenclatureForm) {
        doCreateNomenclature(nomenclature)
    }

    return (
        <NomenclatureFormTemplate editFromBillOfMaterialsUI={props.editFromBillOfMaterialsUI}
                                  onSaveFromBillOnMaterialsUI={props.onSaveFromBillOnMaterialsUI}
                                  setCreatedNomenclature={props.setCreatedNomenclature} buttonName={"Создать"}
                                  onSubmit={onSubmit} isSuccessCreate={isSuccessCreate}
                                  createdNomenclatureId={createdNomenclatureId}
                                  data={nomenclature}
                                  defaultMeasurementUnit={props.defaultMeasurementUnit}/>
    )
}