import type {Middleware, MiddlewareAPI} from '@reduxjs/toolkit'
import {isRejectedWithValue} from '@reduxjs/toolkit'
import {addToast} from "../slices/toast.slice";


export const RTKQueryErrorMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            api.dispatch(addToast({
                id: Date.now(),
                error: {
                    status: action.payload?.data?.status || "0",
                    title: action.payload?.data?.title || "Неизвестная ошибка",
                    detail: action.payload?.data?.detail || "Ошибка неизвестна",
                    request: action.request
                }
            }))
        }
        return next(action)
    }