import React from "react";
import {MainProductOrAdditionalEquipmentInterface} from "../../../model/production-order/ProductionOrder";

interface TableProps {
    tableData: MainProductOrAdditionalEquipmentInterface | null
}

export function TableForMainProduct(props: TableProps) {

    return(
        <table>
            <thead>
                <tr>
                    <th className="border border-black text-base font-normal text-center px-2">№</th>
                    <th className="border border-black text-base font-normal text-center px-2">Артикул</th>
                    <th className="border border-black text-base font-normal text-center px-2">Наименование</th>
                    <th className="border border-black text-base font-normal text-center px-2">Количество</th>
                </tr>
            </thead>
            <tbody>
                {props.tableData && <tr key={props.tableData.nomenclature.id}>
                    <td className="border border-black text-base font-light px-2">1</td>
                    <td className="border border-black text-base font-light px-2">{props.tableData.nomenclature.mainSKU || "–"}</td>
                    <td className="border border-black text-base font-light px-2">{props.tableData.nomenclature.name}</td>
                    <td className="border border-black text-base font-light px-2">{`${props.tableData.quantity} ${props.tableData.nomenclature.measurementUnit.name}`}</td>
                </tr>}
            </tbody>
        </table>
    )
}