import React from "react";
import {AdministrativeRole} from "../../model/administrative-roles/AdministrativeRole";
import {AdministrativeRoleFormTemplate} from "./AdministrativeRoleFormTemplate";
import {useSearchProductionStagesGuidePageQuery} from "../../store/backend-api/backend.api";
import {GuideFilter} from "../../model/filters/GuideFilter";

interface FormProps{
    data: AdministrativeRole
}

export function AdministrativeRolesFormEdit(props: FormProps) {
    const {data: productionStages, isSuccess} = useSearchProductionStagesGuidePageQuery({pageNumber: 0, pageSize: 500, search: null, sortOrder: null} as GuideFilter)

    return (<>
        {isSuccess && <AdministrativeRoleFormTemplate productionStages={productionStages.rows} data={props.data}/>}
    </>)
}