import React from "react"
import {Link, useLocation} from "react-router-dom";
import {useHasAuthority} from "../../hooks/TokensHooks";
import {SystemRole} from "../../model/administrative-roles/AdministrativeRole";

interface NavBarLinkProps {
    to: string
    position: string
    name: string
    isActive?: boolean
    authority?: string
}

export function NavBarLink(props: NavBarLinkProps) {
    const location = useLocation()
    const requiredAuthority = props.authority ? props.authority : SystemRole.ROLE_ADMIN
    const hasAuthority = useHasAuthority(requiredAuthority)

    function currentLocationStyle() {
        const pathSegments = props.to.split('/');
        const basePath = `/${pathSegments[1]}`;
        const subPath = pathSegments[2];

        console.log('base', basePath, 'sub', subPath)

        let isActive: boolean;

        if (basePath === '/production-order' && subPath === 'execution') {
            isActive = location.pathname.startsWith(`/production-order/execution`);
        } else if (basePath === '/production-order' && subPath === 'archive') {
            isActive = location.pathname.startsWith(`/production-order/archive`);
        } else {
            isActive = location.pathname.startsWith(basePath) && !location.pathname.includes('execution') && !location.pathname.includes('archive');
        }

        return isActive ? "border-blue-500 border-2 text-blue-500 font-normal py-1" : "font-light hover:font-normal py-1.5";
    }

    const borderStyle: string = currentLocationStyle()

    if (!hasAuthority) {
        return (<></>)
    }

    if (props.isActive || props.isActive === undefined) {
        return (
            <Link
                to={props.to}
                className={`text-left flex items-center gap-2 ${borderStyle} ${props.position}`}
            >
                <span className="max-w-[275px]">{props.name}</span>
            </Link>
        )
    } else {
        return (
            <button
                className={`font-light text-gray-400 text-left cursor-not-allowed flex items-center gap-2 py-1.5 ${props.position}`}
            >
                <span className="max-w-[275px]">{props.name}</span>
            </button>
        )
    }
}