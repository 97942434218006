import React, {useEffect, useRef, useState} from "react";
import {AiOutlineMore} from "react-icons/ai";

interface OptionsButtonProps<T> {
    row: T,
    options: OptionInterface<T>[]
}

export interface OptionInterface<T> {
    optionName: string,
    onClickOption: (row: T) => void,
    condition?: (row: T) => boolean
}

export function OptionsButton<T> (props: OptionsButtonProps<T>) {
    const [isVisible, setIsVisible] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const displayedOption = props.options.filter(option => option.condition === undefined || option.condition(props.row))
        .map(option =>
            (<button type="button" className="text-left px-2 py-0.5 border-b-[0.5px] border-b-black/[.20]" onClick={() => {option.onClickOption(props.row)}}>
                {option.optionName}
            </button>))

    function handleClickOutsideButton (event: any) {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideButton);
        return () => {
            document.removeEventListener('click', handleClickOutsideButton);
        };
    }, [])

    return (<span className="relative inline">
            <button ref={buttonRef}
                    type="button"
                    onClick={() => {setIsVisible(!isVisible)}}
                    className="mt-1.5 w-[14px] -ml-[10px]"
            >
                <AiOutlineMore className="h-6 w-6"/>
            </button>
            {isVisible && <div
                className={`flex flex-col whitespace-nowrap absolute right-9 transform -translate-y-3/4 bg-white rounded-md border-blue-500 border-[1.5px] z-50 mt-1 shadow-lg`}>
                {displayedOption.length === 0 ? <p className="px-2 py-0.5">Нет опций</p> : displayedOption}
            </div>}
        </span>
    )
}