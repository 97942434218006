import React from 'react';
import {Table, TableColumn} from "../tables/Table";
import {TablePage} from "../../model/utils/TablePage";
import {IndividualsRow, IndividualsSortedField} from "../../model/individuals/Individuals";
import {useNavigate} from "react-router-dom";
import {booleansToString, gendersToString} from "../../data/IndividualsData";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useAppSelector} from "../../hooks/useAppSelector";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {SortedOrder} from "../../model/utils/SortedOrder";

interface FormProps {
    data: TablePage<IndividualsRow>
}

export function IndividualsTable(props: FormProps) {
    const navigate = useNavigate()

    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions()
    const filter = useAppSelector(state => state.sessionStorage.filters[TableFilterKeys.INDIVIDUALS_GUIDE_FILTER].filter)

    const setSortingBy = (currentSortedField: IndividualsSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const idColumn: TableColumn<IndividualsRow> = {
        columnName: "ID",
        columnFieldName: IndividualsSortedField.ID,
        extractCellContent: row => row.id,
        sortingFunction: setSortingBy(IndividualsSortedField.ID)
    }
    const fullNameColumn: TableColumn<IndividualsRow> = {
        columnName: "ФИО",
        columnFieldName: IndividualsSortedField.FULL_NAME,
        extractCellContent: row => row.fullName,
        sortingFunction: setSortingBy(IndividualsSortedField.FULL_NAME)
    }
    const genderColumn: TableColumn<IndividualsRow> = {
        columnName: "Пол",
        columnFieldName: IndividualsSortedField.GENDER,
        extractCellContent: row => gendersToString.get(row.gender),
        sortingFunction: setSortingBy(IndividualsSortedField.GENDER)
    }
    const isUserColumn: TableColumn<IndividualsRow> = {
        columnName: "Пользователь системы",
        columnFieldName: IndividualsSortedField.IS_USER,
        extractCellContent: row => booleansToString.get(row.isUser),
        sortingFunction: setSortingBy(IndividualsSortedField.IS_USER)
    }
    const usernameColumn: TableColumn<IndividualsRow> = {
        columnName: "Имя пользователя",
        columnFieldName: IndividualsSortedField.USERNAME,
        extractCellContent: row => row.username,
        sortingFunction: setSortingBy(IndividualsSortedField.USERNAME)
    }
    const contactInfoColumn: TableColumn<IndividualsRow> = {
        columnName: "Контактная информация",
        extractCellContent: row => row.contactInfo.length > 0 ?
            <div className="leading-tight">{row.contactInfo.map(info => <p>{info}</p>)}</div> : "–"
    }

    function onRowClick(row: IndividualsRow) {
        navigate(`/individuals?id=${row.id}`)
    }

    return (
        <Table<IndividualsRow>
            tableColumns={[idColumn, fullNameColumn, genderColumn, isUserColumn, usernameColumn, contactInfoColumn]}
            rows={props.data.rows}
            onRowClick={onRowClick}
            tableFilterKey={TableFilterKeys.INDIVIDUALS_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => updateFilterPage({
                    key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER,
                    value: newPageNumber
                })
            }}/>
    )
}