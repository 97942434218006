import {TableFilterKeys} from "../model/filters/TableFiltersDefinitions";

export const UpdateFiltersPages = (updateFilterPage: (value: any) => void) => {
    updateFilterPage({key: TableFilterKeys.NOMENCLATURE_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.USER_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.INDIVIDUALS_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER, value: 0})
    updateFilterPage({key: TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER, value: 0})
}