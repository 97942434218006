import {TableColumn} from "../../tables/Table";
import {useNavigate} from "react-router-dom";
import React from "react";
import {
    TechnologicalOperationGuideRow
} from "../../../model/technological-operation/TechnologicalOperationGuideRow";
import {TablePage} from "../../../model/utils/TablePage";
import {SortedOrder} from "../../../model/utils/SortedOrder";
import {TechnologicalOperationSortedField} from "../../../model/technological-operation/TechnologicalOperationGuideFilter";
import {TableFilterKeys} from "../../../model/filters/TableFiltersDefinitions";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useFiltersActions} from "../../../hooks/useFiltersActions";
import {Table} from "../../tables/Table";

export interface TechnologicalOperationLinearTableProps {
    data: TablePage<TechnologicalOperationGuideRow>
}

export function TechnologicalOperationTable(props: TechnologicalOperationLinearTableProps) {
    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER].filter;

    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions()

    const navigate = useNavigate()

    const setSortingBy = (currentSortedField: TechnologicalOperationSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const idColumn: TableColumn<TechnologicalOperationGuideRow> = {
        columnName: 'ID',
        sortingFunction: setSortingBy(TechnologicalOperationSortedField.ID),
        extractCellContent: row => row.id,
        columnFieldName: TechnologicalOperationSortedField.ID
    }

    const nameColumn: TableColumn<TechnologicalOperationGuideRow> = {
        columnName: 'Наименование',
        sortingFunction: setSortingBy(TechnologicalOperationSortedField.NAME),
        extractCellContent: row => row.name,
        columnFieldName: TechnologicalOperationSortedField.NAME
    }

    function actionOnRowClick(row: TechnologicalOperationGuideRow) {
        navigate(`/technological-operation?id=${row.id}`)
    }

    return (
        <Table<TechnologicalOperationGuideRow>
            tableColumns={[idColumn, nameColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({
                        key: TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
                        value: newPageNumber
                    })
                }
            }}
        />
    )
}