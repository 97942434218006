export function doNothing() {
}

interface objInterface {
    [key: string]: any,

}

//делаем название фильтра для локации
export function createLocationFilterName(location: string) {
    return [...location.slice(1).split('/'), 'Filter'].map((item, index) => {
        if (index === 0) return item.toLowerCase()
        return item[0].toUpperCase() + item.slice(1)
    }).join('');
}

//сохранение в сторейдж
export function saveToStorage(keyName: string, data: any) {
    sessionStorage.setItem(keyName, JSON.stringify(data))
}

//загрузка из сторейдж
export function loadFromStorage(keyName: string) {
    const rawData = sessionStorage.getItem(keyName);
    if (!rawData) {
        return null;
    }
    return JSON.parse(rawData);
};

//удалить ключ из сторейдж
export function removeFromStorage(keyName: string) {
    sessionStorage.removeItem(keyName);
}

//выкидывалка лишних ключей из объекта
export function omitKeysFromObj(obj: { [key: string]: any }, keys: string[]): { [key: string]: any } {
    if (!keys.length) return obj;
    const key = keys.pop();
    if (key) {
        const parts = key.split(".");
        if (parts.length > 1) {
            const {[parts[0]]: todo, ...rest} = obj;
            return {
                ...omitKeysFromObj(rest, keys),
                [parts[0]]: omitKeysFromObj(todo, [parts[1]]),
            };
        }
        const {[key]: omitted, ...rest} = obj;
        return omitKeysFromObj(rest, keys);
    }
    return obj;
};

