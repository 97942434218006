import React, {useState} from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {GroupBase, OptionsOrGroups, StylesConfig} from "react-select";
import isEqual from 'lodash.isequal';
import {OptionType} from "../../../../model/option-type/OptionType";
import {AllTableFilterType} from "../../../../model/filters/TableFiltersDefinitions";
import {DEFAULT_GUIDE_FILTER} from "../../../../model/filters/DefaultFilters";


export interface Option<T> {
    value: T,
    label: string
}

interface AsyncSelectProps<T> {
    defaultOption?: Option<T>,
    placeholder?: string,
    onChange: Function,
    request: Function,
    mapFunc: Function
    filter?: AllTableFilterType
    isClearable?: boolean,
    width?: string
}

export function NewPaginateAsyncSelect<T> (props: AsyncSelectProps<T>) {
    const selectStyles: StylesConfig<Option<T>, false, GroupBase<Option<T>>> = {
        control: (styles: any, state) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                borderRadius: '6px',
                minHeight: '36px',
                maxHeight: "36px",
                width: props.width ? props.width : "423px",
                border: "2px solid rgba(156, 163, 175, 0.8)",
                marginTop: "2px",
                flexGrow: "1"

            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                visibility: "hidden"
            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                paddingTop: "0px",
                paddingBottom: "0px"
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                marginLeft: "1px"
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                color: "rgba(156, 163, 175, 0.8)"
            };
        },
        option: (styles: any) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
            };
        },
        menu: (styles: any) => {
            return {
                ...styles
            }
        }
    };

    const [selectedOption, setSelectedOption] = useState<Option<T> | null>(props.defaultOption !== undefined ? props.defaultOption : null);

    const loadOptions = async (searchQuery: string, prevOptions: OptionsOrGroups<OptionType<T>, GroupBase<OptionType<T>>>, {page}: {
        page?: number
    } = {}) => {
        const templateFilter = props.filter !== undefined ? props.filter : DEFAULT_GUIDE_FILTER
        const filter = {
            ...templateFilter,
            search: searchQuery.length > 0 ? searchQuery : null,
            pageNumber: page!
        } as AllTableFilterType

        const options: OptionType<T>[] = []

        if (prevOptions.length === 0) {

            if (props.defaultOption !== undefined) {
                options.push(props.defaultOption)
            }
        }

        const data = (await props.request(filter).unwrap());
        const optionsFromData = props.mapFunc(data)
            .filter((option: OptionType<T>) => !isEqual(option.value, props.defaultOption?.value))
        options.push(...optionsFromData)

        return {
            options,
            hasMore: data!.pageNumber < data!.pageCount,
            additional: {
                page: page! + 1
            }
        }
    }

    return (
        <AsyncPaginate
            menuPosition="fixed"
            loadOptions={loadOptions}
            value={selectedOption}
            onChange={(option) => {
                setSelectedOption(option);
                props.onChange(option);
            }}
            additional={{
                page: 0
            }}
            isClearable={props.isClearable || true}
            placeholder={props.placeholder || "Не выбрано"}
            styles={selectStyles}
        />
    );
}
