import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ToastInterface} from "../../components/toasts/Toast";

interface toastState {
    toasts: ToastInterface[]
}

const initialState: toastState = {
    toasts: []
}

export const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<ToastInterface>) => {
            state.toasts = [...state.toasts, action.payload]
        },
        removeToast: (state, action: PayloadAction<number>) => {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload)
        }
    }
})

export const toastReducer = toastSlice.reducer
export const {removeToast} = toastSlice.actions
export const {addToast} = toastSlice.actions