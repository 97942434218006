import React, {useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {BackButton} from "../ui/buttons/BackButton";
import {CreatingOrUpdatingProductionStage, ProductionStage} from "../../model/production-stage/ProductionStage";
import {useNavigate} from "react-router-dom";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import {Button} from "../ui/buttons/Button";
import {
    useCreateProductionStageMutation,
    useDeleteProductionStageMutation,
    useUpdateProductionStageMutation
} from "../../store/backend-api/backend.api";
import {formButtonGroupStyle, formHeaderStyle, formStyle} from "../../utils/TailwindStyles";
import {SeparatingLine} from "../ui/common/SeparatingLine";

interface FormProps {
    data?: ProductionStage,
    readonly?: boolean
}

interface FormFields {
    id: number | null,
    name: string
}

export function ProductionStageFormTemplate(props: FormProps) {
    const [doCreateProductionStage, {
        data: createdProductionStageId,
        isSuccess: isSuccessCreate
    }] = useCreateProductionStageMutation()
    const [doUpdateProductionStage, {isSuccess: isSuccessUpdate}] = useUpdateProductionStageMutation()
    const [doDeleteProductionStage, {isSuccess: isSuccessDelete}] = useDeleteProductionStageMutation()
    const defaultFormValues: FormFields = props.data !== undefined ? {
        id: props.data.id,
        name: props.data.name
    } : {id: null, name: ""}
    const {control, handleSubmit, formState: {isDirty}} = useForm<FormFields>({mode: "onChange", defaultValues: defaultFormValues})
    const navigate = useNavigate()
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        const productionStage: CreatingOrUpdatingProductionStage = {
            name: data.name
        }
        if (props.data && isDirty) {
            doUpdateProductionStage({id: props.data.id, body: productionStage})
        }
        else if (!props.data) {
            doCreateProductionStage(productionStage)
        }
    }

    function onClickDeleteButton(productionStage: ProductionStage) {
        doDeleteProductionStage(productionStage.id)
    }

    useEffect(() => {
        if (isSuccessUpdate) {
            navigate(`/production-stages?id=${props.data!.id}`)
        }
        if (isSuccessCreate) {
            navigate(`/production-stages?id=${createdProductionStageId}`)
        }
        if (isSuccessDelete) {
            navigate('/production-stages/guide')
        }
    }, [isSuccessUpdate, isSuccessDelete, isSuccessCreate, createdProductionStageId]);

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)} className={formStyle}>
            <div className={formHeaderStyle}>
                <p>ID: {defaultFormValues.id !== null ? defaultFormValues.id : "не определен"}</p>
                <BackButton/>
            </div>
            <ControlledInputField control={control} rules={{required: {value: true, message: "Это обязательное поле"}}}
                                  name={"name"} label={"Наименование этапа"} readonly={props.readonly}
                                  additionalStyles={"w-[423px]"}/>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {props.data && <Button buttonName={"Удалить"} onClickButton={() => onClickDeleteButton(props.data!)}/>}
                {!props.readonly &&
                    <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onClickSaveButton)}/>}
                {props.readonly &&
                    <Button buttonName={"Редактировать"} onClickButton={() => {navigate(`/production-stages/edit?id=${props.data!.id}`)}}/>}
            </div>
        </form>
    )
}