import {GuideFilter} from "../filters/GuideFilter";

export interface AdministrativeRole {
    id: number,
    name: string,
    permissions: Permission[],
    availableProductionStages: number[]
}

export interface AdministrativeRolesRow {
    id: number,
    name: string
}

export interface CreatingOrUpdatingAdministrativeRole {
    name: string,
    permissions: Permission[],
    availableProductionStages: number[]
}

export enum Permission {
    NO_ACCESS = "NO_ACCESS",
    NOMENCLATURE_VIEW = "NOMENCLATURE_VIEW",
    NOMENCLATURE_MANAGE = "NOMENCLATURE_MANAGE",
    MEASUREMENT_UNIT_VIEW = "MEASUREMENT_UNIT_VIEW",
    MEASUREMENT_UNIT_MANAGE = "MEASUREMENT_UNIT_MANAGE",
    ADMINISTRATIVE_ROLE_VIEW = "ADMINISTRATIVE_ROLE_VIEW",
    ADMINISTRATIVE_ROLE_MANAGE = "ADMINISTRATIVE_ROLE_MANAGE",
    USER_VIEW = "USER_VIEW",
    USER_MANAGE = "USER_MANAGE",
    INDIVIDUALS_VIEW = "INDIVIDUALS_VIEW",
    INDIVIDUALS_MANAGE = "INDIVIDUALS_MANAGE",
    TECHNOLOGICAL_PROCESS_CHART_VIEW = "TECHNOLOGICAL_PROCESS_CHART_VIEW",
    TECHNOLOGICAL_PROCESS_CHART_MANAGE = "TECHNOLOGICAL_PROCESS_CHART_MANAGE",
    PRODUCTION_STAGE_VIEW = "PRODUCTION_STAGE_VIEW",
    PRODUCTION_STAGE_MANAGE = "PRODUCTION_STAGE_MANAGE",
    TECHNOLOGICAL_OPERATION_VIEW = "TECHNOLOGICAL_OPERATION_VIEW",
    TECHNOLOGICAL_OPERATION_MANAGE = "TECHNOLOGICAL_OPERATION_MANAGE",
    ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_VIEW = "ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_VIEW",
    ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_MANAGE = "ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_MANAGE",
    PRODUCTION_ORDER_VIEW = "PRODUCTION_ORDER_VIEW",
    PRODUCTION_ORDER_MANAGE = "PRODUCTION_ORDER_MANAGE",
    PRODUCTION_ORDER_EXECUTION = "PRODUCTION_ORDER_EXECUTION"
}

export enum SystemRole {
    ROLE_ADMIN = "ROLE_ADMIN"
}

export enum ObjectsWithCustomizableAccess {
    NOMENCLATURE = "NOMENCLATURE",
    MEASUREMENT_UNIT = "MEASUREMENT_UNIT",
    ADMINISTRATIVE_ROLE = "ADMINISTRATIVE_ROLE",
    USER = "USER",
    INDIVIDUALS = "INDIVIDUALS",
    TECHNOLOGICAL_PROCESS_CHART = "TECHNOLOGICAL_PROCESS_CHART",
    PRODUCTION_STAGE = "PRODUCTION_STAGE",
    TECHNOLOGICAL_OPERATION = "TECHNOLOGICAL_OPERATION",
    ADMINISTRATIVE_AND_FUNCTIONAL_ZONE = "ADMINISTRATIVE_AND_FUNCTIONAL_ZONE",
    PRODUCTION_ORDER = "PRODUCTION_ORDER"
}

export interface AdministrativeRoleGuideFilter extends GuideFilter {

}