import React from "react";
import {NomenclatureGuideRow} from "../../../model/nomenclature/NomenclatureGuideRow";
import {NomenclatureSortedField} from "../../../model/nomenclature/NomenclatureGuideFilter";
import {TablePage} from "../../../model/utils/TablePage";
import {productionNomenclatureClassifierToString} from "../../../data/NomenclatureData";
import {SortedOrder} from "../../../model/utils/SortedOrder";
import {useNavigate} from "react-router-dom";
import {Table, TableColumn} from "../../tables/Table";
import {TableFilterKeys} from "../../../model/filters/TableFiltersDefinitions";
import {useFiltersActions} from "../../../hooks/useFiltersActions";
import {useAppSelector} from "../../../hooks/useAppSelector";

export interface TableProps {
    data: TablePage<NomenclatureGuideRow>
}

export function NomenclatureTable(props: TableProps) {
    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions()

    const filtersState = useAppSelector(state => state.sessionStorage.filters)
    const filter = filtersState[TableFilterKeys.NOMENCLATURE_GUIDE_FILTER].filter;

    const navigate = useNavigate();

    const setSortingBy = (currentSortedField: NomenclatureSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.NOMENCLATURE_GUIDE_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.NOMENCLATURE_GUIDE_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.NOMENCLATURE_GUIDE_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const idColumn: TableColumn<NomenclatureGuideRow> = {
        columnName: 'ID',
        columnFieldName: NomenclatureSortedField.ID,
        extractCellContent: row => row.id,
        sortingFunction: setSortingBy(NomenclatureSortedField.ID)
    }
    const SKUColumn: TableColumn<NomenclatureGuideRow> = {
        columnName: 'Основной артикул',
        extractCellContent: row => row.mainSKU
    }
    const nameColumn: TableColumn<NomenclatureGuideRow> = {
        columnName: 'Наименование',
        columnFieldName: NomenclatureSortedField.NAME,
        extractCellContent: row => row.name,
        sortingFunction: setSortingBy(NomenclatureSortedField.NAME)
    }
    const measurementUnitColumn: TableColumn<NomenclatureGuideRow> = {
        columnName: 'Ед. изм.',
        columnFieldName: NomenclatureSortedField.MEASUREMENT_UNIT,
        extractCellContent: row => row.measurementUnit,
        sortingFunction: setSortingBy(NomenclatureSortedField.MEASUREMENT_UNIT)
    }

    const workClassifierColumn: TableColumn<NomenclatureGuideRow> = {
        columnName: 'Классификатор',
        extractCellContent: row => row.productionNomenclatureClassifier.map(item => productionNomenclatureClassifierToString.get(item)).join(' / '),
    }

    function actionOnRowClick(row: NomenclatureGuideRow) {
        navigate(`/nomenclature?id=${row.id}`)
    }

    return (<>
        <Table<NomenclatureGuideRow>
            tableColumns={[idColumn, SKUColumn, nameColumn, measurementUnitColumn, workClassifierColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.NOMENCLATURE_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                updateFilterPage({
                key: TableFilterKeys.NOMENCLATURE_GUIDE_FILTER,
                value: newPageNumber
            })
            }}}
        />
    </>)
}