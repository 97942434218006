import React from "react";
import {Link} from "react-router-dom";

interface EditButtonProps {
    navLink: string
}

export function EditButton (props: EditButtonProps) {
    return (<Link to={props.navLink}
                  className="h-9 border-[1.5px] border-black/[.30] rounded-md px-3 pt-1 pb-2 ml-6 text-base font-normal text-black/[.67] transition hover:underline hover:underline-offset-[3px] hover:decoration-[0.5px]">
            Редактировать
        </Link>

    )
}