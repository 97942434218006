import React, {useEffect} from 'react';
import {useAppSelector} from "../../hooks/useAppSelector";
import {useSearchAdministrativeAndFunctionalZoneGuidePageQuery} from "../../store/backend-api/backend.api";
import {
    AdministrativeAndFunctionalZoneTable
} from "../../components/administrative-and-functional-zone/guide/AdministrativeAndFunctionalZoneTable";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useFilterNodesActions} from "../../hooks/useMenuFiltersActions";
import {
    AdministrativeAndFunctionalZonePurpose
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {purposesToString} from "../../data/AdministrativeAndFunctionalZoneFormData";
import {OptionType} from "../../model/option-type/OptionType";
import {UpdateFilter} from "../../store/slices/filters.slice";
import {NewSelect} from "../../components/ui/form-components/standalone/NewSelect";
import {DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER} from "../../model/filters/DefaultFilters";
import {useDataActions} from "../../hooks/useDataActions";

export function AdministrativeAndFunctionalZoneGuide() {
    const {updateFilterAndClearData} = useFiltersActions()
    const {addFiltersToMenu} = useFilterNodesActions()
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER])

    const {data: tablePage} = useSearchAdministrativeAndFunctionalZoneGuidePageQuery(filter)

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    useEffect(() => {
        addFiltersToMenu({
            key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
            value: [
                {
                    filterName: "Назначение зоны",
                    filter: <NewSelect
                        options={Object.keys(AdministrativeAndFunctionalZonePurpose).map(key => ({label: purposesToString.get(AdministrativeAndFunctionalZonePurpose[key as keyof typeof AdministrativeAndFunctionalZonePurpose])!, value: AdministrativeAndFunctionalZonePurpose[key as keyof typeof AdministrativeAndFunctionalZonePurpose]}))}
                        onChange={(option: OptionType<AdministrativeAndFunctionalZonePurpose> | null) => {
                            const newFilter: UpdateFilter = {
                                key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
                                value: {...filter, purpose: option ? option.value : null}
                            }
                            updateFilterAndClearData(newFilter)
                        }}
                        defaultOption={filter.purpose ? {label: purposesToString.get(filter.purpose)!, value: filter.purpose} : undefined}
                        width={"259px"}/>
                }
            ]
        })
    }, [filter.purpose]);

    return (
        <div className="space-y-3.5 h-[98%]">
            <div>
                <div className="min-w-full flex flex-row">
                    <GuideTopBox tableFilterKey={TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER}
                                 createNavigationURL={"/administrative-and-functional-zone/new"}
                                 searchInfo={"Поиск по наименованию."}/>
                </div>
            </div>
            <div className="h-[90%] bg-transparent rounded-2xl">
                <div className="bg-transparent rounded-2xl h-[100%]">
                    {data && <AdministrativeAndFunctionalZoneTable data={data}/>}
                </div>
            </div>
        </div>
    );
}