import React from "react";
import {ProductionStageFormTemplate} from "./ProductionStageFormTemplate";

interface FormProps {

}

export function ProductionStageFormCreate(props: FormProps) {

    return (
        <ProductionStageFormTemplate/>
    )
}