import React from "react";
import {useAppSelector} from "../../hooks/useAppSelector";

export function FilterMenu () {
    const filtersForMenuFilters = useAppSelector(state => state.filtersForMenuFilters)

    return (
        <div className={`flex flex-col bg-white mt-1.5`} key={filtersForMenuFilters.key}>
            {filtersForMenuFilters.filters.map(filter =>
                <div className="mx-3 my-2">
                    <div className="text-base mb-2.5">{filter.filterName}</div>
                    {filter.filter}
                </div>)}
        </div>
    )
}