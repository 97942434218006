import React from "react";
import {TablePage} from "../../model/utils/TablePage";
import {
    ProductionOrderExecutionJournalRow,
    ProductionTaskStatus
} from "../../model/production-order-execution/ProductionOrderExecution";
import {Table, TableColumn} from "../tables/Table";
import {useNavigate} from "react-router-dom";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {getTableRowBgColor, getTableRowTitle} from "../utils/ProductionOrderExecution";

interface TableProps {
    data: TablePage<ProductionOrderExecutionJournalRow>
}

export function ProductionOrderExecutionJournalTable(props: TableProps) {
    const {updateFilterPage} = useFiltersActions()

    const navigate = useNavigate()

    const numberColumn: TableColumn<ProductionOrderExecutionJournalRow> = {
        columnName: 'П/№',
        extractCellContent: row => row.documentNumber
    }

    const priorityColumn: TableColumn<ProductionOrderExecutionJournalRow> = {
        columnName: 'Очередь',
        extractCellContent: row => row.priority
    }

    const productionLineColumn: TableColumn<ProductionOrderExecutionJournalRow> = {
        columnName: 'Производственная линия',
        extractCellContent: row => row.productionLine.name
    }

    const workOrderNumberColumn: TableColumn<ProductionOrderExecutionJournalRow> = {
        columnName: '№ Work',
        extractCellContent: row => row.workOrderNumber
    }

    const mainProductColumn: TableColumn<ProductionOrderExecutionJournalRow> = {
        columnName: 'Основная продукция',
        extractCellContent: row => `${row.mainProduct.nomenclature.name}: ${row.mainProduct.quantity} ${row.mainProduct.nomenclature.measurementUnit.name}`
    }

    const additionalEquipmentColumn: TableColumn<ProductionOrderExecutionJournalRow> = {
        columnName: 'Доп. комплектация',
        displayAtList: true,
        extractCellContent: row => row.additionalEquipment.length === 0 ? "–" : row.additionalEquipment.map(equipment => `${equipment.nomenclature.name}, ${equipment.quantity} ${equipment.nomenclature.measurementUnit.name}`)
    }

    function actionOnRowClick(row: ProductionOrderExecutionJournalRow) {
        navigate(`/production-order/execution?id=${row.documentNumber}`)
    }

    function sortRows(rows: ProductionOrderExecutionJournalRow[]): ProductionOrderExecutionJournalRow[][] {
        const inProductionOrders = rows.filter(row => row.executionStatus === ProductionTaskStatus.IN_PRODUCTION)
        const inLineOrders = rows.filter(row => row.executionStatus === ProductionTaskStatus.IN_LINE)
        const unavailableOrders = rows.filter(row => row.executionStatus === ProductionTaskStatus.UNAVAILABLE)

        return [inProductionOrders, inLineOrders, unavailableOrders]
    }

    return (
        <Table<ProductionOrderExecutionJournalRow>
            tableColumns={[numberColumn, priorityColumn, productionLineColumn, workOrderNumberColumn, mainProductColumn, additionalEquipmentColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            getTableRowBgColor={(row: ProductionOrderExecutionJournalRow) => getTableRowBgColor(row.executionStatus)}
            getTableRowTitle={(row: ProductionOrderExecutionJournalRow) => getTableRowTitle(row.executionStatus)}
            tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => updateFilterPage({
                    key: TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER,
                    value: newPageNumber
                })
            }}
            sortRows={{
                sort: sortRows,
                separators: [
                    <tr>
                        <td colSpan={6}
                            className='whitespace-nowrap text-center pb-1 border-t-[0.5px] border-t-black/[.20]'>В
                            работе
                        </td>
                    </tr>,
                    <tr>
                        <td colSpan={6}
                            className='whitespace-nowrap text-center py-1 border-t-[0.5px] border-t-black/[.20]'>Ожидают
                            исполнения
                        </td>
                    </tr>,
                    <tr>
                        <td colSpan={6}
                            className='whitespace-nowrap text-center py-1 border-t-[0.5px] border-t-black/[.20]'>Недоступны
                            к исполнению
                        </td>
                    </tr>
                ]
            }}
        />
    )
}