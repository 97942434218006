import React from "react";
import {UserFormTemplate} from "./UserFormTemplate";
import {useGetIndividualQuery} from "../../store/backend-api/backend.api";
import {User} from "../../model/users/User";
import {Individuals} from "../../model/individuals/Individuals";

interface FormProps {
    data: User
}

export function UserFormRead(props: FormProps) {
    const {data: individual, isSuccess} = useGetIndividualQuery(props.data.subjectId);
    function getSubjectOption(individualData: Individuals) {
        return {
            label: `${individualData.lastName} ${individualData.firstName} ${individualData.middleName}`,
            value: individualData.id!
        }
    }

    return (<>
        {isSuccess && <UserFormTemplate data={props.data} subject={getSubjectOption(individual)} readonly={true}/>}
    </>)
}