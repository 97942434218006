import React, {useState} from "react"
import DatePicker, {registerLocale} from "react-datepicker"
import ru from "date-fns/locale/ru";


interface Props {
    onChange: (startDate: Date | null, endDate: Date | null) => void
    placeholder?: string
    startDate?: Date,
    endDate?: Date
}

registerLocale("ru", ru)

export function SeparatedDatePicker(props: Props) {
    const [startDate, setStartDate] = useState<Date | null>(
        props.startDate ? new Date(props.startDate) : null
    )
    const [endDate, setEndDate] = useState<Date | null>(
        props.endDate ? new Date(props.endDate) : null
    )

    function handleStartDate(date: Date | null) {
        setStartDate(date)
        if (endDate && date && date > endDate) {
            setEndDate(null)
            props.onChange(date, null)
        } else {
            props.onChange(date, endDate)
        }
    }

    return (<>
            <div className="flex flex-col items-start gap-y-1 ps-2">
                <div className="flex gap-2 items-center">
                    <div>От:</div>
                    <DatePicker
                        locale="ru"
                        dateFormat="dd.MM.yyyy"
                        selected={startDate}
                        onChange={(data) => {
                            handleStartDate(data)
                        }}
                        isClearable={!!startDate}
                        onKeyDown={(e) => {
                            e.preventDefault()
                        }}
                        className={`border-gray-400/[.80] block bg-white border-2 rounded-md px-3 mt-[2px] h-9 mb-[1px] font-light text-base`}
                        wrapperClassName="w-full"
                        placeholderText={
                            props.placeholder || "Выберите дату"
                        }
                        startDate={startDate}
                        endDate={endDate}
                        selectsStart
                    /></div>
                <div className="flex gap-2 items-center">
                    <div>До:</div>
                    <DatePicker
                    locale="ru"
                    dateFormat="dd.MM.yyyy"
                    selected={endDate}
                    onChange={(date) => {
                        setEndDate(date)
                        props.onChange(startDate, date)
                    }}
                    isClearable={!!endDate}
                    onKeyDown={(e) => {
                        e.preventDefault()
                    }}
                    className={`border-gray-400/[.80] block bg-white border-2 rounded-md px-3 mt-[2px] h-9 mb-[1px] font-light text-base`}
                    wrapperClassName="w-full"
                    placeholderText={
                        props.placeholder ? props.placeholder : "Выберите дату"
                    }
                    minDate={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsEnd
                /></div>
            </div>
        </>
    )
}
