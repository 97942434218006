import React from "react";
import {FilterForMenu} from "../../store/slices/filterNodes.slice";

export interface MenuProps {
    filterNodes: FilterForMenu[]
}

export function NewFilterMenu({filterNodes}: MenuProps) {

    return (
        <div className={`flex flex-col min-w-[320px] gap-y-3 pt-3`}>
            {filterNodes.map(filter =>
                    <div key={filter.filterName} className="mx-4">
                        <div className="text-base mb-2.5">{filter.filterName}</div>
                        <div className="w-full">{filter.filter}</div>
                    </div>)
            }
        </div>
    )
}