import React from "react";
import {ProductionStage} from "../../model/production-stage/ProductionStage";
import {ProductionStageFormTemplate} from "./ProductionStageFormTemplate";

interface FormProps {
    data: ProductionStage
}

export function ProductionStageFormEdit(props: FormProps) {

    return (
        <ProductionStageFormTemplate data={props.data}/>
    )
}