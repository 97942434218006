import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InitialStateInterface {
    userPhotoId: string | null,
    userPhoto: Blob | null
}

const initialState: InitialStateInterface = {
    userPhotoId: null,
    userPhoto: null
}

interface SetPhoto {
    userPhotoId: string,
    userPhoto: Blob
}

export const userPhotoSlice = createSlice({
    name: "userPhoto",
    initialState,
    reducers: {
        setUserPhoto: (state, action: PayloadAction<SetPhoto | null>) => {
            state.userPhotoId = action.payload ? action.payload.userPhotoId : null
            state.userPhoto = action.payload ? action.payload.userPhoto : null
        }
    }
})

export const userPhotoReducer = userPhotoSlice.reducer
export const userPhotoActions = userPhotoSlice.actions