import {TechnologicalOperation} from "../../../model/technological-operation/TechnologicalOperation";
import {TechnologicalOperationFormTemplate} from "./TechnologicalOperationFormTemplate";

interface FormProps {
    data: TechnologicalOperation
}

export function TechnologicalOperationFormRead(props: FormProps) {

    return (
        <TechnologicalOperationFormTemplate data={props.data} readonly={true}/>
    )
}