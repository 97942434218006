import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Product} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {
    useCreateProductOfBillOfMaterialsMutation,
    useDeleteProductOfBillOfMaterialsMutation,
    useUpdateProductOfBillOfMaterialsMutation
} from "../../store/backend-api/backend.api";
import {OptionType} from "../../model/option-type/OptionType";
import {SaveButton} from "../ui/buttons/SaveButton";
import ReactModal from "react-modal";
import isEqual from "lodash.isequal";
import {InternalProductsForm} from "./InternalProductsForm";

interface FormProps {
    products: Product[],
    billOfMaterialsId: number,
    editFromModalForChoiceMaterials?: boolean,
    editFromModalForChoiceProducts?: boolean,
    stepId?: number
}

interface FormFields {
    selectedProduct: string | null,
    products: {
        productId: number | null,
        myProductId: string,
        isTarget: boolean,
        productName: OptionType<number> | null,
        productQuantity: string,
        productSize: string,
        sizeMeasurementUnit: OptionType<number> | null,
        drawingNumber: string,
        outgoingInStep: string,
        includedInStep: number | null,
        tariff: string,
        defaultProductNomenclatureId: number | null
    }[]
}

export function ProductsFormTemplate(props: FormProps) {
    const [doUpdateProductOfBillOfMaterials] = useUpdateProductOfBillOfMaterialsMutation()
    const [doCreateProductOfBillOfMaterials] = useCreateProductOfBillOfMaterialsMutation()
    const [doDeleteProductOfBillOfMaterials] = useDeleteProductOfBillOfMaterialsMutation()
    const [deletedProductsIds, setDeletedProductsIds] = useState<number[]>([])
    const defaultValues = setDefaultValues().map(product => ({
        productId: product.id,
        myProductId: product.id.toString(),
        isTarget: product.isTarget,
        productName: {
            label: `${product.nomenclature.fullName}, ${product.nomenclature.measurementUnit.name}`,
            value: product.nomenclature.id
        },
        productQuantity: product.quantity,
        productSize: product.size.value !== null ? product.size.value : "",
        sizeMeasurementUnit: product.size.measurementUnit !== null ? {
            label: `${product.size.measurementUnit.name}`,
            value: product.size.measurementUnit.id
        } : null,
        drawingNumber: product.nomenclature.drawingNumber || "",
        outgoingInStep: product.isProductIn ? `${product.isProductIn}` : "",
        includedInStep: product.isMaterialIn ? product.isMaterialIn : null,
        tariff: product.tariff || "",
        defaultProductNomenclatureId: product.nomenclature.id
    }))

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }
    const {control, handleSubmit, formState: {isDirty}, setValue, resetField} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: {
            products: defaultValues,
            selectedProduct: defaultValues.find(value => +value.outgoingInStep === props.stepId)?.myProductId || null
        }
    })

    const onClickSaveButton: SubmitHandler<FormFields> = async data => {
        if (!isEqual(data, defaultValues)) {
            if (props.editFromModalForChoiceProducts && props.stepId) {
                const firstUpdateProduct = data.products.find(product => product.outgoingInStep)
                data.products.forEach(product => {
                    if (product.myProductId === data.selectedProduct) {
                        product.outgoingInStep = `${props.stepId!}`
                    }
                    if (product.myProductId === firstUpdateProduct?.myProductId && firstUpdateProduct?.myProductId !== data.selectedProduct) {
                        product.outgoingInStep = ""
                    }
                })
                if (firstUpdateProduct && firstUpdateProduct.myProductId !== data.selectedProduct) {
                    await doUpdateProductOfBillOfMaterials({
                        billOfMaterialsId: props.billOfMaterialsId,
                        productId: firstUpdateProduct.productId!,
                        body: {
                            quantity: firstUpdateProduct.productQuantity,
                            nomenclatureId: firstUpdateProduct.productName!.value,
                            size: {
                                value: firstUpdateProduct.productSize !== "" ? firstUpdateProduct.productSize : null,
                                measurementUnitId: firstUpdateProduct.sizeMeasurementUnit !== null ? firstUpdateProduct.sizeMeasurementUnit.value : null
                            },
                            isMaterialIn: firstUpdateProduct.includedInStep ? +firstUpdateProduct.includedInStep : null,
                            isProductIn: null,
                            tariff: firstUpdateProduct.tariff !== "" ? firstUpdateProduct.tariff : null
                        }
                    }).unwrap()
                }

                data.products.forEach((product) => {
                    if (product.productId && !isEqual(product, defaultValues.find(value => value.productId === product.productId))) {
                        doUpdateProductOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            productId: product.productId,
                            body: {
                                quantity: product.productQuantity,
                                nomenclatureId: product.productName!.value,
                                size: {
                                    value: product.productSize !== "" ? product.productSize : null,
                                    measurementUnitId: product.sizeMeasurementUnit !== null ? product.sizeMeasurementUnit.value : null
                                },
                                isMaterialIn: product.includedInStep ? +product.includedInStep : null,
                                isProductIn: product.myProductId === data.selectedProduct ? props.stepId! : null,
                                tariff: product.tariff !== "" ? product.tariff : null
                            }
                        })
                    }
                    if (product.productId === null) {
                        doCreateProductOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            body: {
                                quantity: product.productQuantity,
                                nomenclatureId: product.productName!.value,
                                size: {
                                    value: product.productSize !== "" ? product.productSize : null,
                                    measurementUnitId: product.sizeMeasurementUnit !== null ? product.sizeMeasurementUnit.value : null
                                },
                                isMaterialIn: product.includedInStep ? +product.includedInStep : null,
                                isProductIn: product.myProductId === data.selectedProduct ? props.stepId! : null,
                                tariff: product.tariff !== "" ? product.tariff : null
                            }
                        })
                    }
                })
            } else {
                data.products.forEach((product) => {
                    if (product.productId !== null && !isEqual(product, defaultValues.find(value => value.productId === product.productId))) {
                        doUpdateProductOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            productId: product.productId,
                            body: {
                                quantity: product.productQuantity,
                                nomenclatureId: product.productName!.value,
                                size: {
                                    value: product.productSize !== "" ? product.productSize : null,
                                    measurementUnitId: product.sizeMeasurementUnit !== null ? product.sizeMeasurementUnit.value : null
                                },
                                isMaterialIn: product.includedInStep ? product.includedInStep : null,
                                isProductIn: product.outgoingInStep ? +product.outgoingInStep : null,
                                tariff: product.tariff !== "" ? product.tariff : null
                            }
                        })
                    }
                    if (product.productId === null) {
                        doCreateProductOfBillOfMaterials({
                            billOfMaterialsId: props.billOfMaterialsId,
                            body: {
                                quantity: product.productQuantity,
                                nomenclatureId: product.productName!.value,
                                size: {
                                    value: product.productSize !== "" ? product.productSize : null,
                                    measurementUnitId: product.sizeMeasurementUnit !== null ? product.sizeMeasurementUnit.value : null
                                },
                                isMaterialIn: product.includedInStep ? product.includedInStep : null,
                                isProductIn: product.outgoingInStep ? +product.outgoingInStep : null,
                                tariff: product.tariff !== "" ? product.tariff : null
                            }
                        })
                    }
                })
            }
            if (deletedProductsIds.length !== 0) {
                deletedProductsIds.forEach(id => doDeleteProductOfBillOfMaterials({
                    billOfMaterialsId: props.billOfMaterialsId,
                    productId: id
                }))
            }
        }
    }

    function setDefaultValues() {
        if (props.editFromModalForChoiceProducts && props.stepId) {
            return props.products.filter(product => (product.isProductIn === null || product.isProductIn === props.stepId) && product.isMaterialIn !== props.stepId)
        }
        return props.products
    }

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)} className="px-2 pt-[18px] max-w-full overflow-auto">
            <InternalProductsForm control={control} setValue={setValue} resetField={resetField} deletedProductsIds={deletedProductsIds} setDeletedProductsIds={setDeletedProductsIds} editFromModalForChoiceProducts={props.editFromModalForChoiceProducts} stepId={props.stepId}/>
            {isDirty && <>
                <div className="border-b-[1px] border-slate-400/[.80] border-dotted h-1 w-full mt-3 mb-6"></div>
                <div className="flex flex-row justify-end">
                    <div id={"button2"}>
                        <SaveButton onClick={handleSubmit(onClickSaveButton)}/>
                    </div>
                </div>
            </>}
        </form>
    )
}