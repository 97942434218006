import {useSearchParams} from "react-router-dom";
import {useGetAdministrativeAndFunctionalZoneQuery} from "../../store/backend-api/backend.api";
import React, {useEffect, useState} from "react";
import {
    AdministrativeAndFunctionalZoneFormEdit
} from "../../components/administrative-and-functional-zone/form/AdministrativeAndFunctionalZoneFormEdit";

export function EditAdministrativeAndFunctionalZoneForm() {
    const [searchParams] = useSearchParams()
    const id = Number(searchParams.get("id"))
    const {data, isSuccess} = useGetAdministrativeAndFunctionalZoneQuery(id)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [data])

    return (<div key={key}>
        {isSuccess && <AdministrativeAndFunctionalZoneFormEdit data={data}/>}
    </div>)
}