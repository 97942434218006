import React from "react";
import {AdministrativeRolesRow} from "../../model/administrative-roles/AdministrativeRole";
import {TableColumn} from "../tables/Table";
import {useNavigate} from "react-router-dom";
import {TablePage} from "../../model/utils/TablePage";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {Table} from "../tables/Table";

interface TableProps {
    data: TablePage<AdministrativeRolesRow>
}

export function AdministrativeRolesTable(props: TableProps) {
    const {updateFilterPage} = useFiltersActions()
    const navigate = useNavigate()

    const idColumn: TableColumn<AdministrativeRolesRow> = {
        columnName: "ID",
        extractCellContent: row => row.id
    }

    const nameColumn: TableColumn<AdministrativeRolesRow> = {
        columnName: "Наименование роли",
        extractCellContent: row => row.name
    }

    function onRowClick(row: AdministrativeRolesRow) {
        navigate(`/administrative-roles?id=${row.id}`)
    }

    return (<Table<AdministrativeRolesRow>
            tableColumns={[idColumn, nameColumn]}
            rows={props.data.rows}
            onRowClick={onRowClick}
            tableFilterKey={TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER, value: newPageNumber})
                }
            }}/>
    )
}