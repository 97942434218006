import {useSearchParams} from "react-router-dom";
import {useGetTechnologicalOperationQuery} from "../../store/backend-api/backend.api";
import React, {useEffect, useState} from "react";
import {
    TechnologicalOperationFormEdit
} from "../../components/technological-operation/form/TechnologicalOperationFormEdit";

export function EditTechnologicalOperationForm() {
    const [searchParams] = useSearchParams()
    const id = Number(searchParams.get("id"))
    const {data, isSuccess} = useGetTechnologicalOperationQuery(id)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [data])

    return (<div key={key}>
        {isSuccess && (<TechnologicalOperationFormEdit data={data}/>)}
    </div>)
}