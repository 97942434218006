import {Gender, ContactType} from "../model/individuals/Individuals";

export const gendersToString = new Map<Gender, string>([
    [Gender.MALE, 'Мужской'],
    [Gender.FEMALE, 'Женский'],
    [Gender.NONE, 'Не определен'],
])

export const booleansToString = new Map<boolean, string>([
    [true, "Да"],
    [false, "Нет"]
])

export const contactTypesToString = new Map<ContactType, string>([
    [ContactType.PHONE, "Телефон"],
    [ContactType.EMAIL, "Email"],
    [ContactType.WHATSAPP, "Whatsapp"],
    [ContactType.VK, "VK"],
    [ContactType.INSTAGRAM, "Instagram"],
    [ContactType.TELEGRAM, "Telegram"],
    [ContactType.YOUTUBE, "Youtube"],
    [ContactType.WEB_SITE, "Web-сайт"],
])