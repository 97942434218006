import {
    AdministrativeAndFunctionalZone
} from "../../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {useLazyGetIndividualQuery, useLazyGetUserQuery} from "../../../store/backend-api/backend.api";
import {useEffect} from "react";
import {AdministrativeAndFunctionalZoneFormTemplate} from "./AdministrativeAndFunctionalZoneFormTemplate";

interface FormProps {
    data: AdministrativeAndFunctionalZone
}

export function AdministrativeAndFunctionalZoneFormRead(props: FormProps) {
    const [doGetUser, {data: supervisor}] = useLazyGetUserQuery()
    const [doGetIndividuals, {data: individual}] = useLazyGetIndividualQuery()

    useEffect(() => {
        if (props.data.supervisorId) {
            doGetUser(props.data.supervisorId)
        }
    }, [props.data]);

    useEffect(() => {
        if (supervisor) {
            doGetIndividuals(supervisor.subjectId)
        }
    }, [supervisor]);

    return (<>
        {(props.data.supervisorId ? individual : true) &&
            <AdministrativeAndFunctionalZoneFormTemplate data={props.data} readonly={true}
                                                         supervisorSubject={individual ? `${individual.lastName} ${individual.firstName} ${individual.middleName}` : undefined}/>}
    </>)
}