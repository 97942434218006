import React from "react";
import {ProductionOrderFormTemplate} from "./ProductionOrderFormTemplate";
import {ProductionOrder} from "../../../model/production-order/ProductionOrder";

export interface FormProps {
    data: ProductionOrder
}

export function ProductionOrderFormRead(props: FormProps) {

    return (
        <ProductionOrderFormTemplate readonly={true} data={props.data}/>
    )
}