import React from "react";
import {
    TechnologicalOperationFormCreate
} from "../../components/technological-operation/form/TechnologicalOperationFormCreate";

export function CreateTechnologicalOperationForm() {

    return (
        <TechnologicalOperationFormCreate/>
    )
}