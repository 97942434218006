import {useDispatch} from "react-redux";
import {useMemo} from "react";
import {bindActionCreators} from "@reduxjs/toolkit";
import {selectedMeasurementOkeiActions} from "../store/slices/selectedMeasurementOkei.slice";

export const useSelectedMeasurementOkeiActions = () => {
    const dispatch = useDispatch()

    return useMemo(() =>
        bindActionCreators(selectedMeasurementOkeiActions, dispatch), [dispatch])
}