import React, {useEffect, useState} from "react";
import {PiFileTextThin} from "react-icons/pi";
import {productionTaskStatusForProductionOrderExecutionToString} from "../../../data/ProductionOrderExecutionData";
import {
    ProductionTask,
    ProductionTaskStatus,
    TasksForProductionOrderExecution
} from "../../../model/production-order-execution/ProductionOrderExecution";
import {
    useUpdateProductionTaskMutation,
    useUpdateProductionTaskStatusMutation
} from "../../../store/backend-api/backend.api";
import {Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import {NomenclatureInfo} from "../../../model/production-order/ProductionOrder";
import {
    ModalForWorkingWithNomenclatureAttachments
} from "../../nomenclature/ModalForWorkingWithNomenclatureAttachments";
import {getTableRowBgColor} from "../../utils/ProductionOrderExecution";
import {OptionInterface, OptionsButton} from "../../ui/buttons/OptionsButton";
import {ModalForOpenProductionTaskAttachments} from "./ModalForOpenProductionTaskAttachments";

interface TableProps {
    tasks: TasksForProductionOrderExecution
}

interface FormFields {
    productionTasks: ProductionTaskForFormFields[]
}

interface ProductionTaskForFormFields {
    documentNumber: number,
    materials: [
        {
            nomenclatureId: number,
            name: string,
            measurementUnit: {
                id: number,
                name: string
            },
            quantity: string | null,
            sourceProductionStage: {
                id: number,
                name: string,
                status: ProductionTaskStatus
            } | null
        }
    ],
    product: {
        nomenclature: NomenclatureInfo,
        plannedQuantity: number,
        actualQuantity: number,
        size: number | null,
        sizeMeasurementUnit: {
            id: number,
            name: string
        } | null
    },
    productionStage: {
        id: number,
        name: string
    },
    status: ProductionTaskStatus,
    nextProductionStage: {
        id: number,
        name: string,
        status: ProductionTaskStatus
    } | null,
    newActualQuantity: number,
    checked: number | null
}

export function ProductionTasksTable(props: TableProps) {
    const [doUpdateProductionTaskStatus] = useUpdateProductionTaskStatusMutation()
    const [doUpdateProductionTask, {isSuccess}] = useUpdateProductionTaskMutation()
    const [modalForWorkingWithNomenclatureAttachmentsIsOpen, setModalForWorkingWithProductionTaskAttachmentsIsOpen] = useState<boolean>(false)
    const [selectedProductionTaskDocumentNumber, setSelectedProductionTaskDocumentNumber] = useState<number | null>(null)

    const defaultFormValues: FormFields = {
        productionTasks: props.tasks.productionTasks.map(productionTask => ({
            ...productionTask,
            newActualQuantity: +productionTask.product?.actualQuantity,
            checked: productionTask.status === ProductionTaskStatus.IN_PRODUCTION ? productionTask.documentNumber : null
        }))
    }

    const productionTaskOptions: OptionInterface<ProductionTask>[] = [
        {
            optionName: "Нет на складе",
            onClickOption: productionTask => {
                doUpdateProductionTaskStatus({
                    productionOrderDocumentNumber: props.tasks.productionOrderDocumentNumber,
                    documentNumber: productionTask.documentNumber,
                    body: ProductionTaskStatus.POSTPONED
                })
            },
            condition: productionTask => [ProductionTaskStatus.IN_PRODUCTION, ProductionTaskStatus.IN_LINE, ProductionTaskStatus.SUSPENDED].includes(productionTask.status)
        }
    ]

    const {control, formState: {isValid}} = useForm({
        mode: "onChange",
        defaultValues: defaultFormValues
    })
    const {fields} = useFieldArray({
        name: "productionTasks",
        control: control
    })
    const currentFormValue = useWatch({control})

    function onClickFileIcon(documentNumber: number) {
        setSelectedProductionTaskDocumentNumber(documentNumber)
        setModalForWorkingWithProductionTaskAttachmentsIsOpen(true)
    }

    function onBlurInput(documentNumber: number) {
        const productionTask = currentFormValue.productionTasks!.find(task => task.documentNumber === documentNumber)
        if (isValid) {
            doUpdateProductionTask({
                productionOrderDocumentNumber: props.tasks.productionOrderDocumentNumber,
                documentNumber: documentNumber,
                quantity: productionTask!.newActualQuantity!.toString()
            })
        }
    }

    useEffect(() => {
        if (isSuccess) {
            props.tasks.productionTasks.forEach(task => {
                if (task.product.plannedQuantity === task.product.actualQuantity && task.status !== ProductionTaskStatus.COMPLETED) {
                    doUpdateProductionTaskStatus({
                        productionOrderDocumentNumber: props.tasks.productionOrderDocumentNumber,
                        documentNumber: task.documentNumber,
                        body: ProductionTaskStatus.COMPLETED
                    })
                }
            })
        }
    }, [isSuccess, props.tasks]);

    return (
        <form className="pt-[18px] max-w-full overflow-auto">
            <table>
                <thead>
                <tr>
                    <th className="border border-black text-base font-normal text-center px-2">В работе</th>
                    <th className="border border-black text-base font-normal text-center px-2">Предыдущий этап</th>
                    <th className="border border-black text-base font-normal text-center px-2">Материалы</th>
                    <th className="border border-black text-base font-normal text-center px-2">Кол-во</th>
                    <th className="border border-black text-base font-normal text-center px-2">Изделие</th>
                    <th className="border border-black text-base font-normal text-center px-2">Требуемое кол-во</th>
                    <th className="border border-black text-base font-normal text-center px-2">Произведенное кол-во</th>
                    <th className="border border-black text-base font-normal text-center px-2">Осталось произвести</th>
                    <th className="border border-black text-base font-normal text-center px-2">Номер чертежа</th>
                    <th className="border border-black text-base font-normal text-center px-2">Размер</th>
                    <th className="border border-black text-base font-normal text-center px-2">Файлы</th>
                    <th className="border border-black text-base font-normal text-center px-2">Статус задания</th>
                    <th className="border border-black text-base font-normal text-center px-2">Текущий этап</th>
                    <th className="border border-black text-base font-normal text-center px-2">Следующий этап</th>
                    <th className="border border-black text-base font-normal text-center px-2"></th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {fields.map((productionTask, index) =>
                    <tr key={productionTask.id} className={getTableRowBgColor(productionTask.status)}>
                        <td className="border border-black text-center align-center pt-2">
                            <Controller control={control} name={`productionTasks.${index}.checked`} rules={{}}
                                        render={() =>
                                            <input className="w-5 h-5" type="checkbox"
                                                   disabled={productionTask.status === ProductionTaskStatus.COMPLETED}
                                                   value={productionTask.documentNumber}
                                                   onChange={(event) => {
                                                       if (event.target.checked) {
                                                           doUpdateProductionTaskStatus({
                                                               productionOrderDocumentNumber: props.tasks.productionOrderDocumentNumber,
                                                               documentNumber: productionTask.documentNumber,
                                                               body: ProductionTaskStatus.IN_PRODUCTION
                                                           })
                                                       } else if (!event.target.checked) {
                                                           doUpdateProductionTaskStatus({
                                                               productionOrderDocumentNumber: props.tasks.productionOrderDocumentNumber,
                                                               documentNumber: productionTask.documentNumber,
                                                               body: ProductionTaskStatus.SUSPENDED
                                                           })
                                                       }
                                                   }}
                                                   checked={productionTask.status === ProductionTaskStatus.IN_PRODUCTION}></input>
                                        }/>
                        </td>
                        <td className="border border-black text-base font-light pl-2">{productionTask.materials.length ? productionTask.materials.map((material, index) =>
                            <span key={index}
                                  className={material.sourceProductionStage?.status === ProductionTaskStatus.COMPLETED ? "text-green-700" : "text-red-700"}>
                        {material.sourceProductionStage?.name}<br/></span>) : "–"}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{productionTask.materials.length ? productionTask.materials.map((material, index) =>
                            <span key={index}>
                        {material.name}<br/></span>) : "–"}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{productionTask.materials.length ? productionTask.materials.map((material, index) =>
                            <span
                                key={index}>{`${material.quantity || "–"} ${material.measurementUnit.name}`}
                                <br/></span>) : ""}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{`${productionTask.product.nomenclature.name}, ${productionTask.product.nomenclature.mainSKU || "–"}`}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{`${productionTask.product.plannedQuantity} ${productionTask.product.nomenclature.measurementUnit.name}`}</td>
                        <td className="border border-black text-base font-light pl-2 bg-white">{
                            <Controller control={control} name={`productionTasks.${index}.newActualQuantity`} rules={{
                                required: {
                                    value: true,
                                    message: "Это обязательное поле"
                                },
                                pattern: {
                                    value: /^[1-9]\d*$/,
                                    message: 'Введите положительное число',
                                }
                            }} render={({field: {onChange, value}, fieldState: {error}}) => <>
                                <input type="number"
                                       disabled={!(productionTask.status === ProductionTaskStatus.IN_PRODUCTION)}
                                       onChange={onChange} value={value}
                                       className="bg-white w-full h-9"
                                       onBlur={() => {
                                           onBlurInput(productionTask.documentNumber)
                                       }}/>
                                {error && <p className="border-t text-sm font-normal text-red-400">{error.message}</p>}
                            </>}/>}
                        </td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{`${(+productionTask.product.plannedQuantity - +productionTask.product.actualQuantity)} ${productionTask.product.nomenclature.measurementUnit.name}`}</td>
                        <td className="border border-black text-base font-light px-2">{productionTask.product.nomenclature.drawingNumber || "–"}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{(productionTask.product.size || productionTask.product.sizeMeasurementUnit) ? `${productionTask.product.size || "–"} ${productionTask.product.sizeMeasurementUnit?.name || "–"}` : "–"}</td>
                        <td className="border border-black text-base font-light px-2">{
                            <PiFileTextThin
                                className="ml-[14px] w-5 h-5"
                                onClick={() => onClickFileIcon(productionTask.documentNumber)}/>}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{productionTaskStatusForProductionOrderExecutionToString.get(productionTask.status)}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap">{productionTask.productionStage.name}</td>
                        <td className={productionTask.nextProductionStage?.status === ProductionTaskStatus.COMPLETED ? "border border-black text-base font-light px-2 whitespace-nowrap text-green-700" : "border border-black text-base font-light px-2 whitespace-nowrap text-red-700"}>{productionTask.nextProductionStage?.name || "–"}</td>
                        <td className="border border-black text-base font-light px-2 whitespace-nowrap"><OptionsButton
                            row={productionTask} options={productionTaskOptions}/></td>
                    </tr>)}
                </tbody>
            </table>
            {selectedProductionTaskDocumentNumber &&
                <ModalForOpenProductionTaskAttachments
                    isOpen={modalForWorkingWithNomenclatureAttachmentsIsOpen}
                    setIsOpen={setModalForWorkingWithProductionTaskAttachmentsIsOpen}
                    productionTaskDocumentNumber={selectedProductionTaskDocumentNumber}/>}
        </form>
    );
}