import React from "react"
import {FilterMenu} from "../filter-menu/FilterMenu";
import {useAppSelector} from "../../hooks/useAppSelector";
import {CloseFilterMenuButton} from "../ui/buttons/CloseFilterMenuButton";

export function FilterMenuComponent () {
    const isVisible = useAppSelector(state => state.filtersForMenuFilters.isVisible)
    function filterMenuPositionDefinition() {
        if (isVisible) {
            return "min-w-[310px] w-[310px]"
        }
        else {
            return "w-0"
        }
    }

    return <div className={`sm:max-w-[768px] md:max-w-[1024px] lg:max-w-[1280px] xl:max-w-[1536px] 2xl:max-w-[100%] ${filterMenuPositionDefinition()} h-screen border-l-[0.5px] border-l-solid border-l-slate-400/[.80] overflow-y-auto`}>
                <div className="flex flex-row justify-between border-b-[0.5px] border-b-solid border-b-slate-400/[.80]">
                    <span className=" text-[17px] m-3 mt-2.5">Фильтры</span>
                    <CloseFilterMenuButton />
                </div>
                <FilterMenu/>
        </div>
}