import React from 'react';
import {Individuals} from "../../model/individuals/Individuals";
import {IndividualsFormTemplate} from "./IndividualsFormTemplate";

interface FormProps {
    data: Individuals
}

export function IndividualsFormRead (props: FormProps) {

    return (
        <IndividualsFormTemplate data={props.data} readonly={true}/>
    )
}