import {GuideFilter} from "../filters/GuideFilter";

export interface Individuals {
    id: number,
    firstName: string,
    lastName: string,
    middleName: string,
    gender: Gender,
    birthDate: Date,
    comment: string | null,
    photoId: string | null,
    itn: string | null,
    snils: string | null,
    contactInfo: ContactInfo[]
}

export interface ContactInfo {
    contactType: ContactType,
    value: string,
    comment: string
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NONE = 'NONE'
}

export enum ContactType {
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    WHATSAPP = 'WHATSAPP',
    TELEGRAM = 'TELEGRAM',
    VK = 'VK',
    INSTAGRAM = 'INSTAGRAM',
    YOUTUBE = 'YOUTUBE',
    WEB_SITE = 'WEB_SITE'
}

export interface CreateOrUpdateIndividuals {
    firstName: string,
    lastName: string,
    middleName: string,
    gender: Gender,
    birthDate: Date,
    comment: string,
    itn: string | null,
    snils: string | null,
    contactInfo: ContactInfo[]
}

export interface IndividualsRow {
    id: number,
    fullName: string,
    gender: Gender,
    isUser: boolean,
    username: string | null,
    contactInfo: string[]
}

export interface IndividualsGuideFilter extends GuideFilter {
    gender: Gender | null,
    isUser: boolean | null,
    sortedField: IndividualsSortedField | null
}

export enum IndividualsSortedField {
    ID = 'ID',
    FULL_NAME = 'FULL_NAME',
    GENDER = 'GENDER',
    IS_USER = 'IS_USER',
    USERNAME = 'USERNAME'
}