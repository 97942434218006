import React from "react"
import {useAppSelector} from "../../hooks/useAppSelector";
import {UserCardComponent} from "../profile/UserCardComponent";
import {AiOutlineRight} from "react-icons/ai";
import {NavBarLink} from "../navbar/NavBarLink";
import {Permission} from "../../model/administrative-roles/AdministrativeRole";
import {NavLinkGroup} from "../navbar/NavLinkGroup";

export function NavBarComponent() {
    const isNavBarVisible = useAppSelector(state => state.navBar.isNavBarVisible)

    function navBarPosition() {
        if (isNavBarVisible) {
            return "mr-0"
        } else {
            return "ml-[-310px]"
        }
    }

    return <div
        className={`${navBarPosition()} min-w-[310px] w-[310px] transition-all duration-500 ease-in-out h-screen border-r-[0.5px] border-r-solid border-r-slate-400/[.80] overflow-y-auto`}>
        <nav className="flex flex-col bg-white">

            <UserCardComponent/>

            <details open={true} className="group [&_summary::-webkit-details-marker]:hidden text-base">
                <summary className="flex items-center justify-between gap-2 px-3 py-1.5">
                    <span className="font-normal cursor-default"> Журналы документов </span>
                    <span>
                        <AiOutlineRight className="transition group-open:rotate-90 h-4 w-4"/>
                    </span>
                </summary>

                <NavBarLink to="/production-order/document-journal" position="pl-6" name="Производственные заказы"
                            authority={Permission.PRODUCTION_ORDER_VIEW}/>
                <NavBarLink to="/production-order/execution/document-journal" position="pl-6"
                            name="Исполнение производственных заказов"
                            authority={Permission.PRODUCTION_ORDER_EXECUTION}/>
                <NavBarLink to="/production-order/archive/report" position="pl-6" name="Архив производственных заказов"
                            authority={Permission.PRODUCTION_ORDER_VIEW}/>
                {/*<NavBarLink to="/requirement-consignment-note/document-journal" position="pl-6"
                            name="Требования-накладные" isActive={false}/>
                <NavBarLink to="/production-order/document-journal" position="pl-6" name="Перемещения"
                            isActive={false}/>*/}

            </details>
            <details open className="group [&_summary::-webkit-details-marker]:hidden text-base">
                <summary className="flex items-center justify-between gap-2 px-3 py-1.5">
                    <span className="font-normal cursor-default"> Справочники </span>
                    <span>
                        <AiOutlineRight className="transition group-open:rotate-90 h-4 w-4"/>
                    </span>
                </summary>
                <NavLinkGroup authorities={[Permission.NOMENCLATURE_VIEW, Permission.MEASUREMENT_UNIT_VIEW]}>
                    <NavBarLink to="/nomenclature/guide" position="pl-6" name="Номенклатура"
                                authority={Permission.NOMENCLATURE_VIEW}/>
                    {/* <NavBarLink to="/nomenclature/guide/raw-material" position="pl-9" name="Сырье" isActive={false}/>
                    <NavBarLink to="/nomenclature/guide/ready-product" position="pl-9" name="Готовая продукция"
                                isActive={false}/>
                    <NavBarLink to="/nomenclature/guide/uncompleted-manufacturing" position="pl-9"
                                name="Незавершенное производство" isActive={false}/>*/}
                    <NavBarLink to="/measurement-unit/guide" position="pl-6" name="Единицы измерения"
                                authority={Permission.MEASUREMENT_UNIT_VIEW}/>
                </NavLinkGroup>
                <NavLinkGroup
                    authorities={[Permission.ADMINISTRATIVE_ROLE_VIEW, Permission.USER_VIEW, Permission.INDIVIDUALS_VIEW]}>
                    <NavBarLink to="/administrative-roles/guide" position="pl-6" name="Административные роли"
                                authority={Permission.ADMINISTRATIVE_ROLE_VIEW}/>
                    <NavBarLink to="/user/guide" position="pl-6" name="Пользователи системы"
                                authority={Permission.USER_VIEW}/>
                    <NavBarLink to="/individuals/guide" position="pl-6" name="Физические лица"
                                authority={Permission.INDIVIDUALS_VIEW}/>
                </NavLinkGroup>
                <NavLinkGroup
                    authorities={[Permission.TECHNOLOGICAL_PROCESS_CHART_VIEW, Permission.PRODUCTION_STAGE_VIEW, Permission.TECHNOLOGICAL_OPERATION_VIEW]}>
                    <NavBarLink to="/technological-process-chart/guide" position="pl-6" name="Шаблоны и тех. карты"
                                authority={Permission.TECHNOLOGICAL_PROCESS_CHART_VIEW}/>
                    <NavBarLink to="/production-stages/guide" position="pl-6" name="Этапы производства"
                                authority={Permission.PRODUCTION_STAGE_VIEW}/>
                    <NavBarLink to="/technological-operation/guide" position="pl-6" name="Технологические операции"
                                authority={Permission.TECHNOLOGICAL_OPERATION_VIEW}/>
                </NavLinkGroup>
                <NavBarLink to="/administrative-and-functional-zone/guide" position="pl-6"
                            name="Административно-функциональные зоны"
                            authority={Permission.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_VIEW}/>
            </details>
            <details open className="group [&_summary::-webkit-details-marker]:hidden text-base">
                <summary className="flex items-center justify-between gap-2 px-3 py-1.5">
                    <span className="font-normal cursor-default"> Отчеты </span>
                    <span>
                        <AiOutlineRight className="transition group-open:rotate-90 h-4 w-4"/>
                    </span>
                </summary>
                {/*<NavBarLink to="/unmet-need-list/report" position="pl-6"
                            name="Ведомость необеспеченных потребностей в сырье" isActive={false}/>*/}
            </details>
            {/*<details open className="group [&_summary::-webkit-details-marker]:hidden text-base">*/}
            {/*    <summary className="flex items-center justify-between gap-2 px-3 py-1.5">*/}
            {/*        <span className="font-normal cursor-default"> Интерфейс оператора </span>*/}
            {/*        <span>*/}
            {/*            <AiOutlineRight className="transition group-open:rotate-90 h-4 w-4"/>*/}
            {/*        </span>*/}
            {/*    </summary>*/}
            {/*    <NavBarLink to="/production-task-queue/operator" position="pl-6"*/}
            {/*                name="Очередь производственных заданий"/>*/}
            {/*</details>*/}
        </nav>
    </div>
}