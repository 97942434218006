import React from "react";
import {Control, useFieldArray} from "react-hook-form";
import {ProductionOrderFormFields} from "./ProductionOrderFormTemplate";
import {
    ControlledPaginateAsyncSelectForTable
} from "../../ui/form-components/controlled/ControlledPaginateAsyncSelectForTable";
import {useLazySearchTechnologicalProcessChartGuidePageQuery} from "../../../store/backend-api/backend.api";
import {TablePage} from "../../../model/utils/TablePage";
import {TechnologicalProcessChart} from "../../../model/technological-process-chart/TechnologicalProcessChart";
import {DeleteButtonText} from "../../ui/buttons/DeleteButtonText";
import {AddButtonText} from "../../ui/buttons/AddButtonText";
import {ProductionOrderStatus} from "../../../model/production-order/ProductionOrder";

interface TableProps {
    control: Control<ProductionOrderFormFields>,
    readonly?: boolean,
    status?: ProductionOrderStatus
}

export function TableForAdditionalEquipments(props: TableProps) {
    const [doSearchTechnologicalProcessChartGuidePage] = useLazySearchTechnologicalProcessChartGuidePageQuery()
    const {fields, append, remove} = useFieldArray({
        name: "additionalEquipments",
        control: props.control
    })

    return (<>
        <table>
            <thead>
            <tr>
                <th className="border border-black text-base font-normal text-center px-2">№</th>
                <th className="border border-black text-base font-normal text-center px-2">Артикул</th>
                <th className="border border-black text-base font-normal text-center px-2">Наименование</th>
                <th className="border border-black text-base font-normal text-center px-2">Количество</th>
                <th className="border border-black text-base font-normal text-center px-2">Технологическая карта</th>
            </tr>
            </thead>
            <tbody>
            {fields.map((field, index) => <tr key={field.id}>
                <td className="border border-black text-base font-light px-2">{field.number}</td>
                <td className="border border-black text-base font-light px-2">{field.mainSKU || "–"}</td>
                <td className="border border-black text-base font-light px-2">{field.name || "–"}</td>
                <td className="border border-black text-base font-light px-2">{field.quantity || "–"}</td>
                <td className="border border-black text-base font-light px-2">
                    <ControlledPaginateAsyncSelectForTable control={props.control} rules={{
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        }
                    }} name={`additionalEquipments.${index}.technologicalProcessChart`}
                                                           request={doSearchTechnologicalProcessChartGuidePage}
                                                           mapFunc={(data: TablePage<TechnologicalProcessChart>) =>
                                                               data.rows.map(row => ({
                                                                   label: `${row.targetProductName}, ${row.targetProductMainSKU || "–"}`,
                                                                   value: row.id
                                                               }))} isDisabled={(props.status && props.status !== ProductionOrderStatus.WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART) || props.readonly}/>
                </td>
                {(!props.status || props.status === ProductionOrderStatus.WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART) && !props.readonly && <div className="ml-3"><DeleteButtonText onClick={() => remove(index)}/></div>}
            </tr>)}
            </tbody>
        </table>
        {(!props.status || props.status === ProductionOrderStatus.WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART) && !props.readonly && <div className="mt-3"><AddButtonText
            onClick={() => append({number: "–", name: "–", mainSKU: "–", quantity: "–", technologicalProcessChart: null})}/></div>}
    </>)
}