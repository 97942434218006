import {useDispatch} from "react-redux";
import {useMemo} from "react";
import {bindActionCreators} from "@reduxjs/toolkit";
import {dataActions} from "../store/slices/data.slice";

export function useDataActions() {
    const dispatch = useDispatch()

    return useMemo(() =>
        bindActionCreators(dataActions, dispatch), [dispatch])
}