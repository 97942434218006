import React, {useEffect} from "react";
import {AdministrativeRolesTable} from "../../components/administrative-roles/AdministrativeRolesTable";
import {useSearchAdministrativeRolesGuidePageQuery} from "../../store/backend-api/backend.api";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useAppSelector} from "../../hooks/useAppSelector";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER} from "../../model/filters/DefaultFilters";
import {useDataActions} from "../../hooks/useDataActions";

export function AdministrativeRolesGuide() {
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER].filter

    const data = useAppSelector(state => state.data.data[TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER])

    const {data: tablePage} = useSearchAdministrativeRolesGuidePageQuery(filter);

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    return (
        <div className="space-y-3.5 h-[98%]">
            <div>
                <div className="min-w-full flex flex-row">
                    <GuideTopBox tableFilterKey={TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER}
                                 createNavigationURL={'/administrative-roles/new'}
                                 searchInfo={"Поиск по ID и наименованию роли."}/>
                </div>
            </div>
            <div className="h-[90%] bg-transparent rounded-2xl">
                <div className="bg-transparent rounded-2xl h-[100%]">
                    {data && <AdministrativeRolesTable data={data}/>}
                </div>
            </div>
        </div>
    )
}