import React, {useEffect} from "react";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import {TablePage} from "../../model/utils/TablePage";
import {MeasurementUnit} from "../../model/measurement/MeasurementUnit";
import {ControlledPaginateAsyncSelect} from "../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {
    useCreateNomenclatureMutation,
    useLazySearchMeasurementUnitGuidePageQuery
} from "../../store/backend-api/backend.api";
import {OptionType} from "../../model/option-type/OptionType";
import {NomenclatureForm, ProductionNomenclatureClassifier} from "../../model/nomenclature/NomenclatureForm";
import {Button} from "../ui/buttons/Button";
import {productionNomenclatureClassifierToString} from "../../data/NomenclatureData";
import {ControlledMultiSelect} from "../ui/form-components/controlled/ControlledMultiSelect";

interface FormProps {
    setCreatedNomenclatureOption: (option: OptionType<number>) => void,
    setModalIsOpen: (state: boolean) => void
}

interface FormFields {
    name: string,
    drawingNumber: string,
    measurementUnitId: OptionType<number> | null,
    productionNomenclatureClassifier: (ProductionNomenclatureClassifier | null)[]
}

export function MaterialOrProductCreateForm(props: FormProps) {
    const [doSearchMeasurementGuidePage] = useLazySearchMeasurementUnitGuidePageQuery()
    const [doCreateNomenclature, {
        data: createdNomenclatureId,
        isSuccess: isSuccessCreate
    }] = useCreateNomenclatureMutation()
    const {control, handleSubmit} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: {name: "", drawingNumber: "", measurementUnitId: null, productionNomenclatureClassifier: [null, null]}
    })
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        const newMaterial: NomenclatureForm = {
            id: null,
            name: data.name,
            measurementUnitId: data.measurementUnitId!.value,
            sku: null,
            drawingNumber: data.drawingNumber || null,
            additionalSku: [],
            comment: "",
            rawMaterial: data.productionNomenclatureClassifier[0] ? {} : null,
            readyProduct: null,
            uncompletedManufacturing: data.productionNomenclatureClassifier[1] ? {} : null
        }
        doCreateNomenclature(newMaterial)
    }
    const currentFormValue = useWatch({control})

    useEffect(() => {
        if (isSuccessCreate) {
            props.setCreatedNomenclatureOption({label: `${currentFormValue.name}`, value: createdNomenclatureId!})
            props.setModalIsOpen(false)
        }
    }, [isSuccessCreate]);

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)} className="p-3 pt-[18px]">
            <div className="grid grid-cols-2 gap-x-2 gap-y-3">
                <ControlledInputField control={control}
                                      label={"Наименование"} name={"name"} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    },
                    minLength: {
                        value: 1,
                        message: "Минимальная длина: 1"
                    }
                }} additionalStyles={"w-[423px]"}/>
                <ControlledInputField control={control}
                                      label={"Номер чертежа"} name={"drawingNumber"} rules={{}}
                                      additionalStyles={"w-[423px]"}/>
                <ControlledPaginateAsyncSelect control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} label={"Единица измерения"} name={"measurementUnitId"}
                                               request={doSearchMeasurementGuidePage}
                                               mapFunc={(data: TablePage<MeasurementUnit>) =>
                                                   data.rows.map(row =>
                                                       ({label: `${row.name}`, value: row.id})
                                                   )}/>
                <ControlledMultiSelect control={control} rules={{
                    validate: (value: ProductionNomenclatureClassifier[]) => value.some(it => it != null) || 'Выберите хотя бы один вариант'
                }} name={"productionNomenclatureClassifier"}
                                       label={"Производственный классификатор"}
                                       additionalStyles={"w-[423px] row-span-2"}
                                       options={[ProductionNomenclatureClassifier.RAW_MATERIAL, ProductionNomenclatureClassifier.UNCOMPLETED_MANUFACTURING]}
                                       optionsToStringMap={productionNomenclatureClassifierToString}/>
            </div>
            <div className="border-b-[1px] border-slate-400/[.80] border-dotted h-1 w-full my-6"></div>
            <div className="flex flex-row justify-end">
                <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onClickSaveButton)}/>
            </div>
        </form>
    )
}