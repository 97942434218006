import React from 'react';
import {Toast} from "./Toast";
import {useAppSelector} from "../../hooks/useAppSelector";

export function ToastList() {
    const toasts = useAppSelector(state => state.toast.toasts)

    return (<>
        {toasts.length > 0 && <div className="max-h-screen h-fit w-fit flex flex-col gap-y-3 fixed bottom-0 right-0 pr-4 pb-4">
        {toasts.map(toast =>
                <Toast key={toast.id} toast={toast}/>)}
        </div>}
    </>)
}
