import {GuideFilter} from "../filters/GuideFilter";
import {ProductionNomenclatureClassifier} from "./NomenclatureForm";

export interface NomenclatureGuideFilter extends GuideFilter {
    productionNomenclatureClassifier: ProductionNomenclatureClassifier[],
    sortedField: NomenclatureSortedField | null,
}

export enum NomenclatureSortedField {
    ID = "ID",
    NAME = "NAME",
    MEASUREMENT_UNIT = "MEASUREMENT_UNIT",
    HAS_SERIAL_NUMBER = "HAS_SERIAL_NUMBER",
    MANUFACTURER = "MANUFACTURER",
    WORK_CLASSIFIER = "WORK_CLASSIFIER"
}

export enum WorkClassifier {
    WEIGHING_ELECTRONICS = "WEIGHING_ELECTRONICS",
    COMPUTER_ELECTRONICS = "COMPUTER_ELECTRONICS",
    HARDWARE = "HARDWARE",
    METAL = "METAL",
    EQUIPMENT = "EQUIPMENT",
    PAINT = "PAINT",
    MATERIAL_IN_PIECES = "MATERIAL_IN_PIECES"
}