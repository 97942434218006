import React from "react";
import {ProductionStageFormTemplate} from "./ProductionStageFormTemplate";
import {ProductionStage} from "../../model/production-stage/ProductionStage";

interface FormProps {
    data: ProductionStage
}

export function ProductionStageFormRead(props: FormProps) {


    return (
        <ProductionStageFormTemplate data={props.data} readonly={true}/>
    )
}