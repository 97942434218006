import {useDispatch} from "react-redux";
import {useMemo} from "react";
import {bindActionCreators} from "@reduxjs/toolkit";
import {navBarActions} from "../store/slices/navBar.slice";

export function useNavBarActions() {
    const dispatch = useDispatch()

    return useMemo(() =>
        bindActionCreators(navBarActions, dispatch), [dispatch])
}