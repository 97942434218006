import {SystemRole} from "../../../model/administrative-roles/AdministrativeRole";
import {Outlet, useNavigate} from "react-router-dom";
import {useHasAuthority, useIsAuthorized} from "../../../hooks/TokensHooks";
import {useEffect} from "react";
import {ForbiddenStatusShower} from "./ForbiddenStatusShower";

export interface ProtectedRouteProps {
    redirectPath?: string
    authority?: string
    allAvailable?: boolean
}

export function ProtectedRoute(props: ProtectedRouteProps) {
    const redirectPath = props.redirectPath ? props.redirectPath : "/login"
    const authority = props.authority ? props.authority : SystemRole.ROLE_ADMIN
    const allAvailable = props.allAvailable ? props.allAvailable : false

    const isAuthorized = useIsAuthorized()
    const hasAuthority = useHasAuthority(authority)
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuthorized) {
            navigate(redirectPath)
        }
    }, [isAuthorized]);

    return (hasAuthority || allAvailable) ? <Outlet/> : <ForbiddenStatusShower/>
}