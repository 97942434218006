import React from "react";
import {PiX} from "react-icons/pi";
import {useNavigate} from "react-router-dom";

interface ButtonProps {
    onClick?: () => void
}

export function BackButton(props: ButtonProps) {
    const navigate = useNavigate()
    function onClick() {
        navigate(-1)
    }

    return (<button type={"button"}
                    onClick={props.onClick || onClick}
                  title="Закрыть"
                  className="-mr-1 text-black/[.30] rounded-[5px]">
            <PiX className="h-7 w-7"/>
        </button>
    )
}