import {FiX} from "react-icons/fi";
import {TargetProductForm} from "./TargetProductForm";
import {SeparatingLine} from "../ui/common/SeparatingLine";
import {Button} from "../ui/buttons/Button";
import ReactModal from "react-modal";
import React, {useEffect, useState} from "react";
import {
    CreateOrCopyBillOfMaterialsRequest,
    Product
} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {OptionType} from "../../model/option-type/OptionType";
import {useCopyTechnologicalProcessChartMutation} from "../../store/backend-api/backend.api";
import {useNavigate} from "react-router-dom";
import {ModalForCreateNomenclatureFromSelect} from "./ModalForCreateNomenclatureFromSelect";

interface ModalProps {
    modalForCopyIsOpen: boolean,
    setModalForCopyIsOpen: (value: boolean) => void,
    targetProduct: Product
}

interface FormFields {
    targetProductName: OptionType<number> | null,
    productQuantity: string
}

export function ModalForCopyTechnologicalProcessChart(props: ModalProps) {
    const [doCopyTechnologicalProcessChart, {
        data: copiedTechnologicalProcessChartId,
        isSuccess
    }] = useCopyTechnologicalProcessChartMutation()
    const [modalForCreateNomenclatureIsOpen, setModalForCreateNomenclatureIsOpen] = useState<boolean>(false)
    const [createdNomenclatureOption, setCreatedNomenclatureOption] = useState<OptionType<number> | null>(null)
    const {control, handleSubmit, setValue, resetField} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: {
            targetProductName: {
                label: `${props.targetProduct.nomenclature.fullName}, ${props.targetProduct.nomenclature.measurementUnit.name}`,
                value: props.targetProduct.nomenclature.id
            },
            productQuantity: props.targetProduct.quantity
        }
    })
    const onClickCopyButton: SubmitHandler<FormFields> = data => {
        const newTargetProduct: CreateOrCopyBillOfMaterialsRequest = {
            nomenclatureId: data.targetProductName!.value,
            quantity: data.productQuantity!
        }
        doCopyTechnologicalProcessChart({
            billOfMaterialsId: props.targetProduct!.billOfMaterialsId,
            body: newTargetProduct
        })
    }
    const navigate = useNavigate()
    const currentFormValue = useWatch({control})

    useEffect(() => {
        if (isSuccess) {
            navigate(`/bill-of-materials?id=${copiedTechnologicalProcessChartId}`)
        }
    }, [isSuccess]);

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    useEffect(() => {
        if (currentFormValue.targetProductName?.value === -1) {
            setModalForCreateNomenclatureIsOpen(true)
        }
    }, [currentFormValue]);

    useEffect(() => {
        if (!modalForCreateNomenclatureIsOpen && createdNomenclatureOption !== null) {
            setValue("targetProductName", createdNomenclatureOption)
            setCreatedNomenclatureOption(null)
        }
        if (!modalForCreateNomenclatureIsOpen && createdNomenclatureOption === null) {
            resetField("targetProductName")
        }
    }, [modalForCreateNomenclatureIsOpen]);

    return (
        <ReactModal isOpen={props.modalForCopyIsOpen}
                    onRequestClose={() => props.setModalForCopyIsOpen(false)}>
            <div className="flex flex-row justify-end">
                <button type={"button"}
                        title="Закрыть"
                        onClick={() => props.setModalForCopyIsOpen(false)}
                        className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                    <FiX className="h-8 w-8"/>
                </button>
            </div>
            <form onSubmit={handleSubmit(onClickCopyButton)}>
                <TargetProductForm control={control} targetProduct={props.targetProduct} editFromModalForCopy={true}/>
                <SeparatingLine/>
                <div className="flex flex-row justify-center mt-5">
                    <Button buttonName={"Дублировать"} onClickButton={handleSubmit(onClickCopyButton)}/>
                </div>
            </form>
            <ModalForCreateNomenclatureFromSelect modalForNomenclatureCreateIsOpen={modalForCreateNomenclatureIsOpen}
                                                  setModalForNomenclatureCreateIsOpen={setModalForCreateNomenclatureIsOpen}
                                                  setCreatedNomenclatureOption={setCreatedNomenclatureOption}
                                                  nomenclatureId={props.targetProduct.nomenclature.id}
                                                  defaultMeasurementUnit={{
                                                      label: props.targetProduct.nomenclature.measurementUnit.name,
                                                      value: props.targetProduct.nomenclature.measurementUnit.id
                                                  }}/>
        </ReactModal>
    )
}