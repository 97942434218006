import {useEffect, useRef} from "react";
import {Observer} from "../../../utils/Observer";
import {Pagination} from "../../tables/Table";

export function ObservableElement(props: Pagination) {
    const elementRef = useRef(null)
    const currentPageNumberRef = useRef<number>(props.currentPageNumber);

    const callback = () => {
        const nextPageNumber = currentPageNumberRef.current + 1
        if (nextPageNumber < props.quantityOfPages) {
            currentPageNumberRef.current = nextPageNumber
            props.setPageNumber(nextPageNumber)
        }
    };

    useEffect(() => {
        if (elementRef.current !== null) {
            Observer(elementRef.current, callback)
        }
    }, []);
    return (
        <div ref={elementRef}></div>
    )
}