import {combineReducers,} from 'redux';
import {persistReducer, persistStore} from 'redux-persist'
import {configureStore} from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session';
import {backendApi} from "./backend-api/backend.api";
import {filterNodesReducer} from "./slices/filterNodes.slice";
import {tokensReducer} from "./slices/tokens.slice";
import {toastReducer} from "./slices/toast.slice";
import {RTKQueryErrorMiddleware} from "./middlewares/RTKQueryErrorMiddleware";
import {filtersReducer} from "./slices/filters.slice";
import {loadingReducer} from "./slices/loading.slice";
import {LoadingMiddleware} from "./middlewares/LoadingMiddleware";
import {dataReducer} from "./slices/data.slice";
import {ClearCacheMiddleware} from "./middlewares/ClearCacheMiddleware";
import {selectedMeasurementOkeiReduser} from "./slices/selectedMeasurementOkei.slice";
import {userPhotoReducer} from "./slices/userPhoto.slice";
import {navBarReducer} from "./slices/navBar.slice";

const persistConfigLocal = {
    key: 'localData',
    storage
}

const persistConfigSession = {
    key: 'sessionData',
    storage: storageSession
}

const persistedReducerSession = persistReducer(persistConfigSession, filtersReducer)
const persistedReducerLocal = persistReducer(persistConfigLocal, tokensReducer)

const rootReducer = combineReducers({
    [backendApi.reducerPath]: backendApi.reducer,
    filtersForMenuFilters: filterNodesReducer,
    localStorage: persistedReducerLocal,
    sessionStorage: persistedReducerSession,
    toast: toastReducer,
    loading: loadingReducer,
    data: dataReducer,
    selectedMeasurementOkei: selectedMeasurementOkeiReduser,
    userPhoto: userPhotoReducer,
    navBar: navBarReducer
});

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }).concat(backendApi.middleware, LoadingMiddleware, ClearCacheMiddleware).concat(RTKQueryErrorMiddleware)
})

const persistor = persistStore(store);

export {store, persistor};

export type RootState = ReturnType<typeof store.getState>