import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {ControlledInputField} from "../../components/ui/form-components/controlled/ControlledInputField";
import {Button} from "../../components/ui/buttons/Button";
import {backendApi, useGetTokensMutation} from "../../store/backend-api/backend.api";
import {UsernamePassword} from "../../model/security/Tokens";
import {useTokensActions} from "../../hooks/TokensHooks";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SeparatingLine} from "../../components/ui/common/SeparatingLine";
import {PiEyeLight, PiEyeSlashLight} from "react-icons/pi";

interface FormFields {
    username: string,
    password: string
}

export function LoginPage() {
    const {handleSubmit, control} = useForm<FormFields>()
    const dispatch = useDispatch()
    const [requestTokens, {
        isError: isRequestTokensError,
        data: tokens,
        isSuccess: isRequestTokensSuccess
    }] = useGetTokensMutation()
    const {setTokens} = useTokensActions()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const onClickLoginButton: SubmitHandler<FormFields> = data => {
        const credentials: UsernamePassword = {
            username: data.username,
            password: data.password
        }
        requestTokens(credentials)
    }

    useEffect(() => {
        if (isRequestTokensSuccess && tokens) {
            setTokens(tokens)
        }
    }, [isRequestTokensSuccess]);

    useEffect(() => {
        if (tokens) {
            dispatch(backendApi.util.resetApiState)
            navigate('/profile')
        }
    }, [tokens]);

    return (<div className="w-full flex flex-row justify-center">
        <form onSubmit={handleSubmit(onClickLoginButton)}
              className="bg-white min-w-[471px] h-min max-w-[471px] rounded-md shadow-lg p-6 pt-[18px]">
            <div className="grid grid-cols-1 gap-x-20 gap-y-4">
                <ControlledInputField control={control} label={"Имя пользователя (логин)"} name={"username"} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    },
                }} additionalStyles={"w-[423px]"}/>
                <label
                    className="text-base font-normal"
                >
                    <div className="flex flex-row gap-x-2">
                        <span>Пароль</span>
                        {showPassword &&
                            <PiEyeLight className="mt-[5px]" onClick={() => setShowPassword(false)}/>}
                        {!showPassword &&
                            <PiEyeSlashLight className="mt-[5px]" onClick={() => setShowPassword(true)}/>}
                    </div>
                    <ControlledInputField control={control} name={"password"} type={showPassword ? "text" : "password"} rules={{
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        },
                        minLength: {
                            value: 8,
                            message: "Длина пароля не менее 8 символов"
                        }
                    }} additionalStyles={"w-[423px]"}/>
                </label>
            </div>
            {isRequestTokensError &&
                <p className="text-base font-normal text-red-400 w-full text-center mt-4 -mb-5">Неверный логин или
                    пароль</p>}
            <SeparatingLine forSeparatingButtons={true}/>
            <div className="flex flex-row justify-center">
                <Button buttonName={"Войти"} onClickButton={handleSubmit(onClickLoginButton)}/>
            </div>
        </form>
    </div>)
}