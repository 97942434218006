export interface User {
    id: number,
    username: string,
    phoneNumber: string,
    email: string,
    activityStatus: ActivityStatus,
    administrativeRole: AdministrativeRoleForUser | null,
    isAdmin: boolean
    comment: string,
    subjectId: number,
}
export interface CreatingOrUpdatingUser {
    username: string,
    password: string | null,
    email: string | null,
    phoneNumber: string | null,
    activityStatus: ActivityStatus,
    comment: string,
    administrativeRoleId: number | null,
    subjectId: number,
}
export enum ActivityStatus {
    ACTIVE = "ACTIVE",
    BLOCKED = "BLOCKED"
}
export interface AdministrativeRoleForUser {
    id: number,
    name:  string,
}