import React, {useEffect} from "react";
import {
    ProductionOrderExecutionJournalTable
} from "../../components/production-order-execution/ProductionOrderExecutionJournalTable";
import {
    useLazyGetAdministrativeAndFunctionalZoneQuery,
    useLazySearchAdministrativeAndFunctionalZoneGuidePageQuery,
    useSearchProductionOrderExecutionJournalPageQuery
} from "../../store/backend-api/backend.api";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {NewPaginateAsyncSelect} from "../../components/ui/form-components/standalone/NewPaginateAsyncSelect";
import {OptionType} from "../../model/option-type/OptionType";
import {UpdateFilter} from "../../store/slices/filters.slice";
import {TablePage} from "../../model/utils/TablePage";
import {
    AdministrativeAndFunctionalZoneGuideRow
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideRow";
import {
    DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
    DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER
} from "../../model/filters/DefaultFilters";
import {
    AdministrativeAndFunctionalZonePurpose
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {useFilterNodesActions} from "../../hooks/useMenuFiltersActions";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useAppSelector} from "../../hooks/useAppSelector";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useDataActions} from "../../hooks/useDataActions";

export function ProductionOrderExecutionDocumentJournal() {
    const {addFiltersToMenu} = useFilterNodesActions()
    const {updateFilterAndClearData} = useFiltersActions()
    const {addData} = useDataActions()

    const [doGetAdministrativeAndFunctionalZone] = useLazyGetAdministrativeAndFunctionalZoneQuery()
    const [doSearchAdministrativeAndFunctionalZoneGuidePage] = useLazySearchAdministrativeAndFunctionalZoneGuidePageQuery()

    const filter = useAppSelector(state => state.sessionStorage.filters[TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER].filter)

    const data = useAppSelector(state => state.data.data[TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER])

    const {data: tablePage} = useSearchProductionOrderExecutionJournalPageQuery(filter)

    async function getAdministrativeAndFunctionalZone(productionLineId: number | null) {
        if (productionLineId) {
            const productionLine = (await doGetAdministrativeAndFunctionalZone(productionLineId).unwrap())
            return {label: productionLine.name!, value: productionLine.id!} as OptionType<number>
        }
        return undefined
    }

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    useEffect(() => {
        getAdministrativeAndFunctionalZone(filter.productionLineId)
            .then(defaultOption => {
                addFiltersToMenu({
                    key: TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER,
                    value: [{
                        filterName: "Производственная линия",
                        filter: <NewPaginateAsyncSelect onChange={(option: OptionType<number> | null) => {
                            const newFilter: UpdateFilter = {
                                key: TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER,
                                value: {...filter, productionLineId: option ? option.value : null}
                            }
                            updateFilterAndClearData(newFilter)
                        }} defaultOption={defaultOption} width={"270px"}
                                                        request={doSearchAdministrativeAndFunctionalZoneGuidePage}
                                                        mapFunc={(data: TablePage<AdministrativeAndFunctionalZoneGuideRow>) =>
                                                            data.rows.map(row => ({label: row.name, value: row.id}))
                                                        }
                                                        filter={{
                                                            ...DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
                                                            purpose: AdministrativeAndFunctionalZonePurpose.PRODUCTION_LINE
                                                        }}/>
                    }]
                })
            })
    }, [filter.productionLineId]);

    return (<>
        <div className="space-y-3.5 h-[98%]">
            <div>
                <div className="min-w-full flex flex-row">
                    <GuideTopBox tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER}
                                 searchInfo={"Поиск по П/№, № Work и основной продукции."}/>
                </div>
            </div>
            <div className="h-[90%] bg-transparent rounded-2xl">
                <div className="bg-transparent rounded-2xl h-[100%]">
                    {data &&
                        <ProductionOrderExecutionJournalTable data={data}/>}
                </div>
            </div>
        </div>
    </>)
}