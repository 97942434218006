import {UpdateOrCreateMeasurementUnit} from "../../model/measurement/MeasurementUnit";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MeasurementOkei} from "../../model/measurement/MeasurementOkei";

interface InitialStateInterface {
    measurementOkei: MeasurementOkei | null
}

const initialState: InitialStateInterface = {
    measurementOkei: null
}

const selectedMeasurementOkeiSlice = createSlice({
    name: "selectedMeasurementOkei",
    initialState,
    reducers: {
        setSelectedMeasurementOkei: (state, action: PayloadAction<MeasurementOkei>) => {
            state.measurementOkei = action.payload
        },
        resetSelectedMeasurementOkei: (state) => {
            state.measurementOkei = null
        }
    }
})

export const selectedMeasurementOkeiActions = selectedMeasurementOkeiSlice.actions
export const selectedMeasurementOkeiReduser = selectedMeasurementOkeiSlice.reducer