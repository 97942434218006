import {Location, useLocation, useSearchParams} from "react-router-dom";
import {Product} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {
    useLazyGetPositionsForProductionOrderExecutionQuery,
    useLazySearchProductsOfBillOfMaterialsQuery
} from "../../store/backend-api/backend.api";
import {useEffect, useState} from "react";
import {PositionsForProductionOrderExecution} from "../../model/production-order-execution/ProductionOrderExecution";


function getName(location: Location) {
    if (location.pathname === '/nomenclature/guide') {
        return 'Справочник "Номенклатура"'
    }
    if (location.pathname === '/nomenclature/new') {
        return 'Создание записи справочника "Номенклатура"'
    }
    if (location.pathname === '/nomenclature/edit') {
        return 'Редактирование записи справочника "Номенклатура"'
    }
    if (location.pathname === '/nomenclature') {
        return 'Запись справочника "Номенклатура"'
    }

    if (location.pathname === '/measurement-unit/guide') {
        return 'Справочник "Единицы измерения"'
    }
    if (location.pathname === '/measurement-unit/new') {
        return 'Создание записи справочника "Единицы измерения"'
    }
    if (location.pathname === '/measurement-unit/edit') {
        return 'Редактирование записи справочника "Единицы измерения"'
    }
    if (location.pathname === '/measurement-unit') {
        return 'Запись справочника "Единицы измерения"'
    }

    if (location.pathname === '/measurement-okei/guide') {
        return 'Справочник "Единицы измерения ОКЕИ"'
    }

    if (location.pathname === '/administrative-roles/guide') {
        return 'Справочник "Административные роли"'
    }
    if (location.pathname === '/administrative-roles/new') {
        return 'Создание записи справочника "Административные роли"'
    }
    if (location.pathname === '/administrative-roles/edit') {
        return 'Редактирование записи справочника "Административные роли"'
    }
    if (location.pathname === '/administrative-roles') {
        return 'Запись справочника "Административные роли"'
    }

    if (location.pathname === '/user/guide') {
        return 'Справочник "Пользователи системы"'
    }
    if (location.pathname === '/user/new') {
        return 'Создание записи справочника "Пользователи системы"'
    }
    if (location.pathname === '/user/edit') {
        return 'Редактирование записи справочника "Пользователи системы"'
    }
    if (location.pathname === '/user') {
        return 'Запись справочника "Пользователи системы"'
    }

    if (location.pathname === '/individuals/guide') {
        return 'Справочник "Физические лица"'
    }
    if (location.pathname === '/individuals/new') {
        return 'Создание записи справочника "Физические лица"'
    }
    if (location.pathname === '/individuals/edit') {
        return 'Редактирование записи справочника "Физические лица"'
    }
    if (location.pathname === '/individuals') {
        return 'Запись справочника "Физические лица"'
    }

    if (location.pathname === '/technological-process-chart/guide') {
        return 'Справочник "Шаблоны и технологические карты"'
    }
    if (location.pathname === '/bill-of-materials/new') {
        return 'Создание технологической карты для готовой продукции'
    }

    if (location.pathname === '/production-stages/guide') {
        return 'Справочник "Этапы производства"'
    }
    if (location.pathname === '/production-stages/new') {
        return 'Создание записи справочника "Этапы производства"'
    }
    if (location.pathname === '/production-stages/edit') {
        return 'Редактирование записи справочника "Этапы производства"'
    }
    if (location.pathname === '/production-stages') {
        return 'Запись справочника "Этапы производства"'
    }

    if (location.pathname === '/technological-operation/guide') {
        return 'Справочник "Технологические операции"'
    }
    if (location.pathname === '/technological-operation/new') {
        return 'Создание записи справочника "Технологические операции"'
    }
    if (location.pathname === '/technological-operation/edit') {
        return 'Редактирование записи справочника "Технологические операции"'
    }
    if (location.pathname === '/technological-operation') {
        return 'Запись справочника "Технологические операции"'
    }

    if (location.pathname === '/production-order/new') {
        return 'Создание документа "Производственный заказ"'
    }
    if (location.pathname === '/production-order/edit') {
        return 'Редактирование документа "Производственный заказ"'
    }
    if (location.pathname === '/production-order') {
        return 'Документ "Производственный заказ"'
    }
    if (location.pathname === '/production-order/document-journal') {
        return 'Журнал документов "Производственные заказы"'
    }

    if (location.pathname === '/production-order/execution/document-journal') {
        return 'Журнал документов "Исполнение производственных заказов"'
    }

    if (location.pathname === '/production-order/archive/report') {
        return 'Архив производственных заказов'
    }

    if (location.pathname === '/administrative-and-functional-zone/guide') {
        return 'Справочник "Административно-функциональные зоны"'
    }
    if (location.pathname === '/administrative-and-functional-zone/new') {
        return 'Создание записи справочника "Административно-функциональные зоны"'
    }
    if (location.pathname === '/administrative-and-functional-zone/edit') {
        return 'Редактирование записи справочника "Административно-функциональные зоны"'
    }
    if (location.pathname === '/administrative-and-functional-zone') {
        return 'Запись справочника "Административно-функциональные зоны"'
    }

    if (location.pathname === '/login') {
        return 'Вход в систему'
    }
    if (location.pathname === '/profile') {
        return 'Профиль'
    }
    if (location.pathname === '/profile/edit') {
        return 'Редактирование профиля'
    }
}

export function useGetHeader() {
    const location = useLocation();
    const [searchParams] = useSearchParams()
    const id = Number(searchParams.get("id"))
    const [doSearchProductsOfBillOfMaterials] = useLazySearchProductsOfBillOfMaterialsQuery()
    const [doGetPositionsForProductionOrderExecution] = useLazyGetPositionsForProductionOrderExecutionQuery()
    const [header, setHeader] = useState<string | undefined>(undefined)

    useEffect(() => {
        async function getHeader() {
            if (location.pathname === '/bill-of-materials') {
                const resultString = await getHeaderForEditTechnologicalProcessChartPage(doSearchProductsOfBillOfMaterials, id)
                setHeader(resultString)
            } else if (location.pathname ==='/production-order/execution') {
                const resultString = await getHeaderForProductionOrderExecutionPage(doGetPositionsForProductionOrderExecution, id)
                setHeader(resultString)
            } else {
                setHeader(getName(location))
            }
        }
        getHeader().then(r => r)
    }, [location.pathname])

    return header
}

export async function getHeaderForEditTechnologicalProcessChartPage(doSearchProductsOfBillOfMaterials: Function, id: number) {
    const products = await doSearchProductsOfBillOfMaterials(id).unwrap();
    const nomenclature = products.find((product: Product) => product.isTarget).nomenclature
    return `Редактирование технологической карты: "${nomenclature.fullName}"`
}
export async function getHeaderForProductionOrderExecutionPage(doGetPositionsForProductionOrderExecution: Function, id: number) {
    const positions: PositionsForProductionOrderExecution = await doGetPositionsForProductionOrderExecution(id).unwrap();
    const workOrderNumber = positions.workOrderInfo.number;
    return `Документ "Исполнение производственного заказа №${workOrderNumber}"`
}