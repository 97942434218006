import React, {useEffect, useState} from "react";
import {defBaseUrl, useSearchProductionTaskAttachmentsQuery} from "../../../store/backend-api/backend.api";
import ReactModal from "react-modal";
import {PiArrowLineDownLight, PiX} from "react-icons/pi";
import {DownloadFilesButton} from "../../storage/DownloadFilesButton";
import {ProductionTaskAttachment} from "../../../model/production-order-execution/ProductionOrderExecution";
import {NomenclatureAttachment} from "../../../model/nomenclature/NomenclatureForm";
import {isFileOpenable, ModalForOpenFile} from "../../nomenclature/ModalForOpenFile/ModalForOpenFile";

interface ModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
    productionTaskDocumentNumber: number
}

export function ModalForOpenProductionTaskAttachments(props: ModalProps) {
    const {
        data: attachments,
        isSuccess: isSuccessSearchAttachments
    } = useSearchProductionTaskAttachmentsQuery(props.productionTaskDocumentNumber)
    const [modalForOpenFileIsOpen, setModalForOpenFileIsOpen] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<ProductionTaskAttachment | null>(null)
    const [key, setKey] = useState(0);

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [props.productionTaskDocumentNumber]);

    return (
        <ReactModal key={key} isOpen={props.isOpen}
                    onRequestClose={() => props.setIsOpen(false)}>
            <div className="flex flex-row justify-between">
                <p className="font-light text-lg -mt-1">Загруженные файлы</p>
                <button type={"button"}
                        title="Закрыть"
                        onClick={() => props.setIsOpen(false)}
                        className="-mr-1 -mt-2 text-black/[.30] rounded-[5px]">
                    <PiX className="h-7 w-7"/>
                </button>
            </div>
            <table className="font-light text-base border border-black mt-5">
                <thead>
                <tr className="text-left">
                    <th className="text-base font-normal border border-black px-2">Наименование файла</th>
                    <th className="text-base font-normal border border-black px-2">Загружен</th>
                    <th className="border border-black"></th>
                </tr>
                </thead>
                <tbody>
                {isSuccessSearchAttachments && attachments.map(file =>
                    <tr>
                        {isFileOpenable(file.fileName) &&
                            <td className="border border-black px-2 hover:bg-blue-50/[.70] cursor-pointer"
                                onClick={() => {
                                    setSelectedFile(file)
                                    setModalForOpenFileIsOpen(true)
                                }}>{file.fileName}</td>}
                        {!isFileOpenable(file.fileName) &&
                            <td className="border border-black px-2">{file.fileName}</td>}
                        <td className="border border-black px-2">{file.createdTime}</td>
                        <td className="border border-black px-0.5">
                            <a href={`${defBaseUrl()}/api/v2/file?id=${file.fileId}`} download={true}
                               title={"Скачать файл"}><PiArrowLineDownLight
                                className="mt-[2px] h-[18px] w-[18px] hover:text-blue-800"/></a></td>
                    </tr>)}
                </tbody>
            </table>
            <div className="border-b-[1px] border-slate-400/[.80] border-dotted h-1 w-full my-6"></div>
            <div className="flex flex-row justify-end">
                {isSuccessSearchAttachments &&
                    <DownloadFilesButton filesIds={attachments.map(it => it.fileId)} name={"Скачать все файлы"}/>}
            </div>
            {selectedFile && <ModalForOpenFile modalForOpenFileIsOpen={modalForOpenFileIsOpen}
                                               setModalForOpenFileIsOpen={setModalForOpenFileIsOpen}
                                               selectedFile={({
                                                   fileId: selectedFile.fileId,
                                                   fileName: selectedFile.fileName,
                                                   creationTime: selectedFile.createdTime
                                               } as NomenclatureAttachment)}
                                               setSelectedFile={() => setSelectedFile(null)}/>}
        </ReactModal>
    )
}

