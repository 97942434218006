import {ControlledPaginateAsyncSelect} from "../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {TablePage} from "../../model/utils/TablePage";
import {NomenclatureGuideRow} from "../../model/nomenclature/NomenclatureGuideRow";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import React from "react";
import {useLazySearchNomenclatureGuidePageQuery} from "../../store/backend-api/backend.api";
import {Product} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {OptionType} from "../../model/option-type/OptionType";
import {Control} from "react-hook-form";

interface FormProps {
    control: Control<FormFields>,
    targetProduct?: Product,
    setModalForNomenclatureEditIsOpen?: (value: boolean) => void,
    editFromModalForCopy?: boolean
}

interface FormFields {
    targetProductName: OptionType<number> | null,
    productQuantity: string
}

export function TargetProductForm(props: FormProps) {
    const [doSearchNomenclatureGuidePage] = useLazySearchNomenclatureGuidePageQuery()
    const divStyle: string = props.editFromModalForCopy ? "" : "xl:grid-cols-2"
    return (
        <div className={`grid grid-cols-1 gap-y-4 ${divStyle}`}>
            <div>
                <ControlledPaginateAsyncSelect<number> control={props.control} rules={{
                required: {
                    value: true,
                    message: "Это обязательное поле"
                }
            }} name={"targetProductName"} label={"Целевое изделие"} request={doSearchNomenclatureGuidePage}
                                                   mapFunc={(data: TablePage<NomenclatureGuideRow>) =>
                                                       data.rows.map(row =>
                                                           ({
                                                               label: `${row.fullName}, ${row.measurementUnit}`,
                                                               value: row.id
                                                           })
                                                       )} additionalOptions={[{
                label: "Создать новую номенклатуру",
                value: -1
            }]}/>

            </div>
            <ControlledInputField control={props.control} rules={{
                required: {
                    value: true,
                    message: "Это обязательное поле"
                },
                pattern: {
                    value: /^[0-9]*[.]?[1-9][0-9]{0,2}$/g,
                    message: 'Введите положительное число с 3 или менее знаками после "."',
                },
                validate: {
                    positive: (value: string) => value !== '0' || 'Количество больше нуля'
                }
            }} name={"productQuantity"} label={"Количество"} additionalStyles={"w-[423px]"}
                                  placeholder={props.targetProduct !== undefined ? `Не заполнено, ${props.targetProduct.nomenclature.measurementUnit.name}` : undefined}/>

        </div>
        )
}