import {FiX} from "react-icons/fi";
import {NomenclatureFormCreate} from "../nomenclature/form/NomenclatureFormCreate";
import ReactModal from "react-modal";
import React, {useEffect} from "react";
import {OptionType} from "../../model/option-type/OptionType";
import {useLazyGetNomenclatureQuery} from "../../store/backend-api/backend.api";
import {NomenclatureForm} from "../../model/nomenclature/NomenclatureForm";

interface ModalProps {
    modalForNomenclatureCreateIsOpen: boolean,
    setModalForNomenclatureCreateIsOpen: (value: boolean) => void,
    setCreatedNomenclatureOption: (state: OptionType<number>) => void,
    nomenclatureId?: number,
    defaultMeasurementUnit?: OptionType<number>
}

export function ModalForCreateNomenclatureFromSelect(props: ModalProps) {
    const [doGetNomenclature, {data: nomenclature}] = useLazyGetNomenclatureQuery()

    useEffect(() => {
        if (props.modalForNomenclatureCreateIsOpen && props.nomenclatureId) {
            doGetNomenclature(props.nomenclatureId)
        }
    }, [props.modalForNomenclatureCreateIsOpen]);

    return (
        <ReactModal isOpen={props.modalForNomenclatureCreateIsOpen}
                    onRequestClose={() => props.setModalForNomenclatureCreateIsOpen(false)}>
            <div className="flex flex-row justify-end">
                <button type={"button"}
                        title="Закрыть"
                        onClick={() => props.setModalForNomenclatureCreateIsOpen(false)}
                        className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                    <FiX className="h-8 w-8"/>
                </button>
            </div>
            {(props.nomenclatureId ? nomenclature : true) && <NomenclatureFormCreate editFromBillOfMaterialsUI={true}
                                     onSaveFromBillOnMaterialsUI={props.setModalForNomenclatureCreateIsOpen}
                                     setCreatedNomenclature={props.setCreatedNomenclatureOption}
                                     data={props.nomenclatureId ? {...nomenclature, id: null} as NomenclatureForm : undefined}
                                     defaultMeasurementUnit={props.defaultMeasurementUnit}/>}
        </ReactModal>
    )
}