import {Button} from "../ui/buttons/Button";
import React, {useEffect} from "react";
import {backendApi, defBaseUrl, useInvalidateTokenMutation} from "../../store/backend-api/backend.api";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useDispatch} from "react-redux";
import {SeparatingLine} from "../ui/common/SeparatingLine";
import {tokensActions} from "../../store/slices/tokens.slice";
import {formButtonGroupStyle, formColumnsStyle, formStyle} from "../../utils/TailwindStyles";
import {UserCard} from "../../model/security/UserCard";
import {BlockForDisplayingText} from "../production-order/form/BlockForDisplayingText";
import {useNavigate} from "react-router-dom";
import {useUserPhotoActions} from "../../hooks/useUserPhotoActions";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {resetAllFiltersToDefault} from "../../store/utils/TokenUtils";

interface FormProps {
    data?: UserCard
}

export function ProfileRead(props: FormProps) {
    const userPhoto = useAppSelector(state => state.userPhoto.userPhoto)
    const userPhotoId = useAppSelector(state => state.userPhoto.userPhotoId)
    const {setUserPhoto} = useUserPhotoActions()
    const {resetFilterToDefault} = useFiltersActions()


    const [invalidateTokensRequest, {isSuccess: isSuccessInvalidateTokens}] = useInvalidateTokenMutation()
    const dispatch = useDispatch()
    const tokens = useAppSelector(state => state.localStorage.tokens)
    const navigate = useNavigate()

    function onClickExitButton() {
        resetAllFiltersToDefault(resetFilterToDefault)
        if (tokens) {
            invalidateTokensRequest(tokens)
        }
    }

    useEffect(() => {
        if (isSuccessInvalidateTokens) {
            dispatch(tokensActions.invalidateTokens())
            dispatch(backendApi.util.resetApiState())
        }
    }, [isSuccessInvalidateTokens]);

    useEffect(() => {
        if (props.data && props.data.photoId && props.data.photoId !== userPhotoId) {
            fetch(`${defBaseUrl()}/api/v2/file?id=${props.data.photoId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokens?.accessToken}`
                },
            })
                .then(response => response.blob())
                .then(blob => {
                    setUserPhoto({userPhotoId: props.data!.photoId!, userPhoto: blob})
                })
        }
    }, [props.data]);

    return (
        <div className={formStyle}>
            {!props.data && <p className="text-base font-light">Загрузка...</p>}
            {props.data && <div className="mt-2">
                {props.data.photoId && <>
                    {!userPhoto && <div className="text-base">Загрузка фото...</div>}
                    {userPhoto &&
                        <img src={`${URL.createObjectURL(userPhoto)}`} alt="Фото" className="h-52 object-contain"/>}
                </>}
                {!props.data.photoId && <p className="text-base font-light">Фото не загружено</p>}
                <SeparatingLine label={"Личная информация"}/>
                <div className={formColumnsStyle}>
                     <BlockForDisplayingText label={"Имя пользователя (login)"} text={props.data.username} standardHeightRequired={true}/>
                     <BlockForDisplayingText label={"ФИО"} text={props.data.subject} standardHeightRequired={true}/>
                     <BlockForDisplayingText label={"Номер телефона"} text={props.data.phoneNumber} standardHeightRequired={true}/>
                     <BlockForDisplayingText label={"Почта (e-mail)"} text={props.data.email} standardHeightRequired={true}/>
                     <BlockForDisplayingText label={"Административная роль"} text={props.data.role} standardHeightRequired={true}/>
                </div>
            </div>}
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                <Button buttonName={"Выйти"} onClickButton={onClickExitButton}/>
                <Button buttonName={"Редактировать"} onClickButton={() => navigate("/profile/edit")}/>
            </div>
        </div>
    )
}