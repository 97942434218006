import React, {useState} from "react";
import {Control, Controller} from "react-hook-form";
import {AsyncPaginate} from "react-select-async-paginate";
import {GroupBase, OptionsOrGroups, StylesConfig} from "react-select";
import {GuideFilter} from "../../../../model/filters/GuideFilter";
import {OptionType} from "../../../../model/option-type/OptionType";
import isEqual from "lodash.isequal";

const defaultFilter: GuideFilter = {
    pageNumber: 0,
    pageSize: 10,
    search: null,
    sortOrder: null
}

interface SelectProps<T> {
    control: Control<any>,
    rules: {},
    name: string,
    label?: string,
    defaultOption?: OptionType<T>,
    placeholder?: string,
    onChange?: Function,
    request: Function,
    //request: (filter: GuideFilter) => QueryActionCreatorResult<QueryDefinition<any, any, any, TablePage<T>>>,
    mapFunc: (data: any) => OptionType<T>[]
    filter?: GuideFilter
    isClearable?: boolean,
    isDisabled?: boolean,
    width?: string,
    additionalOptions?: OptionType<T>[]
}

export function ControlledPaginateAsyncSelect<T>(props: SelectProps<T>) {
    const selectStyles: StylesConfig<OptionType<T>, false, GroupBase<OptionType<T>>> = {
        control: (styles: any, state) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                borderRadius: '6px',
                minHeight: '36px',
                maxHeight: "36px",
                width: props.width ? props.width : "423px",
                backgroundColor: state.isDisabled ? "hsl(0, 0%, 100%)" : "hsl(0, 0%, 100%)",
                border: state.isDisabled ? "2px solid rgb(209 213 219)" : "2px solid rgb(156 163 175 / 80%)",
                marginTop: "2px",
                marginBottom: "1px",
                flexGrow: "1"

            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                visibility: "hidden"
            };
        },
        singleValue: (styles: any, state) => {
            return {
                ...styles,
                color: state.isDisabled ? 'black' : 'black',
            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                paddingTop: "0px",
                paddingBottom: "0px"
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                marginLeft: "1px"
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                color: "rgba(156, 163, 175, 0.8)"
            };
        },
        option: (styles: any) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
            };
        },
        menu: (styles: any) => {
            return {
                ...styles,
                width: props.width ? props.width : "423px",
            }
        }
    };

    const loadOptions = async (searchQuery: string, prevOptions: OptionsOrGroups<OptionType<T>, GroupBase<OptionType<T>>>, {page}: {
        page?: number
    } = {}) => {
        const templateFilter = props.filter !== undefined ? props.filter : defaultFilter
        const filter = {
            ...templateFilter,
            search: searchQuery.length > 0 ? searchQuery : null,
            pageNumber: page!
        } as GuideFilter

        const options: OptionType<T>[] = []

        if (prevOptions.length === 0) {
            if (props.additionalOptions !== undefined) {
                options.push(...props.additionalOptions)
            }

            if (props.defaultOption !== undefined) {
                options.push(props.defaultOption)
            }
        }

        const data = (await props.request(filter).unwrap());
        const optionsFromData = props.mapFunc(data)
            .filter((option: OptionType<T>) => !isEqual(option.value, props.defaultOption?.value))
        options.push(...optionsFromData)

        return {
            options,
            hasMore: data!.pageNumber < data!.pageCount,
            additional: {
                page: page! + 1
            }
        }
    }

    return (
        <Controller control={props.control} name={props.name} rules={props.rules}
                    defaultValue={props.defaultOption !== undefined ? props.defaultOption : undefined}
                    render={({field: {onChange, value}, fieldState: {error}}) =>
                        <>
                            <label
                                className="text-base font-normal"
                            > {props.label}
                                <AsyncPaginate
                                    menuPosition="fixed"
                                    loadOptions={loadOptions}
                                    value={value}
                                    onChange={onChange}
                                    additional={{
                                        page: 0
                                    }}
                                    isDisabled={props.isDisabled !== undefined ? props.isDisabled : false}
                                    isClearable={props.isClearable !== undefined ? props.isClearable : true}
                                    placeholder={props.placeholder ?? "Не выбрано"}
                                    styles={selectStyles}

                                />
                                {error && <p className="text-sm font-normal text-red-400">{error.message}</p>}
                            </label>

                        </>}/>
    )
}