import {
    MainProductOrAdditionalEquipmentInterface,
    NomenclatureInfo,
    ProductionLine
} from "../production-order/ProductionOrder";
import {GuideFilter} from "../filters/GuideFilter";
import {WorkOrderInfo} from "../work-order-info/WorkOrderInfo";

export interface PositionsForProductionOrderExecution {
    productionOrderDocumentNumber: number,
    workOrderInfo: WorkOrderInfo,
    mainProduct: {
        number: number,
        details: NomenclatureInfo,
        quantity: string
    },
    additionalEquipment: AdditionalEquipmentForPosition[]
}

export interface AdditionalEquipmentForPosition {
    number: number,
    details: NomenclatureInfo,
    quantity: string
}

export interface TasksForProductionOrderExecution {
    productionOrderDocumentNumber: number,
    productionTasks: ProductionTask[]
}

export interface ProductionStageInfo {
    name: string,
    isCompleted: boolean,
    status: ProductionTaskStatus
}

export interface ProductionTask {
    documentNumber: number,
    materials: [
        {
            nomenclatureId: number,
            name: string,
            measurementUnit: {
                id: number,
                name: string
            },
            quantity: string | null,
            sourceProductionStage: {
                id: number,
                name: string,
                status: ProductionTaskStatus
            } | null
        }
    ],
    product: {
        nomenclature: NomenclatureInfo,
        plannedQuantity: number,
        actualQuantity: number,
        size: number | null,
        sizeMeasurementUnit: {
            id: number,
            name: string
        } | null
    },
    productionStage: {
        id: number,
        name: string
    },
    status: ProductionTaskStatus,
    nextProductionStage: {
        id: number,
        name: string,
        status: ProductionTaskStatus
    } | null
}

export interface ProductionTaskAttachment{
    id: string,
    fileId: string,
    fileName: string,
    productionTaskDocumentNumber: number,
    createdTime: string
}

export enum ProductionTaskStatus {
    UNAVAILABLE = "UNAVAILABLE",
    IN_LINE = "IN_LINE",
    IN_PRODUCTION = "IN_PRODUCTION",
    SUSPENDED = "SUSPENDED",
    POSTPONED = "POSTPONED",
    COMPLETED = "COMPLETED"
}

export interface ProductionOrderExecutionJournalRow {
    documentNumber: number,
    workOrderNumber: number,
    priority: number,
    executionStatus: ProductionTaskStatus,
    productionLine: ProductionLine,
    mainProduct: MainProductOrAdditionalEquipmentInterface,
    additionalEquipment: MainProductOrAdditionalEquipmentInterface[],
}

export interface ProductionOrderExecutionJournalFilter extends GuideFilter {
    productionLineId: number | null
}