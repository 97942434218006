import {ObjectsWithCustomizableAccess, Permission} from "../../model/administrative-roles/AdministrativeRole";
import {ProductionStage} from "../../model/production-stage/ProductionStage";

export function createDefaultValuesForPermissionArray(permissionArray: Permission[]) {
    if (permissionArray.length === 0) {
        return Object.keys(ObjectsWithCustomizableAccess).map(key => ({
            objectName: key as keyof typeof ObjectsWithCustomizableAccess,
            access: Permission.NO_ACCESS
        }))
    }
    return Object.keys(ObjectsWithCustomizableAccess).map(key => ({
        objectName: key as keyof typeof ObjectsWithCustomizableAccess,
        access: permissionArray.find(permission => permission.startsWith(key)) || Permission.NO_ACCESS
    }))
}

export function createDefaultValuesForProductionStageArray(productionStages: ProductionStage[], selectedProductionStages: number[]) {
    if (selectedProductionStages.length === 0) {
        return productionStages.map(stage => ({checkboxValue: stage.id, checkboxLabel: stage.name, checked: false}))
    }
    return productionStages.map(productionStage => ({checkboxValue: productionStage.id, checkboxLabel: productionStage.name, checked: selectedProductionStages.some(stageId => stageId === productionStage.id)}))
}

export function onClickDeleteAdministrativeRoleButton (id: number, doDeleteAdministrativeRole: (id: number) => any) {
    doDeleteAdministrativeRole(id)
}
