import {MeasurementUnit} from "../../../model/measurement/MeasurementUnit";
import {MeasurementUnitFormTemplate} from "./MeasurementUnitFormTemplate";

interface FormProps {
    data: MeasurementUnit
}

export function MeasurementUnitFormRead(props: FormProps) {
    return (
        <MeasurementUnitFormTemplate data={props.data} readonly={true}/>
    )
}