import React from "react";
import {ProductionStageProgress} from "../../../model/production-order/ProductionOrder";

interface TableProps {
    tableData: ProductionStageProgress[]
}

export function ExecutionByProductionStagesTable(props: TableProps) {

    function getExecutionStatus(stageProgress: ProductionStageProgress) {
        if (stageProgress.percentageProgress === 0) {
            return "В очереди"
        }
        if (stageProgress.percentageProgress === 100) {
            return "Выполнено"
        }
        return "В работе"
    }

    return (
        <table>
            <thead>
                <tr>
                    <th className="border border-black text-base font-normal text-center px-2">Этап</th>
                    <th className="border border-black text-base font-normal text-center px-2">Выполнение</th>
                    <th className="border border-black text-base font-normal text-center px-2">Статус</th>
                </tr>
            </thead>
            <tbody>
                {props.tableData.map(stage => <tr key={stage.productionStage.id}>
                    <td className="border border-black text-base font-light px-2">{stage.productionStage.name}</td>
                    <td className="border border-black text-base font-light px-2">{`${stage.quantityProgress.actualQuantity}/${stage.quantityProgress.planningQuantity}`}</td>
                    <td className="border border-black text-base font-light px-2">{getExecutionStatus(stage)}</td>
                </tr>)}
            </tbody>
        </table>
    )
}