import React from "react";
import {Control, Controller, useFieldArray} from "react-hook-form";
import {AddButtonText} from "../../buttons/AddButtonText";

interface SelectProps {
    control: Control<any>,
    rules: {},
    label?: string,
    readonly?: boolean,
    additionalStyles?: string,
    currentArrayValue?: any[],
    setValue?: any
}

export interface MultiSelectFormFields {
    multiSelectWithCheckboxes: MultiSelectWithCheckboxes[]
}

interface MultiSelectWithCheckboxes {
    checkboxValue: any,
    checkboxLabel: string,
    checked: boolean
}

export function ControlledMultiSelectUseFieldArray(props: SelectProps) {
    const borderColor: string = props.readonly !== undefined && props.readonly ? "border-gray-300" : "border-gray-400/[.80]"
    const labelStyles: string = props.readonly !== undefined && props.readonly ? "" : "hover:bg-blue-500/[.15] cursor-pointer"

    const {fields} = useFieldArray<MultiSelectFormFields>({
        name: "multiSelectWithCheckboxes",
        control: props.control
    })

    return (<div className={`${props.additionalStyles}`}>
        <div className='flex flex-row justify-between max-w-[423px]'>
            <div className="text-base font-normal">{props.label}</div>
            {!!props.currentArrayValue && !!props.setValue && !props.readonly && <AddButtonText marginBottom='mb-0' name={'Выбрать всё'} onClick={() => {
                const multiSelectWithCheckboxes = (props.currentArrayValue || []).map(value => ({
                    checkboxValue: value.checkboxValue!,
                    checkboxLabel: value.checkboxLabel!,
                    checked: value.checked!
                }))
                const isAllSelected = !multiSelectWithCheckboxes.some(value => !value.checked)
                const newMultiSelectWithCheckboxesValue = multiSelectWithCheckboxes.map(value => ({
                    ...value,
                    checked: !isAllSelected
                })) || []
                props.setValue('multiSelectWithCheckboxes', newMultiSelectWithCheckboxesValue)
            }}/>}
        </div>
        <div className={`${borderColor} ${props.additionalStyles} mt-[2px] border-2 rounded-md max-w-[423px] max-h-[180px] overflow-auto`}>
            {fields.map((field, index) =>
                <Controller key={field.id} control={props.control} name={`multiSelectWithCheckboxes.${index}.checked`} rules={{}}
                            render={({field: {value, onChange}, fieldState: {error}}) => <>
                                <label className={`${labelStyles} rounded-md px-3 py-1 border-2 border-white w-full text-base font-light flex flex-row items-center`}>
                                    <input type="checkbox" value={field.checkboxValue}
                                           onChange={(event) => onChange(event.target.checked)}
                                           className={`${borderColor} w-[18px] h-[18px] mr-2`}
                                           checked={value}
                                           readOnly={props.readonly}
                                           disabled={props.readonly}/>
                                    {field.checkboxLabel}
                                </label>
                                {error && <p className="text-sm font-normal text-red-400">{error.message}</p>}</>}/>
            )}
        </div>
    </div>)
}