import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {BackButton} from "../ui/buttons/BackButton";
import {ContactType, CreateOrUpdateIndividuals, Gender, Individuals} from "../../model/individuals/Individuals";
import {
    defBaseUrl,
    useCreateIndividualMutation,
    useDeleteIndividualMutation, useDeleteIndividualPhotoMutation, useGetUserCardQuery,
    useUpdateIndividualMutation
} from "../../store/backend-api/backend.api";
import {FileUploader} from "../ui/common/FileUploader";
import {SeparatingLine} from "../ui/common/SeparatingLine";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import {ControlledDatePicker} from "../ui/form-components/controlled/ControlledDatePicker";
import {ControlledSelect} from "../ui/form-components/controlled/ControlledSelect";
import {contactTypesToString, gendersToString} from "../../data/IndividualsData";
import {OptionType} from "../../model/option-type/OptionType";
import {AddButtonText} from "../ui/buttons/AddButtonText";
import {DeleteButtonText} from "../ui/buttons/DeleteButtonText";
import {Button} from "../ui/buttons/Button";
import {useAppSelector} from "../../hooks/useAppSelector";
import {formButtonGroupStyle, formColumnsStyle, formHeaderStyle, formStyle} from "../../utils/TailwindStyles";
import {useUserPhotoActions} from "../../hooks/useUserPhotoActions";

interface FormProps {
    data?: Individuals,
    readonly?: boolean
}

interface FormFields {
    id: number | null,
    firstName: string,
    lastName: string,
    middleName: string,
    birthDate: Date | null,
    gender: OptionType<Gender>,
    comment: string,
    itn: string,
    snils: string,
    contactInfo: { contactType: OptionType<ContactType> | null, value: string, comment: string }[]
}

export function IndividualsFormTemplate(props: FormProps) {
    const navigate = useNavigate()
    const defaultFormValues: FormFields = props.data ? {
        id: props.data.id,
        firstName: props.data.firstName,
        lastName: props.data.lastName,
        middleName: props.data.middleName,
        birthDate: new Date(props.data.birthDate),
        gender: {label: gendersToString.get(props.data.gender)!, value: props.data.gender},
        comment: props.data.comment || "",
        itn: props.data.itn || "",
        snils: props.data.snils || "",
        contactInfo: props.data.contactInfo.length > 0 ? props.data.contactInfo.map(info => ({contactType: {label: contactTypesToString.get(info.contactType)!, value: info.contactType}, value: info.value, comment: info.comment})) : []
    } : {
        id: null,
        firstName: "",
        lastName: "",
        middleName: "",
        birthDate: null,
        gender: {label: gendersToString.get(Gender.NONE)!, value: Gender.NONE},
        comment: "",
        itn: "",
        snils: "",
        contactInfo: []
    }
    const {control, handleSubmit, formState: {isDirty}} = useForm<FormFields>({mode: "onChange", defaultValues: defaultFormValues})
    const {fields, append, remove} = useFieldArray({
        name: "contactInfo",
        control: control
    })
    const [imageBase64, setImageBase64] = useState<Blob | null>(null)
    const [isSuccessGetImage, setIsSuccessGetImage] = useState<boolean>(false)
    const [doUpdateIndividual, {isSuccess: isSuccessUpdate}] = useUpdateIndividualMutation()
    const [doCreateIndividual, {data: createdIndividualId, isSuccess: isSuccessCreate}] = useCreateIndividualMutation()
    const [doDeleteIndividual, {isSuccess: isSuccessDelete}] = useDeleteIndividualMutation()
    const [doDeleteIndividualPhoto] = useDeleteIndividualPhotoMutation()
    const tokens = useAppSelector(state => state.localStorage.tokens)
    const {setUserPhoto} = useUserPhotoActions()
    const {data: userCard} = useGetUserCardQuery()

    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        const newIndividual: CreateOrUpdateIndividuals = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            gender: data.gender!.value,
            birthDate: data.birthDate!,
            comment: data.comment,
            itn: data.itn || null,
            snils: data.snils || null,
            contactInfo: data.contactInfo.length > 0 ? data.contactInfo.map(info => ({contactType: info.contactType!.value, value: info.value, comment: info.comment})) : []
        }
        if (props.data && isDirty) {
            doUpdateIndividual({id: props.data.id, body: newIndividual})
        }
        else if (!props.data) {
            doCreateIndividual(newIndividual)
        }
    }

    useEffect(() => {
        if (props.data && props.data.photoId) {
            fetch(`${defBaseUrl()}/api/v2/file?id=${props.data.photoId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokens?.accessToken}`
                },
            })
                .then(response => response.blob())
                .then(blob => {
                    setImageBase64(blob)
                    setIsSuccessGetImage(true)
                })
        }
    }, [props.data]);

    useEffect(() => {
        if (isSuccessUpdate) {
            navigate(`/individuals?id=${props.data!.id}`)
        }
        if (isSuccessCreate) {
            navigate(`/individuals?id=${createdIndividualId}`)
        }
        if (isSuccessDelete) {
            navigate('individuals/guide')
        }
    }, [isSuccessUpdate, isSuccessDelete, isSuccessCreate, createdIndividualId]);

    return (
        <form onSubmit={handleSubmit(onClickSaveButton)} className={formStyle}>
            <div className={formHeaderStyle}>
                <p>ID: {defaultFormValues.id !== null ? defaultFormValues.id : "не определен"}</p>
                <BackButton/>
            </div>
            {props.data && props.data.photoId && userCard && <div className="flex flex-col gap-y-2 items-start">
                {!isSuccessGetImage && <div className="text-base">Загрузка фото...</div>}
                {imageBase64 && isSuccessGetImage && <img src={`${URL.createObjectURL(imageBase64)}`} alt="Фото" className="h-52 object-contain"/>}
                {!props.readonly && <div className="flex flex-row gap-x-8">
                    <FileUploader individualId={props.data!.id} isUserIndividual={props.data!.id === userCard.individualId} label={"Загрузить другое фото"}/>
                    <DeleteButtonText onClick={() => {
                        doDeleteIndividualPhoto({id: props.data!.id, isUserIndividual: props.data!.id === userCard.individualId})
                        if (props.data!.id === userCard.individualId) {
                            setUserPhoto(null)
                        }
                    }} label={"Удалить фото"}/>
                </div>}
            </div>}
            {(props.data && !props.data.photoId && userCard) && <>
                {!props.readonly && <FileUploader individualId={props.data!.id} isUserIndividual={props.data!.id === userCard.individualId} label={"Загрузить фото"}/>}
                {props.readonly && <p className="text-base font-light">Фото не загружено</p>}
            </>}
            {!props.data &&
                <p className="text-base font-light">Фото можно загрузить после создания физического лица</p>}
            <SeparatingLine label={"Личная информация"}/>
            <div className={formColumnsStyle}>
                <ControlledInputField control={control}
                                      rules={{required: {value: true, message: "Это обязательное поле"}}}
                                      name={"lastName"} label={"Фамилия"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
                <ControlledDatePicker control={control}
                                      rules={{required: {value: true, message: "Это обязательное поле"}}}
                                      name={"birthDate"} label={"Дата рождения"}
                                      readonly={props.readonly}
                                      additionalStyles={"w-[423px]"}/>
                <ControlledInputField control={control}
                                      rules={{required: {value: true, message: "Это обязательное поле"}}}
                                      name={"firstName"} label={"Имя"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
                <ControlledSelect control={control} rules={{required: {value: true, message: "Это обязательное поле"}}}
                                  name={"gender"}
                                  options={Object.keys(Gender).map(key => ({label: gendersToString.get(Gender[key as keyof typeof Gender])!, value: Gender[key as keyof typeof Gender]}))}
                                  label={"Пол"} isDisabled={props.readonly}/>
                <ControlledInputField control={control}
                                      rules={{}}
                                      name={"middleName"} label={"Отчество"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
                <ControlledInputField control={control}
                                      rules={{}}
                                      name={"comment"} label={"Комментарий"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
            </div>
            <SeparatingLine label={"Дополнительная информация"}/>
            <div className={formColumnsStyle}>
                <ControlledInputField control={control}
                                      rules={{}}
                                      name={"itn"} label={"ИНН"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
                <ControlledInputField control={control}
                                      rules={{}}
                                      name={"snils"} label={"СНИЛС"} additionalStyles={"w-[423px]"}
                                      readonly={props.readonly}/>
            </div>
            <SeparatingLine label={"Контактные данные"}/>
            {props.data && props.readonly && props.data.contactInfo.length === 0 && <p className="text-base font-light">Контактные данные не добавлены.</p>}
            {((props.data && props.readonly && props.data.contactInfo.length > 0) || !props.readonly) && <><div className="flex flex-row">
                <p className="mb-[6px] ml-5 min-w-[200px]">Тип</p>
                <p className="mb-[6px] ml-6 min-w-[323px]">Данные</p>
                <div className="flex flex-row justify-between w-[323px] ml-[22px]">
                    <p className="mb-[6px]">Комментарий</p>
                    {!props.readonly && <AddButtonText onClick={() => {
                        append({contactType: null, value: "", comment: ""})
                    }}/>}
                </div>
            </div>
            <ol className="list-decimal ml-4 space-y-3">
                {fields.map((field, index) =>
                    <li key={field.id}>
                        <div className="flex flex-row gap-6">
                            <ControlledSelect control={control} rules={{required: {value: true, message: "Это обязательное поле"}}}
                                              name={`contactInfo.${index}.contactType`}
                                              options={Object.keys(ContactType).map(key => ({label: contactTypesToString.get(ContactType[key as keyof typeof ContactType])!, value: ContactType[key as keyof typeof ContactType]}))}
                                              isDisabled={props.readonly} width={"200px"}/>
                            <ControlledInputField control={control} rules={{
                                required: {
                                    value: true,
                                    message: "Это обязательное поле"
                                }
                            }} readonly={props.readonly} name={`contactInfo.${index}.value`}
                                                  additionalStyles={"w-[323px]"}/>
                            <ControlledInputField control={control} rules={{}} readonly={props.readonly} name={`contactInfo.${index}.comment`}
                                                  additionalStyles={"w-[323px]"}/>
                            {!props.readonly && (
                                <DeleteButtonText onClick={() => remove(index)}/>)}
                        </div>
                    </li>
                )}
            </ol></>}
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {props.data && <Button buttonName={"Удалить"} onClickButton={() => doDeleteIndividual(props.data!.id)}/>}
                {!props.readonly &&
                    <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onClickSaveButton)}/>}
                {props.readonly &&
                    <Button buttonName={"Редактировать"} onClickButton={() => {navigate(`/individuals/edit?id=${props.data!.id}`)}}/>}
            </div>
        </form>
    )
}