import {FiX} from "react-icons/fi";
import {NomenclatureFormEdit} from "../nomenclature/form/NomenclatureFormEdit";
import ReactModal from "react-modal";
import React from "react";
import {NomenclatureForm} from "../../model/nomenclature/NomenclatureForm";

interface ModalProps {
    nomenclature: NomenclatureForm,
    modalForNomenclatureEditIsOpen: boolean,
    setModalForNomenclatureEditIsOpen: (value: boolean) => void
}

export function ModalForEditNomenclature(props: ModalProps) {
    return (
        <ReactModal isOpen={props.modalForNomenclatureEditIsOpen}
                    onRequestClose={() => props.setModalForNomenclatureEditIsOpen(false)}>
            <div className="flex flex-row justify-end">
                <button type={"button"}
                        title="Закрыть"
                        onClick={() => props.setModalForNomenclatureEditIsOpen(false)}
                        className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                    <FiX className="h-8 w-8"/>
                </button>
            </div>
            <NomenclatureFormEdit data={props.nomenclature} editFromBillOfMaterialInterface={true}
                                  onSaveFromBillOnMaterialsUI={props.setModalForNomenclatureEditIsOpen}/>
        </ReactModal>
    )
}