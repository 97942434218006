import React from "react";
import {FiX} from "react-icons/fi";

interface Props {
    onClose?: () => void
}

export function NewCloseFilterMenuButton(props: Props) {
    return (<button type="button"
                    title="Закрыть"
                    onClick={props.onClose}
                    className="text-gray-400/[.90] m-3">
            <FiX className="stroke-2 h-6 w-6"/>
        </button>
    )
}