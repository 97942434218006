import React from "react";
import {AdministrativeRoleFormTemplate} from "./AdministrativeRoleFormTemplate";
import {useSearchProductionStagesGuidePageQuery} from "../../store/backend-api/backend.api";
import {GuideFilter} from "../../model/filters/GuideFilter";

export function AdministrativeRolesFormCreate() {
    const {data: productionStages, isSuccess} = useSearchProductionStagesGuidePageQuery({pageNumber: 0, pageSize: 500, search: null, sortOrder: null} as GuideFilter)

    return (<>
        {isSuccess && <AdministrativeRoleFormTemplate productionStages={productionStages.rows}/>}
    </>)
}