import React, {useEffect} from 'react';
import {
    AdministrativeAndFunctionalZone,
    AdministrativeAndFunctionalZonePurpose,
    CreateOrUpdateAdministrativeAndFunctionalZone
} from "../../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {
    useCreateAdministrativeAndFunctionalZoneMutation,
    useDeleteAdministrativeAndFunctionalZoneMutation,
    useLazySearchUserGuidePageQuery,
    useUpdateAdministrativeAndFunctionalZoneMutation
} from "../../../store/backend-api/backend.api";
import {BackButton} from "../../ui/buttons/BackButton";
import {ControlledInputField} from "../../ui/form-components/controlled/ControlledInputField";
import {SeparatingLine} from "../../ui/common/SeparatingLine";
import {Button} from "../../ui/buttons/Button";
import {SubmitHandler, useForm} from "react-hook-form";
import {OptionType} from "../../../model/option-type/OptionType"
import {purposesToString} from "../../../data/AdministrativeAndFunctionalZoneFormData";
import {useNavigate} from "react-router-dom";
import {ControlledPaginateAsyncSelect} from "../../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {ControlledSelect} from "../../ui/form-components/controlled/ControlledSelect";
import {TablePage} from "../../../model/utils/TablePage";
import {UserGuideRow} from "../../../model/users/UserGuideRow";
import {formButtonGroupStyle, formColumnsStyle, formHeaderStyle, formStyle} from "../../../utils/TailwindStyles";

interface FormProps {
    data?: AdministrativeAndFunctionalZone,
    supervisorSubject?: string,
    readonly?: boolean,
    buttonName?: string
}

interface FormFields {
    name: string,
    purpose: OptionType<AdministrativeAndFunctionalZonePurpose> | null,
    supervisorId: OptionType<number> | null
}

export function AdministrativeAndFunctionalZoneFormTemplate(props: FormProps) {
    const navigate = useNavigate()
    const [doCreateAdministrativeAndFunctionalZone, {data: createdAdministrativeAndFunctionalZoneId, isSuccess: isSuccessCreate}] = useCreateAdministrativeAndFunctionalZoneMutation()
    const [doUpdateAdministrativeAndFunctionalZone, {isSuccess: isSuccessUpdate}] = useUpdateAdministrativeAndFunctionalZoneMutation()
    const [doDeleteAdministrativeAndFunctionalZone, {isSuccess: isSuccessDelete}] = useDeleteAdministrativeAndFunctionalZoneMutation()
    const [doSearchUserGuidePage] = useLazySearchUserGuidePageQuery()

    const defaultValues: FormFields = props.data ? {
        name: props.data.name,
        purpose: {label: purposesToString.get(props.data.purpose)!, value: props.data.purpose},
        supervisorId: props.data.supervisorId && props.supervisorSubject ? {
            label: props.supervisorSubject,
            value: props.data.supervisorId
        } : null
    } : {
        name: "",
        purpose: null,
        supervisorId: null
    }

    const {handleSubmit, control, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues
    })
    const onSubmit: SubmitHandler<FormFields> = data => {
        const administrativeAndFunctionalZone: CreateOrUpdateAdministrativeAndFunctionalZone = {
            name: data.name,
            purpose: data.purpose!.value,
            supervisorId: data.supervisorId?.value || null
        }
        if (props.data && isDirty) {
            doUpdateAdministrativeAndFunctionalZone({id: props.data.id, body: administrativeAndFunctionalZone})
        } else if (!props.data) {
            doCreateAdministrativeAndFunctionalZone(administrativeAndFunctionalZone)
        }
    }

    useEffect(() => {
        if (isSuccessCreate && createdAdministrativeAndFunctionalZoneId) {
            navigate(`/administrative-and-functional-zone?id=${createdAdministrativeAndFunctionalZoneId}`)
        }
        if (isSuccessUpdate) {
            navigate(`/administrative-and-functional-zone?id=${props.data!.id}`)
        }
        if (isSuccessDelete) {
            navigate(`/administrative-and-functional-zone/guide`)
        }
    }, [isSuccessCreate, isSuccessUpdate, isSuccessDelete]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
            <div className={formHeaderStyle}>
                <p>ID: {props.data?.id ? props.data.id : "не определен"}</p>
                <BackButton/>
            </div>
            <div className={formColumnsStyle}>
                <ControlledInputField control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} name={"name"} label={"Наименование"} additionalStyles={"w-[423px]"} readonly={props.readonly}/>
                <ControlledSelect control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} name={"purpose"} label={"Назначение зоны"} options={
                    Array.from(purposesToString.keys()).map(key => ({
                        label: purposesToString.get(key)!,
                        value: key
                    }))
                } isDisabled={props.readonly}/>
                <ControlledPaginateAsyncSelect control={control} rules={{}} name={"supervisorId"}
                                               label={"Начальник участка"}
                                               request={doSearchUserGuidePage}
                                               mapFunc={(data: TablePage<UserGuideRow>) =>
                                                   data.rows.map(row => ({label: row.subject, value: row.id}))
                                               } isDisabled={props.readonly}/>
            </div>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {props.data &&
                    <Button buttonName={"Удалить"}
                            onClickButton={() => doDeleteAdministrativeAndFunctionalZone(props.data!.id)}/>}
                {!props.readonly &&
                    <Button buttonName={props.buttonName!} onClickButton={handleSubmit(onSubmit)}/>}
                {props.readonly &&
                    <Button buttonName={"Редактировать"} onClickButton={() => {
                        navigate(`/administrative-and-functional-zone/edit?id=${props.data!.id}`)
                    }}/>}
            </div>
        </form>
    )
}