export const objectsWithCustomizableAccessToString = new Map<string, string>([
    ["NOMENCLATURE", "Номенклатура"],
    ["MEASUREMENT_UNIT", "Единицы измерения"],
    ["ADMINISTRATIVE_ROLE", "Административные роли"],
    ["USER", "Пользователи системы"],
    ["INDIVIDUALS", "Физические лица"],
    ["TECHNOLOGICAL_PROCESS_CHART", "Технологические карты"],
    ["PRODUCTION_STAGE", "Этапы производства"],
    ["TECHNOLOGICAL_OPERATION", "Технологические операции"],
    ["ADMINISTRATIVE_AND_FUNCTIONAL_ZONE", "Административно-функциональные зоны"],
    ["PRODUCTION_ORDER", "Производственные заказы"]
])
