import React, {useEffect, useState} from 'react';
import {PiX} from "react-icons/pi";
import {removeToast} from "../../store/slices/toast.slice";
import {useDispatch} from "react-redux";

export interface ToastInterface {
    id: number,
    error: {
        status: string,
        title: string,
        detail: string,
        request: any
    }
}

interface ToastProps {
    toast: ToastInterface
}

export function Toast(props: ToastProps) {
    const dispatch = useDispatch()
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
    const [isToastDeleted, setIsToastDeleted] = useState<boolean>(false)
    useEffect(() => {
        const newTimeoutId = setTimeout(() => {
            setIsToastDeleted(true)
            setTimeout(() => {
                dispatch(removeToast(props.toast.id))
            }, 500)
        }, 9500)
        setTimeoutId(newTimeoutId)

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
                setTimeoutId(null)
            }
        }
    }, []);

    function handleMouseEnter() {
        if (timeoutId) {
            clearTimeout(timeoutId)
            setTimeoutId(null)
            setIsToastDeleted(false)
        }
    }

    function handleMouseLeave() {
        if (!isToastDeleted) {
            const newTimeoutId = setTimeout(() => {
                setIsToastDeleted(true)
                setTimeout(() => {
                    dispatch(removeToast(props.toast.id))
                }, 500)
            }, 9500)
            setTimeoutId(newTimeoutId)
        }
    }

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
             className={`flex flex-col h-fit w-min-80 w-fit transition-opacity duration-500 ease-in-out ${isToastDeleted ? 'opacity-0' : 'opacity-90'} hover:opacity-100 p-2 rounded-[5px] leading-tight border border-red-600/[.60] bg-white text-sm`}>
            <div className="flex flex-row gap-x-3 whitespace-nowrap justify-between leading-none">
                <span>{`${props.toast.error.status}: ${props.toast.error.title}`}</span>
                <PiX className="ml-auto" onClick={() => dispatch(removeToast(props.toast.id))}/>
            </div>
            <div className={`border-b-[1px] border-red-600/[.60] h-1 my-1 grow`}></div>
            <p className="w-72">{props.toast.error.detail}</p>
        </div>
    )
}
