import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useGetUserQuery} from "../../store/backend-api/backend.api";
import {UserFormRead} from "../../components/users/UserFormRead";

export function ReadUserForm() {
    const [searchParams] = useSearchParams()
    const id = Number(searchParams.get("id"))
    const {data, isSuccess} = useGetUserQuery(id)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [data])

    return (
        <div key={key}>
            {isSuccess && <UserFormRead data={data}/>}
        </div>
    )
}