import React from "react";
import {TableColumn} from "../tables/Table";
import {ProductionStage} from "../../model/production-stage/ProductionStage";
import {useNavigate} from "react-router-dom";
import {TablePage} from "../../model/utils/TablePage";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {Table} from "../tables/Table";

interface TableProps {
    data: TablePage<ProductionStage>
}

export function ProductionStageTable(props: TableProps) {
    const {updateFilterPage} = useFiltersActions()

    const navigate = useNavigate()

    const idColumn: TableColumn<ProductionStage> = {
        columnName: "ID",
        extractCellContent: row => row.id
    }

    const nameColumn: TableColumn<ProductionStage> = {
        columnName: "Наименование этапа",
        extractCellContent: row => row.name
    }

    function onRowClick(row: ProductionStage) {
        navigate(`/production-stages?id=${row.id}`)
    }

    return (
        <Table<ProductionStage>
            tableColumns={[idColumn, nameColumn]}
            rows={props.data.rows}
            onRowClick={onRowClick}
            tableFilterKey={TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER, value: newPageNumber})
                }
            }}/>
    )
}