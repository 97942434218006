import React from "react";
import {
    AdministrativeAndFunctionalZoneFormCreate
} from "../../components/administrative-and-functional-zone/form/AdministrativeAndFunctionalZoneFormCreate";

export function CreateAdministrativeAndFunctionalZoneForm() {

    return (
        <AdministrativeAndFunctionalZoneFormCreate/>
    )
}