import {NomenclatureForm, ProductionNomenclatureClassifier} from "../../model/nomenclature/NomenclatureForm";
import {workReadyProductTypeToString, purposeReproducibilityToString} from "../../data/NomenclatureData";
import {OptionType} from "../../model/option-type/OptionType";
import {NomenclatureFormFields} from "../nomenclature/form/NomenclatureFormTemplate";

export function getDefaultValuesForNomenclatureForm(nomenclature?: NomenclatureForm, defaultMeasurementUnit?: OptionType<number>): NomenclatureFormFields {
    if (nomenclature && defaultMeasurementUnit) {
        return {
            name: nomenclature.name,
            productionNomenclatureClassifier: defaultOptionForProductionClassifier(nomenclature),
            measurementUnitId: defaultMeasurementUnit || null,
            sku: nomenclature.sku || "",
            drawingNumber: nomenclature.drawingNumber || "",
            additionalSku: nomenclature.additionalSku,
            comment: nomenclature.comment,
            purposeReproducibility: nomenclature.readyProduct !== null ? {
                label: purposeReproducibilityToString.get(nomenclature.readyProduct.purposeReproducibility)!,
                value: nomenclature.readyProduct.purposeReproducibility
            } : null,
            type: nomenclature.readyProduct !== null ? {
                label: workReadyProductTypeToString.get(nomenclature.readyProduct.type)!,
                value: nomenclature.readyProduct.type
            } : null,
            rawMaterial: nomenclature.rawMaterial,
            uncompletedManufacturing: nomenclature.uncompletedManufacturing
        }
    }
    return {
        name: "",
        productionNomenclatureClassifier: [null, null, null],
        measurementUnitId: null,
        sku: "",
        drawingNumber: "",
        additionalSku: [],
        comment: "",
        purposeReproducibility: null,
        type: null,
        rawMaterial: null,
        uncompletedManufacturing: null
    }
}

function defaultOptionForProductionClassifier(nomenclature: NomenclatureForm) {
    const arrayValues: (ProductionNomenclatureClassifier | null)[] = []
    nomenclature.readyProduct === null ? arrayValues.push(null) : arrayValues.push(ProductionNomenclatureClassifier.READY_PRODUCT)
    nomenclature.rawMaterial === null ? arrayValues.push(null) : arrayValues.push(ProductionNomenclatureClassifier.RAW_MATERIAL)
    nomenclature.uncompletedManufacturing === null ? arrayValues.push(null) : arrayValues.push(ProductionNomenclatureClassifier.UNCOMPLETED_MANUFACTURING)
    return arrayValues
}
