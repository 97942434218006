import {GuideFilter} from "../filters/GuideFilter";
import {AdministrativeAndFunctionalZonePurpose} from "./AdministrativeAndFunctionalZone";

export interface AdministrativeAndFunctionalZoneGuideFilter extends GuideFilter {
    purpose: AdministrativeAndFunctionalZonePurpose | null,
    sortedField: AdministrativeAndFunctionalZoneSortedField | null,
}

export enum AdministrativeAndFunctionalZoneSortedField {
    ID = "ID",
    NAME = "NAME",
    PURPOSE = "PURPOSE"
}