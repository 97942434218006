import React, {useEffect} from "react";
import {ProductionStageTable} from "../../components/production-stages/ProductionStageTable";
import {useSearchProductionStagesGuidePageQuery} from "../../store/backend-api/backend.api";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useDataActions} from "../../hooks/useDataActions";
import {DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER} from "../../model/filters/DefaultFilters";

export function ProductionStageGuide() {
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER])

    const {data: tablePage} = useSearchProductionStagesGuidePageQuery(filter)

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage]);

    return (
        <div className="space-y-3.5 h-[98%]">
            <div>
                <div className="min-w-full flex flex-row">
                    <GuideTopBox tableFilterKey={TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER}
                                 createNavigationURL={'/production-stages/new'}
                                 searchInfo={"Поиск по ID и наименованию этапа."}/>
                </div>
            </div>
            <div className="h-[90%] bg-transparent rounded-2xl">
                <div className="bg-transparent rounded-2xl h-[100%]">
                    {data && <ProductionStageTable data={data}/>}
                </div>
            </div>
        </div>
    )
}