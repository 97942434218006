import {GuideFilter} from "../filters/GuideFilter";

export interface MeasurementGuideFilter extends GuideFilter {
    sortedField: MeasurementSortedField | null
}

export enum MeasurementSortedField {
    ID = "ID",
    NAME = "NAME",
    ABBREVIATION = "ABBREVIATION",
    OKEI_CODE = "OKEI_CODE",
    OKEI_TYPE = "OKEI_TYPE",
    COMMENT = "COMMENT"
}