import React from "react";

interface ButtonProps {
    onClick: () => void,
    label?: string
}
export function DeleteButtonText (props: ButtonProps) {
    return (
        <button type="button" onClick={props.onClick} className="-ml-2 mt-[6px] h-6 text-sm text-black/[.60] font-normal rounded px-1 pb-1 underline underline-offset-[3px] decoration-[0.5px] transition hover:decoration-red-400 hover:bg-red-50/[.50]">
            {props.label || "Удалить"}
        </button>
    )
}