import React from "react";
import {AdministrativeRolesFormCreate} from "../../components/administrative-roles/AdministrativeRolesFormCreate";

interface FormProps{

}

export function CreateAdministrativeRolesForm(props: FormProps) {


    return (
        <AdministrativeRolesFormCreate/>
    )
}