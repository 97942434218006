import React from "react";

interface BlockProps {
    text: string | null,
    label?: string,
    standardHeightRequired?: boolean
}

export function BlockForDisplayingText(props: BlockProps) {
    const height: string = props.standardHeightRequired || !props.text ? "h-9 py-1" : "h-min py-1";
    const text = parseHtmlString(props.text);

    function parseHtmlString(text: string | null) {
        if (!text) return ['–'];
        return text.split(/<\/?[^>]+>/).filter(text => text.trim() !== '');
    }

    return (
        <label
            className="text-base font-normal"
        > {props.label}
            <div className={`${height} bg-white border-2 border-gray-300 rounded-md px-3 mt-[2px] mb-[1px] font-light text-base w-[423px]`}>
                {text.map(string => <p>{string.split("\n").map(line => line && <span key={line}>{line}<br/></span>)}</p>)}
            </div>
        </label>
    )
}