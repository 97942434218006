import React from "react";

interface ButtonProps {
    onClick: () => void,
    name?: string,
    marginBottom?: string
}
export function AddButtonText (props: ButtonProps) {
    const marginBottom = props.marginBottom || 'mb-[2px]'

    return (
        <button type="button" onClick={props.onClick} className={`${marginBottom} h-6 text-sm text-black/[.60] font-normal rounded px-1 pb-1 underline underline-offset-[3px] decoration-[0.5px] transition hover:decoration-blue-400 hover:bg-blue-50/[.70]`}>
            {props.name !== undefined ? props.name : "Добавить"}
        </button>
    )
}