import {Button} from "../ui/buttons/Button";
import React, {useEffect, useState} from "react";
import {SeparatingLine} from "../ui/common/SeparatingLine";
import {formButtonGroupStyle, formColumnsStyle, formStyle} from "../../utils/TailwindStyles";
import {SubmitHandler, useForm} from "react-hook-form";
import {UserCard} from "../../model/security/UserCard";
import {useUpdateUserProfileMutation} from "../../store/backend-api/backend.api";
import {UpdateUserProfile} from "../../model/security/UserProfile";
import {useNavigate} from "react-router-dom";
import {BackButton} from "../ui/buttons/BackButton";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import {PiEyeLight, PiEyeSlashLight} from "react-icons/pi";

interface FormProps {
    data: UserCard
}

interface FormFields {
    username: string,
    password: string,
    phoneNumber: string,
    email: string
}

export function ProfileEdit(props: FormProps) {
    const [doUpdateUserProfile, {isSuccess: isSuccessUpdate}] = useUpdateUserProfileMutation()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const {control, handleSubmit, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: {
            username: props.data.username,
            password: '',
            phoneNumber: props.data.phoneNumber || '',
            email: props.data.email || ''
        }
    })

    const onSubmit: SubmitHandler<FormFields> = data => {
        const newData: UpdateUserProfile = {
            username: data.username,
            password: data.password || null,
            phoneNumber: data.phoneNumber || null,
            email: data.email || null
        }
        doUpdateUserProfile({id: props.data.id, body: newData})
    }

    useEffect(() => {
        if (isSuccessUpdate) {
            navigate("/profile")
        }
    }, [isSuccessUpdate]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
            <div className="flex flex-row justify-end">
                <BackButton/>
            </div>
            <div className={formColumnsStyle}>
                <ControlledInputField control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} name={"username"} label={"Имя пользователя (login)"} additionalStyles={"w-[423px]"}/>
                <label
                    className="text-base font-normal"
                >
                    <div className="flex flex-row gap-x-2">
                        <span>Пароль</span>
                        {showPassword &&
                            <PiEyeLight className="mt-[5px]" onClick={() => setShowPassword(false)}/>}
                        {!showPassword &&
                            <PiEyeSlashLight className="mt-[5px]" onClick={() => setShowPassword(true)}/>}
                    </div>
                    <ControlledInputField control={control} rules={{
                        minLength: {
                            value: 8,
                            message: "Длина пароля не менее 8 символов"
                        }
                    }} name={"password"} placeholder={"********"} type={showPassword ? "text" : "password"}
                                          additionalStyles={"w-[423px]"}/>
                </label>
                <ControlledInputField control={control} rules={{
                    pattern: {
                        value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                        message: "Введите не менее 6 символов: цифры, тире или пробелы"
                    }
                }} name={"phoneNumber"} label={"Номер телефона"} additionalStyles={"w-[423px]"}/>
                <ControlledInputField control={control} rules={{
                    pattern: {
                        value: /^(?!\.)(""([^""\r\\]|\\[""\r\\])*""|([-a-z0-9!#$%&'*+/=?^_`{|}~]|(?<!\.)\.)*)(?<!\.)@[a-z0-9][\w\.-]*[a-z0-9]\.[a-z][a-z\.]*[a-z]$/,
                        message: "Введите email в формате post@domen.zone"
                    }
                }} name={"email"} label={"Почта (e-mail)"} additionalStyles={"w-[423px]"}/>
            </div>
            {isDirty && <>
                <SeparatingLine forSeparatingButtons={true}/>
                <div className={formButtonGroupStyle}>
                    <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onSubmit)}/>
                </div>
            </>}
        </form>
    )
}