import {GuideFilter} from "../filters/GuideFilter";

export interface MeasurementOkeiGuideFilter extends GuideFilter{
    sortedField: MeasurementOkeiSortedField | null
}

export enum MeasurementOkeiSortedField {
    ID = "ID",
    NAME = "NAME",
    ABBREVIATION = "ABBREVIATION",
    OKEI_TYPE = "OKEI_TYPE"
}