import React from "react";
import {Table, TableColumn} from "../../tables/Table";
import {productionOrderStatusToString} from "../../../data/ProductionOrderData";
import {ProgressButton} from "../../ui/buttons/ProgressButton";
import {useNavigate} from "react-router-dom";
import {TablePage} from "../../../model/utils/TablePage";
import {ProductionOrderJournalRow} from "../../../model/production-order/ProductionOrder";
import {useFiltersActions} from "../../../hooks/useFiltersActions";
import {TableFilterKeys} from "../../../model/filters/TableFiltersDefinitions";

interface TableProps {
    data: TablePage<ProductionOrderJournalRow>
}

export function ProductionOrderJournalTable(props: TableProps) {
    const {updateFilterPage} = useFiltersActions()

    const navigate = useNavigate()

    const numberColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'П/№',
        extractCellContent: row => row.documentNumber
    }

    const priorityColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Очередь',
        extractCellContent: row => row.priority
    }

    const productionLineColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Производственная линия',
        extractCellContent: row => row.productionLine?.name || "–"
    }

    const workOrderNumberColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: '№ Work',
        extractCellContent: row => row.workOrderNumber
    }

    const mainProductColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Основная продукция',
        extractCellContent: row => row.mainProduct ? `${row.mainProduct?.nomenclature?.name}, ${row.mainProduct?.nomenclature?.measurementUnit?.name || "–"}` : "–"
    }

    const additionalEquipmentColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Доп. комплектация',
        displayAtList: true,
        extractCellContent: row => row.additionalEquipment.length === 0 ? "–" : row.additionalEquipment.map(equipment => equipment.nomenclature.name)
    }

    const productionOrderStatusColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Статус заказа',
        extractCellContent: row => productionOrderStatusToString.get(row.status)
    }

    const productionTaskProgressColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Прогресс выполнения заказа',
        showOnOneTableRow: true,
        extractCellContent: row => row.progress.length === 0 ? "–" : row.progress.map((progress, index) => <ProgressButton data={progress} key={index}/>)
    }

    const planningProductionEndTimeColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Требуется завершить',
        extractCellContent: row => {
            const date = new Date(row.planningCompletionDate)
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`
        }
    }

    const productionEndTimeColumn: TableColumn<ProductionOrderJournalRow> = {
        columnName: 'Завершён',
        extractCellContent: row => row.completionTime
    }

    function actionOnRowClick(row: ProductionOrderJournalRow) {
        navigate(`/production-order?number=${row.documentNumber}`)
    }

    return (
        <Table<ProductionOrderJournalRow>
            tableColumns={[numberColumn, priorityColumn, productionLineColumn, workOrderNumberColumn, mainProductColumn, additionalEquipmentColumn, productionOrderStatusColumn, productionTaskProgressColumn, planningProductionEndTimeColumn, productionEndTimeColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}