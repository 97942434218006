import React from "react";

interface TableProps {
    creationTime: string,
    addedInLineTime: string,
    productionStartTime: string,
    completionTime: string | null
}

export function ProductionOrderExecutionTable(props: TableProps) {

    return (
        <table>
            <tbody>
            <tr>
                <td className="border border-black text-base font-light px-2">Заказ создан</td>
                <td className="border border-black text-base font-light px-2">{props.creationTime || "–"}</td>
            </tr>
            <tr>
                <td className="border border-black text-base font-light px-2">Заказ добавлен в очередь</td>
                <td className="border border-black text-base font-light px-2">{props.addedInLineTime || "–"}</td>
            </tr>
            <tr>
                <td className="border border-black text-base font-light px-2">Заказ запущен в производство</td>
                <td className="border border-black text-base font-light px-2">{props.productionStartTime || "–"}</td>
            </tr>
            <tr>
                <td className="border border-black text-base font-light px-2">Заказ завершен</td>
                <td className="border border-black text-base font-light px-2">{props.completionTime || "–"}</td>
            </tr>
            </tbody>
        </table>
    )
}