import React from "react";
import {useNavigate} from "react-router-dom";
import {Tab, Tabs} from "../tabs/Tabs";
import {BackButton} from "../ui/buttons/BackButton";
import {ProductionTasksTable} from "./additional-components/ProductionTasksTable";
import {
    PositionsForProductionOrderExecution,
    TasksForProductionOrderExecution
} from "../../model/production-order-execution/ProductionOrderExecution";
import {PositionsComponent} from "./additional-components/PositionsComponent";
import {formStyle} from "../../utils/TailwindStyles";

interface FormProps {
    positions: PositionsForProductionOrderExecution,
    tasks: TasksForProductionOrderExecution,
    tabId: number | null
}

export function ProductionOrderExecutionForm(props: FormProps) {
    const selectedTabId: number = props.tabId !== null ? props.tabId : 1
    const navigate = useNavigate()
    const productionOrderDocumentNumber: number = props.positions.productionOrderDocumentNumber
    const tabs: Tab[] = [
        {
            id: 0,
            label: "Позиции заказа"
        },
        {
            id: 1,
            label: "Производственные задания"
        },

    ]

    function onClickTab (tabId: number) {
        navigate(`/production-order/execution?id=${productionOrderDocumentNumber}&tab=${tabId}`)
    }

    return (
        <div className={formStyle}>
            <div className="flex flex-row justify-between">
                <Tabs tabs={tabs} selectedTabId={selectedTabId} onClick={onClickTab}/>
                <BackButton onClick={() => navigate('/production-order/execution/document-journal')}/>
            </div>
            {selectedTabId === 0 && <div>
                <PositionsComponent positions={props.positions}/>
            </div>}
            {selectedTabId === 1 && <div>
                <ProductionTasksTable tasks={props.tasks}/>
            </div>}
        </div>
    )
}