import {useLazyGetUserCardQuery} from "../../store/backend-api/backend.api";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useEffect} from "react";
import {ProfileRead} from "../../components/profile/ProfileRead";

export function ReadProfile() {
    const [doGetUserCard, {data: userCard}] = useLazyGetUserCardQuery()
    const tokens = useAppSelector(state => state.localStorage.tokens)

    useEffect(() => {
        if (tokens) {
            doGetUserCard()
        }
    }, [tokens]);

    return (
        <ProfileRead data={userCard}/>
    )
}