import React from "react";

interface ButtonProps {
    onClick: () => void
}

export function SaveButton (props: ButtonProps) {
    return (<button type="button" onClick={props.onClick}
                  className="h-9 border-[1.5px] border-black/[.30] rounded-md px-3 pt-1 pb-2 ml-6 text-base font-normal text-black/[.67] transition hover:underline hover:underline-offset-[3px] hover:decoration-[0.5px]">
            Сохранить
        </button>

    )
}

//hover:bg-gray-100/[.40]