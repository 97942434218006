import React, {useEffect, useState} from "react";
import {useUploadNomenclatureAttachmentsMutation} from "../../../store/backend-api/backend.api";

interface ComponentProps {
    nomenclatureId: number
}

export function FilesUploader(props: ComponentProps) {
    const [doUploadNomenclatureAttachment] = useUploadNomenclatureAttachmentsMutation()
    const [files, setFiles] = useState<FileList | null>(null)
    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        setFiles(event.target.files)
    }

    useEffect(() => {
        if (files) {
            const formData = new FormData()
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i])
            }
            doUploadNomenclatureAttachment({nomenclatureId: props.nomenclatureId, body: formData})
        }
    }, [files]);

    return (
        <label className="h-9 border-[1.5px] border-black/[.30] rounded-md px-3 pt-1 pb-2 ml-6 text-base font-normal text-black/[.67] transition hover:underline hover:underline-offset-[3px] hover:decoration-[0.5px]">
            Загрузить файлы на сервер
            <input type="file" name="inputName" multiple={true} className="hidden" onChange={handleFileChange}/>
        </label>
    )
}