import React, {useState} from "react";
import Select, {GroupBase, StylesConfig} from "react-select";
import {OptionType} from "../../../../model/option-type/OptionType";
import {Option} from "./NewPaginateAsyncSelect";

interface SelectProps<T> {
    options: OptionType<T>[],
    onChange: Function,
    placeholder?: string,
    isDisabled?: boolean,
    isClearable?: boolean,
    width?: string,
    defaultOption?: OptionType<T>
}


export function NewSelect<T>(props: SelectProps<T>) {
    const selectStyles: StylesConfig<OptionType<T>, false, GroupBase<OptionType<T>>> = {
        control: (styles: any, state) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                borderRadius: '6px',
                minHeight: '36px',
                maxHeight: "36px",
                width: props.width ? props.width : "423px",
                backgroundColor: state.isDisabled ? "hsl(0, 0%, 100%)" : "hsl(0, 0%, 100%)",
                border: state.isDisabled ? "2px solid rgb(209 213 219)" : "2px solid rgb(156 163 175 / 80%)",
                marginTop: "2px",
                marginBottom: "-1px",
                flexGrow: "1"

            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                visibility: "hidden"
            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                paddingTop: "0px",
                paddingBottom: "0px"
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                marginLeft: "1px",
            };
        },
        singleValue: (styles: any, state) => {
            return {
                ...styles,
                color: state.isDisabled ? 'black' : 'black',
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                color: "rgba(156, 163, 175, 0.8)"
            };
        },
        option: (styles: any) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
            };
        },
        menu: (styles: any) => {
            return {
                ...styles,
                width: props.width ? props.width : "423px",
            }
        }
    }
    const [selectedOption, setSelectedOption] = useState<OptionType<T> | null>(props.defaultOption || null);

    return (<Select options={props.options}
                    placeholder={props.placeholder || "Не выбрано"}
                    isDisabled={props.isDisabled || false}
                    isClearable={props.isClearable || true}
                    styles={selectStyles}
                    onChange={(option) => {
                        setSelectedOption(option);
                        props.onChange(option);
                    }}
                    value={selectedOption}/>

    )
}