export interface AdministrativeAndFunctionalZone {
    id: number;
    name: string;
    purpose: AdministrativeAndFunctionalZonePurpose;
    supervisorId: number | null;
}

export interface CreateOrUpdateAdministrativeAndFunctionalZone {
    name: string;
    purpose: AdministrativeAndFunctionalZonePurpose;
    supervisorId: number | null;
}

export enum AdministrativeAndFunctionalZonePurpose {
    WAREHOUSE = "WAREHOUSE",
    PRODUCTION_AREA = "PRODUCTION_AREA",
    WORKSHOP = "WORKSHOP",
    PRODUCTION_LINE = "PRODUCTION_LINE"
}