export interface NomenclatureForm {
    id: number | null,
    name: string,
    measurementUnitId: number,
    sku: string | null,
    drawingNumber: string | null,
    additionalSku: AdditionalSku[],
    comment: string,
    rawMaterial: RawMaterial | null,
    readyProduct: ReadyProduct | null,
    uncompletedManufacturing: UncompletedManufacturing | null
}

export interface NomenclatureAttachment {
    fileId: string,
    fileName: string,
    creationTime: string
}

export interface AdditionalSku {
    value: string,
    comment: string,
}

export interface RawMaterial {
}

export interface UncompletedManufacturing {
}

export interface ReadyProduct {
    purposeReproducibility: PurposeReproducibility,
    type: WorkReadyProductType
}

export enum PurposeReproducibility {
    SERIAL_PRODUCTION = "SERIAL_PRODUCTION",
    CONTRACT_PRODUCTION = "CONTRACT_PRODUCTION",
    EXPERIMENTAL_PRODUCTION = "EXPERIMENTAL_PRODUCTION"
}

export enum WorkReadyProductType {
    AUTOMOBILE_SCALES = "AUTOMOBILE_SCALES",        // Автомобильные весы
    WAGON_SCALES = "WAGON_SCALES",                  // Вагонные весы
    PLATFORM_SCALES = "PLATFORM_SCALES",            // Платформенные весы
    PLASMA = "PLASMA",                              // Плазма
    LIVESTOCK_SCALES = "LIVESTOCK_SCALES",          // Скотские весы
    CONVEYOR_SCALES = "CONVEYOR_SCALES",            // Конвейерные весы
    METAL_STRUCTURES = "METAL_STRUCTURES",          // Металлоконструкции
    LASER_CUTTING = "LASER_CUTTING",                // Лазерная резка
    BENDING = "BENDING",                            // Гибка
    ELECTRONICS = "ELECTRONICS",                    // Электроника
    DISPENSERS_PACKAGING = "DISPENSERS_PACKAGING",  // Дозаторы/Фасовка
    CONTROL_WEIGHTS = "CONTROL_WEIGHTS",            // Контрольные груза
    MODERNIZATION = "MODERNIZATION",                // Модернизация
    CONSTRUCTION_INSTALLATION = "CONSTRUCTION_INSTALLATION",  // Строительство (монтаж)
    OPTIONAL_EQUIPMENT = "OPTIONAL_EQUIPMENT"   // Дополнительная комплектация
}

export enum ProductionNomenclatureClassifier {
    RAW_MATERIAL = "RAW_MATERIAL",
    READY_PRODUCT = "READY_PRODUCT",
    UNCOMPLETED_MANUFACTURING = "UNCOMPLETED_MANUFACTURING",
    NO_CATEGORY = "NO_CATEGORY"
}