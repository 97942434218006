import React, {useState} from "react";
import Select, {GroupBase, StylesConfig} from "react-select";
import {OptionType} from "../../../../model/option-type/OptionType";

interface SelectProps<T> {
    options: OptionType<T>[],
    onChange: (options: readonly OptionType<T>[]) => void,
    placeholder?: string,
    isDisabled?: boolean,
    isClearable?: boolean,
    width?: string,
    defaultOptions?: OptionType<T>[]
}


export function MultiSelect<T>(props: SelectProps<T>) {
    const selectStyles: StylesConfig<OptionType<T>, true, GroupBase<OptionType<T>>> = {
        control: (styles: any, state) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                borderRadius: '6px',
                minHeight: '36px',
                width: props.width ? props.width : "423px",
                backgroundColor: state.isDisabled ? "hsl(0, 0%, 100%)" : "hsl(0, 0%, 100%)",
                border: state.isDisabled ? "2px solid rgb(209 213 219)" : "2px solid rgb(156 163 175 / 80%)",
                marginTop: "2px",
                marginBottom: "-1px",
                flexGrow: "1"
            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                visibility: "hidden"
            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                paddingTop: "0px",
                paddingBottom: "0px"
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                marginLeft: "1px",
            };
        },
        singleValue: (styles: any, state) => {
            return {
                ...styles,
                color: state.isDisabled ? 'black' : 'black',
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                color: "rgba(156, 163, 175, 0.8)"
            };
        },
        option: (styles: any) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
            };
        },
        menu: (styles: any) => {
            return {
                ...styles,
                width: props.width ? props.width : "423px",
            }
        }
    }
    const [selectedOptions, setSelectedOptions] = useState<readonly OptionType<T>[] | null>(props.defaultOptions || null);

    return (<Select options={props.options}
                    placeholder={props.placeholder || "Не выбрано"}
                    isDisabled={props.isDisabled || false}
                    isClearable={props.isClearable || true}
                    isMulti={true}
                    styles={selectStyles}
                    onChange={(options) => {
                        setSelectedOptions(options);
                        props.onChange(options);
                    }}
                    value={selectedOptions}/>

    )
}