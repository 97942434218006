import {SortedOrder} from "../../model/utils/SortedOrder";
import React from "react";
import {MeasurementOkei} from "../../model/measurement/MeasurementOkei";
import {MeasurementOkeiSortedField} from "../../model/measurement/MeasurementOkeiGuideFilter";
import {TablePage} from "../../model/utils/TablePage";
import {TableColumn} from "../tables/Table";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useAppSelector} from "../../hooks/useAppSelector";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {useNavigate} from "react-router-dom";
import {useSelectedMeasurementOkeiActions} from "../../hooks/useSelectedMeasurementOkeiActions";
import {Table} from "../tables/Table";

export interface TableProps {
    data: TablePage<MeasurementOkei>
}


export function MeasurementOkeiTable(props: TableProps) {
    const {setSelectedMeasurementOkei} = useSelectedMeasurementOkeiActions()
    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER].filter;

    const navigate = useNavigate()

    const setSortingBy = (currentSortedField: MeasurementOkeiSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const idColumn: TableColumn<MeasurementOkei> = {
        columnName: 'ID',
        sortingFunction: setSortingBy(MeasurementOkeiSortedField.ID),
        extractCellContent: row => row.id,
        columnFieldName: MeasurementOkeiSortedField.ID
    }

    const nameColumn: TableColumn<MeasurementOkei> = {
        columnName: 'Наименование',
        sortingFunction: setSortingBy(MeasurementOkeiSortedField.NAME),
        extractCellContent: row => row.name,
        columnFieldName: MeasurementOkeiSortedField.NAME
    }

    const abbreviationColumn: TableColumn<MeasurementOkei> = {
        columnName: 'Условное обозначение',
        sortingFunction: setSortingBy(MeasurementOkeiSortedField.ABBREVIATION),
        extractCellContent: row => row.abbreviation,
        columnFieldName: MeasurementOkeiSortedField.ABBREVIATION
    }

    const okeiTypeColumn: TableColumn<MeasurementOkei> = {
        columnName: 'Группа в ОКЕИ',
        sortingFunction: setSortingBy(MeasurementOkeiSortedField.OKEI_TYPE),
        extractCellContent: row => row.okeiType,
        columnFieldName: MeasurementOkeiSortedField.OKEI_TYPE
    }

    function onRowClick(row: MeasurementOkei) {
        setSelectedMeasurementOkei(row)
        navigate(`/measurement-unit/new`)
    }

    return (
        <Table<MeasurementOkei>
            tableColumns={[idColumn, nameColumn, abbreviationColumn, okeiTypeColumn]}
            rows={props.data.rows}
            onRowClick={onRowClick}
            tableFilterKey={TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}