import {GuideFilter} from "../filters/GuideFilter";
import {ActivityStatus} from "./User";

export interface UserGuideFilter extends GuideFilter {
    registrationFrom: string | null,
    registrationTo: string | null,
    lastActivityFrom: string | null,
    lastActivityTo: string | null,
    sortedField: UserSortedField | null
    role: number[],
    status: ActivityStatus | null
}

export interface DateInterval {
    start: Date,
    end: Date | null
}

export enum UserSortedField {
    ID = "ID",
    NAME = "NAME",
    SUBJECT = "SUBJECT",
    ADMINISTRATIVE_ROLE = "ADMINISTRATIVE_ROLE",
    REGISTRATION_DATE_TIME = "REGISTRATION_DATE_TIME",
    LAST_ACTIVITY = "LAST_ACTIVITY"
}