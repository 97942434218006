import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InitialStateInterface {
    isNavBarVisible: boolean
}

const initialState: InitialStateInterface = {
    isNavBarVisible: window.innerWidth > 600
}

export const navBarSlice = createSlice({
    name: "navBar",
    initialState,
    reducers: {
        setIsNavBarVisible: (state, action: PayloadAction<boolean>) => {
            state.isNavBarVisible = action.payload
        }
    }
})

export const navBarReducer = navBarSlice.reducer
export const navBarActions = navBarSlice.actions