import ReactModal from "react-modal";
import {FiX} from "react-icons/fi";
import React, {useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {TablePage} from "../../model/utils/TablePage";
import {ProductionStage} from "../../model/production-stage/ProductionStage";
import {
    AdministrativeAndFunctionalZonePurpose
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {
    AdministrativeAndFunctionalZoneGuideFilter
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {ControlledPaginateAsyncSelect} from "../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {
    useLazySearchProductionStagesGuidePageQuery,
    useUpdateStageOfBillOfMaterialsMutation
} from "../../store/backend-api/backend.api";
import {OptionType} from "../../model/option-type/OptionType";
import {
    EditStageOfBillOfMaterialsRequest,
    Stage
} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {ControlledInputField} from "../ui/form-components/controlled/ControlledInputField";
import {Button} from "../ui/buttons/Button";
import {SeparatingLine} from "../ui/common/SeparatingLine";

interface ModalProps {
    modalForEditStageIsOpen: boolean,
    setModalForEditStageIsOpen: (value: boolean) => void,
    setEditableStageId: (value: number | null) => void
    stage: Stage
}

interface FormFields {
    productionStage: OptionType<number>,
    order: number
}

export function ModalForEditStage(props: ModalProps) {
    const [doSearchProductionStagesGuidePage] = useLazySearchProductionStagesGuidePageQuery()
    const [doUpdateStageOfBillOfMaterials, {isSuccess: isSuccessUpdate}] = useUpdateStageOfBillOfMaterialsMutation()
    const {control, handleSubmit, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues: {
            productionStage: {
                label: props.stage.productionStageGuideRow.name,
                value: props.stage.productionStageGuideRow.id
            },
            order: props.stage.order
        }
    })
    const onClickSaveButton: SubmitHandler<FormFields> = data => {
        const newStageData: EditStageOfBillOfMaterialsRequest = {
            productionStageId: data.productionStage.value,
            order: data.order
        }
        doUpdateStageOfBillOfMaterials({
            billOfMaterialsId: props.stage.billOfMaterialsId,
            stageId: props.stage.id,
            body: newStageData
        })
    }

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    useEffect(() => {
        if (isSuccessUpdate) {
            props.setModalForEditStageIsOpen(false)
            props.setEditableStageId(null)
        }
    }, [isSuccessUpdate]);

    return (
        <ReactModal isOpen={props.modalForEditStageIsOpen}
                    onRequestClose={() => {
                        props.setModalForEditStageIsOpen(false)
                        props.setEditableStageId(null)
                    }}>
            <div className="flex flex-row justify-end">
                <button type={"button"}
                        title="Закрыть"
                        onClick={() => {
                            props.setModalForEditStageIsOpen(false)
                            props.setEditableStageId(null)
                        }}
                        className="-mr-1 text-gray-400/[.60] rounded-[5px]">
                    <FiX className="h-8 w-8"/>
                </button>
            </div>
            <form onSubmit={handleSubmit(onClickSaveButton)}>
                <div className="flex flex-col gap-y-4 -mb-2">
                    <ControlledPaginateAsyncSelect control={control} rules={{
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        }
                    }} name={"productionStage"} label={"Этап производства"}
                                                   request={doSearchProductionStagesGuidePage}
                                                   mapFunc={(data: TablePage<ProductionStage>) =>
                                                       data.rows.map(row => ({label: `${row.name}`, value: row.id}))
                                                   } filter={{
                        pageNumber: 0,
                        pageSize: 10,
                        search: null,
                        sortOrder: null,
                        purpose: AdministrativeAndFunctionalZonePurpose.PRODUCTION_AREA,
                        sortedField: null
                    } as AdministrativeAndFunctionalZoneGuideFilter}/>
                    <ControlledInputField control={control}
                                          rules={{
                                              required: {
                                                  value: true,
                                                  message: "Это обязательное поле"
                                              },
                                              pattern: {
                                                  value: /^[1-9]\d*$/,
                                                  message: 'Введите положительное число',
                                              }
                                          }} name={"order"}
                                          label={"Порядковый номер"} additionalStyles={"w-[423px]"}/>
                </div>
                {isDirty && <>
                    <SeparatingLine/>
                    <div className="flex flex-row justify-center mt-5">
                        <Button buttonName={"Сохранить"} onClickButton={handleSubmit(onClickSaveButton)}/>
                </div></>}
            </form>
        </ReactModal>
    )
}