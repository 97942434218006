import {SortedOrder} from "../../../model/utils/SortedOrder";
import React from "react";
import {MeasurementSortedField} from "../../../model/measurement/MeasurementGuideFilter";
import {MeasurementUnit} from "../../../model/measurement/MeasurementUnit";
import {TablePage} from "../../../model/utils/TablePage";
import {TableColumn} from "../../tables/Table";
import {useNavigate} from "react-router-dom";
import {TableFilterKeys} from "../../../model/filters/TableFiltersDefinitions";
import {useFiltersActions} from "../../../hooks/useFiltersActions";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {Table} from "../../tables/Table";

export interface TableProps {
    data: TablePage<MeasurementUnit>
}

export function MeasurementUnitTable(props: TableProps) {
    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER].filter;

    const navigate = useNavigate()

    const setSortingBy = (currentSortedField: MeasurementSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const idColumn: TableColumn<MeasurementUnit> = {
        columnName: 'ID',
        sortingFunction: setSortingBy(MeasurementSortedField.ID),
        extractCellContent: row => row.id,
        columnFieldName: MeasurementSortedField.ID
    }

    const nameColumn: TableColumn<MeasurementUnit> = {
        columnName: 'Наименование',
        sortingFunction: setSortingBy(MeasurementSortedField.NAME),
        extractCellContent: row => row.name,
        columnFieldName: MeasurementSortedField.NAME
    }

    const abbreviationColumn: TableColumn<MeasurementUnit> = {
        columnName: 'Условное обозначение',
        sortingFunction: setSortingBy(MeasurementSortedField.ABBREVIATION),
        extractCellContent: row => row.abbreviation,
        columnFieldName: MeasurementSortedField.ABBREVIATION
    }

    const okeiCodeColumn: TableColumn<MeasurementUnit> = {
        columnName: 'Код в ОКЕИ',
        sortingFunction: setSortingBy(MeasurementSortedField.OKEI_CODE),
        extractCellContent: row => row.okeiCode,
        columnFieldName: MeasurementSortedField.OKEI_CODE
    }

    const okeiTypeColumn: TableColumn<MeasurementUnit> = {
        columnName: 'Группа в ОКЕИ',
        sortingFunction: setSortingBy(MeasurementSortedField.OKEI_TYPE),
        extractCellContent: row => row.okeiType,
        columnFieldName: MeasurementSortedField.OKEI_TYPE
    }

    const commentColumn: TableColumn<MeasurementUnit> = {
        columnName: 'Комментарий',
        sortingFunction: setSortingBy(MeasurementSortedField.COMMENT),
        extractCellContent: row => row.comment,
        columnFieldName: MeasurementSortedField.COMMENT
    }

    function actionOnRowClick(row: MeasurementUnit) {
        navigate(`/measurement-unit?id=${row.id}`)
    }

    return (
        <Table<MeasurementUnit>
            tableColumns={[idColumn, nameColumn, abbreviationColumn, okeiCodeColumn, okeiTypeColumn, commentColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}