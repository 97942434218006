import {useEffect, useRef} from "react";
import LoadingBar, {LoadingBarRef} from "react-top-loading-bar";

interface ComponentProps {
    isLoading: boolean
}

export function LoadingIndicator(props: ComponentProps) {
    const ref = useRef<LoadingBarRef | null>(null)

    useEffect(() => {
        if (ref.current) {
            if (props.isLoading) {
                ref.current.continuousStart();
            } else {
                ref.current.complete();
            }
        }
    }, [props.isLoading]);

    return (
        <LoadingBar ref={ref} color={"#3b82f6"}/>
    )
}