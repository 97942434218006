import React, {useEffect, useState} from "react";
import {Control, Controller} from "react-hook-form";
import {AsyncPaginate} from "react-select-async-paginate";
import {GroupBase, OptionsOrGroups, StylesConfig} from "react-select";
import {GuideFilter} from "../../../../model/filters/GuideFilter";
import {OptionType} from "../../../../model/option-type/OptionType";
import isEqual from "lodash.isequal";
import {useNavigate} from "react-router-dom";

const defaultFilter: GuideFilter = {
    pageNumber: 0,
    pageSize: 10,
    search: null,
    sortOrder: null
}

interface SelectProps<T> {
    control: Control<any>,
    rules: {},
    name: string,
    label?: string,
    defaultOption?: OptionType<T>,
    placeholder?: string,
    onChange?: Function,
    request: Function,
    //request: (filter: GuideFilter) => QueryActionCreatorResult<QueryDefinition<any, any, any, TablePage<T>>>,
    mapFunc: (data: any) => OptionType<T>[]
    filter?: GuideFilter
    isClearable?: boolean,
    isDisabled?: boolean,
    additionalOptions?: OptionType<T>[],
    selectedFieldIndex?: number,
    setSelectedFieldIndex?: (index: number) => void,
    setModalIsOpen?: (state: boolean) => void,
    navigateLink?: string
}

export function ControlledPaginateAsyncSelectForTable<T>(props: SelectProps<T>) {
    const navigate = useNavigate()
    const selectStyles: StylesConfig<OptionType<T>, false, GroupBase<OptionType<T>>> = {
        control: (styles: any, state) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                borderRadius: '0px',
                minHeight: '30px',
                maxHeight: "30px",
                whiteSpace: 'nowrap',
                wordBreak: 'break-word',
                maxWidth: 'auto',
                width: 'auto',
                minWidth: 'max-content',
                backgroundColor: state.isDisabled ? "hsl(0, 0%, 100%)" : "hsl(0, 0%, 100%)",
                border: '0px',
                flex: "1",
                overflow: "visible"
            };
        },
        indicatorSeparator: (styles: any) => {
            return {
                ...styles,
                visibility: "hidden"
            };
        },
        singleValue: (styles: any, state) => {
            return {
                ...styles,
                color: state.isDisabled ? 'black' : 'black',
                whiteSpace: 'nowrap',
                wordBreak: 'break-word',
                overflow: "visible",
                minWidth: 'fit-content',

            };
        },
        menuList: (styles: any) => {
            return {
                ...styles,
                paddingTop: "0px",
                paddingBottom: "0px",
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: '-6px',
                paddingBottom: '0',
                paddingLeft: '8px',
                paddingRight: '8px',
                minWidth: 'max-content'
            };
        },
        indicatorsContainer: (styles: any) => {
            return {
                ...styles,
                marginTop: '-1px'
            };
        },
        dropdownIndicator: (styles: any) => {
            return {
                ...styles,
                marginTop: "-2px",
                color: "rgba(156, 163, 175, 0.8)"
            };
        },
        clearIndicator: (styles: any) => {
            return {
                ...styles,
                marginRight: "-10px"
            }
        },
        option: (styles: any) => {
            return {
                ...styles,
                textAlign: 'left',
                fontWeight: 300,
                paddingLeft: '10px'
            };
        },
        input: (styles) => {
            return {
                ...styles,
                paddingTop: '0',
            }
        },
        menu: (styles: any) => {
            return {
                ...styles
            }
        }
    };

    const loadOptions = async (searchQuery: string, prevOptions: OptionsOrGroups<OptionType<T>, GroupBase<OptionType<T>>>, {page}: {
        page?: number
    } = {}) => {
        const templateFilter = props.filter !== undefined ? props.filter : defaultFilter
        const filter = {
            ...templateFilter,
            search: searchQuery.length > 0 ? searchQuery : null,
            pageNumber: page!
        } as GuideFilter

        const options: OptionType<T>[] = []

        if (prevOptions.length === 0) {
            if (props.additionalOptions !== undefined) {
                options.push(...props.additionalOptions)
            }

            if (props.defaultOption !== undefined) {
                options.push(props.defaultOption)
            }
        }

        const data = (await props.request(filter).unwrap());
        const optionsFromData = props.mapFunc(data)
            .filter((option: OptionType<T>) => !isEqual(option.value, props.defaultOption?.value))
        options.push(...optionsFromData)

        return {
            options,
            hasMore: data!.pageNumber < data!.pageCount,
            additional: {
                page: page! + 1
            }
        }
    }

    return (
        <Controller control={props.control} name={props.name} rules={props.rules}
                    defaultValue={props.defaultOption !== undefined ? props.defaultOption : undefined}
                    render={({field: {onChange, value}, fieldState: {error}}) =>
                        <>
                            <label
                                className="text-base font-normal"
                            > {props.label}
                                <AsyncPaginate
                                    menuPosition="fixed"
                                    loadOptions={loadOptions}
                                    value={value}
                                    onChange={(event) => {
                                        if (props.setModalIsOpen !== undefined) {
                                            if (props.additionalOptions !== undefined && event?.value === props.additionalOptions[0].value && props.selectedFieldIndex !== undefined && props.setSelectedFieldIndex !== undefined) {
                                                props.setSelectedFieldIndex(props.selectedFieldIndex)
                                                props.setModalIsOpen(true)
                                            }
                                        }
                                        if (props.navigateLink !== undefined) {
                                            if (props.additionalOptions !== undefined && event?.value === props.additionalOptions[0].value) {
                                                navigate(props.navigateLink)
                                            }
                                        }
                                        onChange(event)
                                    }}
                                    additional={{
                                        page: 0
                                    }}
                                    isDisabled={props.isDisabled !== undefined ? props.isDisabled : false}
                                    isClearable={props.isClearable !== undefined ? props.isClearable : true}
                                    placeholder={props.placeholder ?? "Не выбрано"}
                                    styles={selectStyles}

                                />
                                {error &&
                                    <p className="border-t text-sm font-normal text-red-400 px-1">{error.message}</p>}
                            </label>
                        </>}/>
    )
}