import {Button} from "../ui/buttons/Button";
import React, {useEffect} from "react";
import {useLazyGetAllFilesQuery} from "../../store/backend-api/backend.api";

interface DownloadFilesButtonProps {
    name: string
    filesIds: string[]
}

export function DownloadFilesButton(props: DownloadFilesButtonProps) {
    const [getAllFiles, {data: files, isSuccess}] = useLazyGetAllFilesQuery();

    function getFilesIds(filesIds: string[]) {
        return filesIds.map(fileId => `id=${fileId}`).join('&')
    }

    useEffect(() => {
        if (isSuccess && files) {
            const url = window.URL.createObjectURL(files);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'attachments.zip';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        }
    }, [files]);

    return <Button buttonName={props.name} onClickButton={() => getAllFiles(getFilesIds(props.filesIds))}/>
}