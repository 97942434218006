import {ProductionTaskStatus} from "../../model/production-order-execution/ProductionOrderExecution";

export function getTableRowBgColor(status: ProductionTaskStatus) {
    if (status === ProductionTaskStatus.COMPLETED) {
        return "bg-green-500/[.05]"
    }
    if (status === ProductionTaskStatus.IN_PRODUCTION) {
        return "bg-yellow-500/[.06]"
    }
    if (status === ProductionTaskStatus.UNAVAILABLE) {
        return "bg-gray-500/[.045] text-black/[.50]"
    }
    return "bg-red-500/[.06]"
}

export function getTableRowTitle(status: ProductionTaskStatus) {
    if (status === ProductionTaskStatus.COMPLETED) {
        return "Выполнен"
    }
    if (status === ProductionTaskStatus.IN_PRODUCTION) {
        return "В работе"
    }
    if (status === ProductionTaskStatus.UNAVAILABLE) {
        return "Недоступен"
    }
    return "Ожидает выполнения"
}