import React, {useEffect} from "react";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useSearchTechnologicalOperationGuidePageQuery} from "../../store/backend-api/backend.api";
import {TechnologicalOperationTable} from "../../components/technological-operation/table/TechnologicalOperationTable";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {GuideTopBox} from "../../components/ui/table-components/GuideTopBox";
import {useDataActions} from "../../hooks/useDataActions";
import {DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER} from "../../model/filters/DefaultFilters";


export function TechnologicalOperationGuide() {
    const {addData} = useDataActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER].filter;

    const data = useAppSelector(state => state.data.data[TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER])

    const {data: tablePage} = useSearchTechnologicalOperationGuidePageQuery(filter);

    useEffect(() => {
        if (tablePage) {
            if (!data || data.rows.length < (tablePage.pageNumber * DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER.pageSize) + 1) {
                addData({key: TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER, data: tablePage})
            }
        }

    }, [tablePage])

    return (
        <div className="space-y-3.5 h-[98%]">
            <div>
                <div className="min-w-full flex flex-row">
                    <GuideTopBox tableFilterKey={TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER}
                                 createNavigationURL={"/technological-operation/new"}
                                 searchInfo={"Поиск по наименованию."}/>
                </div>
            </div>
            <div className="h-[90%] bg-transparent rounded-2xl">
                <div className="bg-transparent rounded-2xl h-[100%]">
                    {data && <TechnologicalOperationTable data={data}/>}
                </div>
            </div>
        </div>
    )
}