import React, {useEffect, useState} from "react";
import {SubmitHandler, useFieldArray, useForm, useWatch} from "react-hook-form";
import {ControlledInputField} from "../../ui/form-components/controlled/ControlledInputField";
import {
    AdditionalSku,
    WorkReadyProductType,
    NomenclatureForm,
    ProductionNomenclatureClassifier,
    PurposeReproducibility,
    RawMaterial,
    ReadyProduct,
    UncompletedManufacturing
} from "../../../model/nomenclature/NomenclatureForm";
import {ControlledPaginateAsyncSelect} from "../../ui/form-components/controlled/ControlledPaginateAsyncSelect";
import {
    useDeleteNomenclatureMutation,
    useLazySearchMeasurementUnitGuidePageQuery
} from "../../../store/backend-api/backend.api";
import {TablePage} from "../../../model/utils/TablePage";
import {MeasurementUnit} from "../../../model/measurement/MeasurementUnit";
import {ControlledSelect} from "../../ui/form-components/controlled/ControlledSelect";
import {
    workReadyProductTypeToString,
    productionNomenclatureClassifierToString,
    purposeReproducibilityToString
} from "../../../data/NomenclatureData";
import {ControlledMultiSelect} from "../../ui/form-components/controlled/ControlledMultiSelect";
import {DeleteButtonText} from "../../ui/buttons/DeleteButtonText";
import {AddButtonText} from "../../ui/buttons/AddButtonText";
import {EditButton} from "../../ui/buttons/EditButton";
import {useNavigate} from "react-router-dom";
import {OptionType} from "../../../model/option-type/OptionType";
import {BackButton} from "../../ui/buttons/BackButton";
import {Button} from "../../ui/buttons/Button";
import {ModalForWorkingWithNomenclatureAttachments} from "../ModalForWorkingWithNomenclatureAttachments";
import {getDefaultValuesForNomenclatureForm} from "../../utils/Nomenclature";
import {formButtonGroupStyle, formColumnsStyle, formHeaderStyle, formStyle} from "../../../utils/TailwindStyles";
import {SeparatingLine} from "../../ui/common/SeparatingLine";

interface FormProps {
    readonly?: boolean,
    data?: NomenclatureForm,
    defaultMeasurementUnit?: OptionType<number>,
    onSubmit?: (nomenclature: NomenclatureForm) => void,
    buttonName?: string,
    isSuccessCreate?: boolean;
    createdNomenclatureId?: number,
    isSuccessUpdate?: boolean;
    editFromBillOfMaterialsUI?: boolean,
    onSaveFromBillOnMaterialsUI?: (state: boolean) => void,
    setCreatedNomenclature?: (state: OptionType<number>) => void
}

export interface NomenclatureFormFields {
    name: string,
    measurementUnitId: OptionType<number> | null,
    sku: string,
    drawingNumber: string,
    additionalSku: AdditionalSku[],
    productionNomenclatureClassifier: (ProductionNomenclatureClassifier | null)[],
    comment: string,
    rawMaterial: RawMaterial | null,
    purposeReproducibility: OptionType<PurposeReproducibility> | null,
    type: OptionType<WorkReadyProductType> | null,
    uncompletedManufacturing: UncompletedManufacturing | null
}

export function NomenclatureFormTemplate(props: FormProps) {
    const [doDeleteNomenclature, {isSuccess: isSuccessDelete}] = useDeleteNomenclatureMutation()
    const [doSearchMeasurementGuidePage] = useLazySearchMeasurementUnitGuidePageQuery()
    const [modalForWorkingWithAttachmentsIsOpen, setModalForWorkingWithAttachmentsIsOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const {handleSubmit, control, setValue, formState: {errors}} = useForm<NomenclatureFormFields>({
        mode: "onChange",
        defaultValues: getDefaultValuesForNomenclatureForm(props.data, props.defaultMeasurementUnit)
    })
    const currentFormValue = useWatch({control})
    const {fields, append, remove} = useFieldArray({
        name: "additionalSku",
        control
    })
    const onSubmit: SubmitHandler<NomenclatureFormFields> = data => {
        const nomenclature: NomenclatureForm = {
            id: props.data?.id || null,
            name: data.name,
            measurementUnitId: data.measurementUnitId!.value,
            sku: data.sku || null,
            drawingNumber: data.drawingNumber || null,
            additionalSku: data.additionalSku,
            comment: data.comment,
            readyProduct: data.productionNomenclatureClassifier[0] !== null ? {
                purposeReproducibility: data.purposeReproducibility!.value,
                type: data.type!.value
            } as ReadyProduct : null,
            rawMaterial: data.productionNomenclatureClassifier[1] !== null ? {} as RawMaterial : null,
            uncompletedManufacturing: data.productionNomenclatureClassifier[2] !== null ? {} as UncompletedManufacturing : null
        }
        if (props.onSubmit) {
            props.onSubmit(nomenclature)
        }
    }

    const inputWidth: string = "w-[423px]"
    useEffect(() => {
        if (isSuccessDelete) {
            navigate("/nomenclature/guide")
        }
        if (props.isSuccessCreate) {
            if (!props.editFromBillOfMaterialsUI) {
                navigate(`/nomenclature?id=${props.createdNomenclatureId}`)
            } else {
                props.setCreatedNomenclature!({label: currentFormValue.name!, value: props.createdNomenclatureId!})
                props.onSaveFromBillOnMaterialsUI!(false)
            }
        }
        if (props.isSuccessUpdate) {
            if (!props.editFromBillOfMaterialsUI) {
                navigate(`/nomenclature?id=${props.data!.id}`)
            } else {
                props.onSaveFromBillOnMaterialsUI!(false)
            }
        }
    }, [props.isSuccessUpdate, props.isSuccessCreate, isSuccessDelete]);

    return (<>
        <form onSubmit={handleSubmit(onSubmit)}
              className={props.editFromBillOfMaterialsUI ? "p-2 pt-[18px]" : formStyle}>
            {!props.editFromBillOfMaterialsUI &&
                <div className={formHeaderStyle}>
                    <p>ID: {props.data?.id ? props.data.id : "не определен"}</p>
                    <BackButton/>
                </div>}
            <div className={formColumnsStyle}>
                <ControlledInputField control={control} readonly={props.readonly}
                                      label={"Артикул"} name={"sku"} rules={{}} additionalStyles={inputWidth}/>
                <ControlledInputField control={control} readonly={props.readonly}
                                      label={"Номер чертежа"} name={"drawingNumber"} rules={{}}
                                      additionalStyles={inputWidth}/>
                <ControlledInputField control={control} readonly={props.readonly}
                                      label={"Наименование"} name={"name"} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    },
                    minLength: {
                        value: 1,
                        message: "Минимальная длина: 1"
                    }
                }} additionalStyles={inputWidth}/>
                <ControlledMultiSelect control={control} rules={{}} name={"productionNomenclatureClassifier"}
                                       label={"Производственный классификатор"}
                                       additionalStyles={"w-[423px] row-span-2"}
                                       options={[ProductionNomenclatureClassifier.READY_PRODUCT, ProductionNomenclatureClassifier.RAW_MATERIAL, ProductionNomenclatureClassifier.UNCOMPLETED_MANUFACTURING]}
                                       optionsToStringMap={productionNomenclatureClassifierToString}
                                       readonly={props.readonly}/>
                <ControlledPaginateAsyncSelect control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} label={"Единица измерения"} name={"measurementUnitId"} isDisabled={props.readonly}
                                               request={doSearchMeasurementGuidePage}
                                               mapFunc={(data: TablePage<MeasurementUnit>) =>
                                                   data.rows.map(row =>
                                                       ({label: `${row.name}`, value: row.id})
                                                   )}/>
                <ControlledInputField control={control} readonly={props.readonly}
                                      label={"Комментарий"} name={"comment"} rules={{}}
                                      additionalStyles={inputWidth}/>
            </div>
            <SeparatingLine label={"Артикулы"}/>
            {(props.readonly && currentFormValue!.additionalSku!.length === 0) && (
                <p className="text-base font-light">У данной номенклатуры дополнительные артикулы не добавлены.</p>)}
            {!props.readonly && (<div className="flex flex-row gap-6">
                <p className="mb-[6px] ml-6 min-w-[423px]">Артикул</p>
                <div className="flex flex-row justify-between w-[423px]">
                    <p className="mb-[6px]">Тип артикула</p>
                    <AddButtonText onClick={() => {
                        append({value: "", comment: ""})
                    }}/>
                </div>
            </div>)}
            <ol className="list-decimal ml-4 space-y-3">
                {fields.map((field, index) =>
                    <li key={field.id}>
                        <div className="flex flex-row gap-6">
                            <ControlledInputField control={control} rules={{
                                required: {
                                    value: true,
                                    message: "Это обязательное поле"
                                }
                            }} readonly={props.readonly} name={`additionalSku.${index}.value`}
                                                  additionalStyles={`${inputWidth} ml-2`}/>
                            <ControlledInputField control={control} rules={{
                                required: {
                                    value: true,
                                    message: "Это обязательное поле"
                                }
                            }} readonly={props.readonly} name={`additionalSku.${index}.comment`}
                                                  additionalStyles={`${inputWidth} ml-2`}/>
                            {(props.readonly === undefined || !props.readonly) && (
                                <DeleteButtonText onClick={() => {
                                    remove(index)
                                }}/>)}
                        </div>
                    </li>
                )}
            </ol>
            {currentFormValue.productionNomenclatureClassifier![0] !== null && (<>
                <SeparatingLine label={"Готовая продукция"}/>
                <div className="flex flex-row gap-6">
                    <ControlledSelect control={control} rules={{
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        }
                    }} isDisabled={props.readonly} name={"purposeReproducibility"} label={"Целевая воспроизводимость"}
                                      options={Array.from(purposeReproducibilityToString.entries()).map(([key, value]) => ({
                                          label: value,
                                          value: key
                                      }))}/>
                    <ControlledSelect control={control} rules={{
                        required: {
                            value: true,
                            message: "Это обязательное поле"
                        }
                    }} isDisabled={props.readonly} name={"type"}
                                      label={"Тип"}
                                      height={"120px"}
                                      options={Array.from(workReadyProductTypeToString.entries()).map(([key, value]) => ({
                                          label: value,
                                          value: key
                                      }))}/>
                </div>
            </>)}
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {props.data && props.data.id && <>
                    <Button buttonName={"Файлы"} onClickButton={() => setModalForWorkingWithAttachmentsIsOpen(true)}/>
                    <Button buttonName={"Удалить"} onClickButton={() => doDeleteNomenclature(props.data!.id!)}/>
                </>}
                {(!props.readonly) && (
                    <Button buttonName={props.buttonName!} onClickButton={handleSubmit(onSubmit)}/>)}
                {props.data && props.data.id && props.readonly && (<>
                    <EditButton navLink={`/nomenclature/edit?id=${props.data.id}`}/>
                </>)}
            </div>
            {props.data?.id && <ModalForWorkingWithNomenclatureAttachments
                isOpen={modalForWorkingWithAttachmentsIsOpen}
                setIsOpen={setModalForWorkingWithAttachmentsIsOpen}
                nomenclatureId={props.data.id!}/>}
        </form>
    </>)
}