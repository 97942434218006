import React, {useEffect, useState} from "react"
import {useAppSelector} from "../../hooks/useAppSelector";
import {NewCloseFilterMenuButton} from "../ui/buttons/NewCloseFilterMenuButton";
import {NewFilterMenu} from "../filter-menu/NewFilterMenu";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {getFilterType, TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";

interface MenuProps {
    tableFilterKey: keyof typeof TableFilterKeys
}

export function NewFilterMenuComponent ({tableFilterKey}: MenuProps) {
    const isMenuVisible = useAppSelector(state => state.sessionStorage.filters[TableFilterKeys[tableFilterKey]].isMenuVisible)
    const filterNodes = useAppSelector(state => state.filtersForMenuFilters[TableFilterKeys[tableFilterKey]])
    const [keyForElement, setKeyForElement] = useState<number>(1)
    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys[tableFilterKey]].filter

    const {resetFilterToDefault, toggleFiltersVisibility} = useFiltersActions()

    function handleClose() {
        toggleFiltersVisibility(TableFilterKeys[tableFilterKey])
    }

    function handleResetToDefault() {
        if (JSON.stringify(filter) !== JSON.stringify(getFilterType(TableFilterKeys[tableFilterKey]))) {
            resetFilterToDefault(TableFilterKeys[tableFilterKey])
        }
    }

    useEffect(() => {
        setKeyForElement(keyForElement + 1)
    }, [filterNodes]);

    return <>
        <div key={keyForElement}
            className={`${isMenuVisible ? 'mr-0' : 'mr-[-310px]'} min-w-[310px] w-[310px] transition-all duration-500 ease-in-out h-screen border-l-[0.5px] border-l-solid border-l-slate-400/[.80] overflow-y-auto overflow-x-hidden`}>
            <div className="flex flex-row justify-between border-b-[0.5px] border-b-solid border-b-slate-400/[.80]">
                <span className=" text-[17px] m-3 mt-2.5">Фильтры</span>
                <span onClick={handleResetToDefault} className="max-w-4 max-h-4 w-4 h-4 self-center cursor-pointer">
                <img title="Сбросить фильтры" alt="Сбросить все фильтры"
                     src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABzUlEQVR4nO2ZPUvDQBiAH78QcU+i4ODg4uDi4qSLi5uTm5Ojg+CsP8DRzU1wcHNychJBUUHqJ7SCg4ODIAgiguLHSfCEENrkkt7lLpAHXihtr32fvG/ueleAI0CUJA5JQJQsWmI7sUokTpnukTMS8ICGA0mmxR0wQApDwL0DyYoW8QAMo8gI8OhA0iIWT8AoGRkDnh1IXsh4AcbJyQTw6oDEGzBJm0wD7xYlPoAZNDELfFqQ+ALm0Mw88F2gxA+wgCEWCxRZxjCrBUisUBBrBiXWKZAOYMOAxKb87ELpBLY1SuwAXViiB9jVILEH9GKZPmC/DYlw69BvW6LCdUTGcBZRiTiGqCriGKKqiGOIqiKOIaqK/FEDroCA7ATyPPgYBypyKZ+vZ5QJ5BghL4Z1kfAQ/Ua+dgsMKnynHxnTUBxjXCSrjG9CQpeIqoxvSkKnSJqMUQndIq1kjEuEHBhYR4LIjFSPPc4zTSvRDSzJ/zJ07hC9SBWMViJOeKW25EG0DhE/JqI6NWtjCrguY2tlbbc0/CY3dp5F03i7JeEnzE7WZf7brSZnONV2CnK+xypZ1gnPhco0I89i57koo+Nn/IXB/JQ5Bc7b2FiFEie/O4/hhSfWkh8AAAAASUVORK5CYII="/>
                </span>
                <NewCloseFilterMenuButton onClose={handleClose}/>
            </div>
            <NewFilterMenu filterNodes={filterNodes}/>
        </div>
    </>
}