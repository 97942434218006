import {
    AdministrativeAndFunctionalZoneGuideRow
} from "../../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideRow";
import {TablePage} from "../../../model/utils/TablePage";
import {useNavigate} from "react-router-dom";
import {SortedOrder} from "../../../model/utils/SortedOrder";
import React from "react";
import {
    AdministrativeAndFunctionalZoneSortedField
} from "../../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {TableColumn} from "../../tables/Table";
import {purposesToString} from "../../../data/AdministrativeAndFunctionalZoneFormData";
import {TableFilterKeys} from "../../../model/filters/TableFiltersDefinitions";
import {useFiltersActions} from "../../../hooks/useFiltersActions";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {Table} from "../../tables/Table";

export interface TableProps {
    data: TablePage<AdministrativeAndFunctionalZoneGuideRow>
}

export function AdministrativeAndFunctionalZoneTable(props: TableProps) {
    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER].filter;

    const navigate = useNavigate()

    const setSortingBy = (currentSortedField: AdministrativeAndFunctionalZoneSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const idColumn: TableColumn<AdministrativeAndFunctionalZoneGuideRow> = {
        columnName: 'ID',
        extractCellContent: row => row.id,
        sortingFunction: setSortingBy(AdministrativeAndFunctionalZoneSortedField.ID),
        columnFieldName: AdministrativeAndFunctionalZoneSortedField.ID
    }
    const nameColumn: TableColumn<AdministrativeAndFunctionalZoneGuideRow> = {
        columnName: 'Наименование',
        extractCellContent: row => row.name,
        sortingFunction: setSortingBy(AdministrativeAndFunctionalZoneSortedField.NAME),
        columnFieldName: AdministrativeAndFunctionalZoneSortedField.NAME
    }
    const purposeColumn: TableColumn<AdministrativeAndFunctionalZoneGuideRow> = {
        columnName: 'Назначение зоны',
        extractCellContent: row => purposesToString.get(row.purpose),
        sortingFunction: setSortingBy(AdministrativeAndFunctionalZoneSortedField.PURPOSE),
        columnFieldName: AdministrativeAndFunctionalZoneSortedField.PURPOSE
    }

    function actionOnRowClick(row: AdministrativeAndFunctionalZoneGuideRow) {
        navigate(`/administrative-and-functional-zone?id=${row.id}`)
    }

    return (
        <Table<AdministrativeAndFunctionalZoneGuideRow>
            tableColumns={[idColumn, nameColumn, purposeColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}