import React from "react";

interface LineProps {
    label?: string,
    forSeparatingButtons?: boolean
}

export function SeparatingLine(props: LineProps) {
    const margin: string = props.forSeparatingButtons ? "my-6" : "mt-[11px]"
    return (
        <div className="flex flex-row my-3">
            {props.label && <div className="text-base font-light text-black/[.70] mr-6">{props.label}</div>}
            <div className={`${margin} border-b-[1px] border-slate-400/[.80] border-dotted h-1 grow`}></div>
        </div>
    )
}