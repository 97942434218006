import {
    WorkReadyProductType, ProductionNomenclatureClassifier,
    PurposeReproducibility
} from "../model/nomenclature/NomenclatureForm";

export const purposeReproducibilityToString = new Map<PurposeReproducibility, string>([
    [PurposeReproducibility.SERIAL_PRODUCTION, "Серийная продукция"],
    [PurposeReproducibility.CONTRACT_PRODUCTION, "Контрактное производство"],
    [PurposeReproducibility.EXPERIMENTAL_PRODUCTION, "Эксперементальное производство"]
])

export const workReadyProductTypeToString = new Map<WorkReadyProductType, string>([
    [WorkReadyProductType.AUTOMOBILE_SCALES, "Автомобильные весы"],
    [WorkReadyProductType.WAGON_SCALES, "Вагонные весы"],
    [WorkReadyProductType.PLATFORM_SCALES, "Платформенные весы"],
    [WorkReadyProductType.PLASMA, "Плазма"],
    [WorkReadyProductType.LIVESTOCK_SCALES, "Скотские весы"],
    [WorkReadyProductType.CONVEYOR_SCALES, "Конвейерные весы"],
    [WorkReadyProductType.METAL_STRUCTURES, "Металлоконструкции"],
    [WorkReadyProductType.LASER_CUTTING, "Лазерная резка"],
    [WorkReadyProductType.BENDING, "Гибка"],
    [WorkReadyProductType.ELECTRONICS, "Электроника"],
    [WorkReadyProductType.DISPENSERS_PACKAGING, "Дозаторы/Фасовка"],
    [WorkReadyProductType.CONTROL_WEIGHTS, "Контрольные груза"],
    [WorkReadyProductType.MODERNIZATION, "Модернизация"],
    [WorkReadyProductType.CONSTRUCTION_INSTALLATION, "Строительство (монтаж)"],
    [WorkReadyProductType.OPTIONAL_EQUIPMENT, "Доп. комплектация"]
])

export const productionNomenclatureClassifierToString = new Map<ProductionNomenclatureClassifier, string>([
    [ProductionNomenclatureClassifier.RAW_MATERIAL, 'Сырье'],
    [ProductionNomenclatureClassifier.READY_PRODUCT, 'Готовая продукция'],
    [ProductionNomenclatureClassifier.UNCOMPLETED_MANUFACTURING, 'Незавершенное производство'],
    [ProductionNomenclatureClassifier.NO_CATEGORY, 'Без категории']
])