import {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {startLoading, stopLoading} from "../slices/loading.slice";

export const LoadingMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (action.type.endsWith('/pending')) {
        api.dispatch(startLoading())
    } else if (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected')) {
        api.dispatch(stopLoading())
    }

    return next(action)
}