import React from "react";
import {FiX} from "react-icons/fi";
import {useFilterNodesActions} from "../../../hooks/useMenuFiltersActions";

export function CloseFilterMenuButton () {
    const {changeMenuVisibility} = useFilterNodesActions()

    return (<button type="button"
                    title="Закрыть"
                    onClick={() => changeMenuVisibility()}
                    className="text-gray-400/[.90] m-3">
            <FiX className="stroke-2 h-6 w-6"/>
        </button>
    )
}