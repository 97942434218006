export function Observer(element: any, callback: any) {
    function func(entries: any, observer: any) {
        if (entries[0].isIntersecting) {
            callback()
        }
    }
    const observer = new IntersectionObserver(func)
    if (element) {
        observer.observe(element)
    }

}