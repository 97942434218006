import {RefreshTokenResponse, Tokens} from "../../model/security/Tokens";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface TokensState {
    tokens: Tokens | null
}

const initialState: TokensState = {
    tokens: null
}

export const tokensSlice = createSlice({
    name: "tokens",
    initialState,
    reducers: {
        setTokens(state, action: PayloadAction<Tokens>) {
            state.tokens = action.payload
        },
        setRefreshedToken(state, action: PayloadAction<RefreshTokenResponse>) {
            if (state.tokens) {
                state.tokens = {
                    ...state.tokens,
                    accessToken: action.payload.accessToken,
                    accessTokenExpiry: action.payload.accessTokenExpiry
                } as Tokens
            }
        },
        invalidateTokens: (state) => {
            state.tokens = null
        }
    }
})

export const tokensActions = tokensSlice.actions
export const tokensReducer = tokensSlice.reducer