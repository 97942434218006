import React, {useEffect, useState} from "react"
import {
    TechnologicalProcessChartFormReadAndEdit
} from "../../components/technological-process-chart/TechnologicalProcessChartFormReadAndEdit";
import {useSearchParams} from "react-router-dom";
import {
    useLazyGetNomenclatureQuery, useLazySearchStepsOfBillOfMaterialsQuery, useSearchMaterialsOfBillOfMaterialsQuery,
    useSearchProductsOfBillOfMaterialsQuery, useSearchStagesOfBillOfMaterialsQuery, useSearchStepsOfBillOfMaterialsQuery
} from "../../store/backend-api/backend.api";

export function ReadAndEditTechnologicalProcessChartForm() {
    const [searchParams] = useSearchParams()
    const billOfMaterialsId = Number(searchParams.get("id"))
    const tabId = searchParams.get("tab") !== undefined ? Number(searchParams.get("tab")) : null
    const [doSearchStepsOfBillOfMaterials] = useLazySearchStepsOfBillOfMaterialsQuery()
    const {data: products, isSuccess: isSuccessSearchProducts} = useSearchProductsOfBillOfMaterialsQuery(billOfMaterialsId)
    const [doGetNomenclature, {data: nomenclature, isSuccess: isSuccessGetNomenclature}] = useLazyGetNomenclatureQuery()
    const {data: materials, isSuccess: isSuccessSearchMaterials} = useSearchMaterialsOfBillOfMaterialsQuery(billOfMaterialsId)
    const {data: steps, isSuccess: isSuccessSearchSteps} = useSearchStepsOfBillOfMaterialsQuery(billOfMaterialsId)
    const {data: stages, isSuccess: isSuccessSearchStages} = useSearchStagesOfBillOfMaterialsQuery(billOfMaterialsId)
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey((prevState) => prevState + 1)
    }, [products, materials, nomenclature, steps, stages])

    useEffect(() => {
        doSearchStepsOfBillOfMaterials(billOfMaterialsId)
    }, [products, materials]);

    useEffect(() => {
        if (isSuccessSearchProducts) {
            doGetNomenclature(products.find(product => product.isTarget)!.nomenclature.id)
        }
    }, [isSuccessSearchProducts]);

    return (
        <div key={key}>
            {isSuccessSearchProducts && isSuccessGetNomenclature && isSuccessSearchMaterials && isSuccessSearchSteps && isSuccessSearchStages && <TechnologicalProcessChartFormReadAndEdit products={products} nomenclature={nomenclature!} materials={materials} billOfMaterialsId={billOfMaterialsId} tabId={tabId} steps={steps} stages={stages}/>}
        </div>
    )
}