import React, {useEffect} from 'react';
import {
    useCreateTechnologicalOperationMutation,
    useDeleteTechnologicalOperationMutation,
    useUpdateTechnologicalOperationMutation
} from "../../../store/backend-api/backend.api";
import {BackButton} from "../../ui/buttons/BackButton";
import {ControlledInputField} from "../../ui/form-components/controlled/ControlledInputField";
import {SeparatingLine} from "../../ui/common/SeparatingLine";
import {Button} from "../../ui/buttons/Button";
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {
    CreateOrUpdateTechnologicalOperation,
    TechnologicalOperation
} from "../../../model/technological-operation/TechnologicalOperation";
import {formButtonGroupStyle, formColumnsStyle, formHeaderStyle, formStyle} from "../../../utils/TailwindStyles";

interface FormProps {
    data?: TechnologicalOperation,
    readonly?: boolean,
    buttonName?: string
}

interface FormFields {
    name: string
}

export function TechnologicalOperationFormTemplate (props: FormProps) {
    const navigate = useNavigate()
    const [doCreateTechnologicalOperation, {data: createdTechnologicalOperationId, isSuccess: isSuccessCreate}] = useCreateTechnologicalOperationMutation()
    const [doUpdateTechnologicalOperation, {isSuccess: isSuccessUpdate}] = useUpdateTechnologicalOperationMutation()
    const [doDeleteTechnologicalOperation, {isSuccess: isSuccessDelete}] = useDeleteTechnologicalOperationMutation()

    const defaultValues: FormFields = props.data ? {
        name: props.data.name,
    } : {
        name: ""
    }

    const {handleSubmit, control, formState: {isDirty}} = useForm<FormFields>({
        mode: "onChange",
        defaultValues
    })
    const onSubmit: SubmitHandler<FormFields> = data => {
        const technologicalOperation: CreateOrUpdateTechnologicalOperation = {
            name: data.name
        }
        if (props.data && isDirty) {
            doUpdateTechnologicalOperation({id: props.data.id, body: technologicalOperation})
        } else if (!props.data) {
            doCreateTechnologicalOperation(technologicalOperation)
        }
    }

    useEffect(() => {
        if (isSuccessCreate && createdTechnologicalOperationId) {
            navigate(`/technological-operation?id=${createdTechnologicalOperationId}`)
        }
        if (isSuccessUpdate) {
            navigate(`/technological-operation?id=${props.data!.id}`)
        }
        if (isSuccessDelete) {
            navigate(`/technological-operation/guide`)
        }
    }, [isSuccessCreate, isSuccessUpdate, isSuccessDelete]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
            <div className={formHeaderStyle}>
                <p>ID: {props.data?.id ? props.data.id : "не определен"}</p>
                <BackButton/>
            </div>
            <div className={formColumnsStyle}>
                <ControlledInputField control={control} rules={{
                    required: {
                        value: true,
                        message: "Это обязательное поле"
                    }
                }} name={"name"} label={"Наименование"} additionalStyles={"w-[423px]"} readonly={props.readonly}/>
            </div>
            <SeparatingLine forSeparatingButtons={true}/>
            <div className={formButtonGroupStyle}>
                {props.data &&
                    <Button buttonName={"Удалить"}
                            onClickButton={() => doDeleteTechnologicalOperation(props.data!.id)}/>}
                {!props.readonly &&
                    <Button buttonName={props.buttonName!} onClickButton={handleSubmit(onSubmit)}/>}
                {props.readonly &&
                    <Button buttonName={"Редактировать"} onClickButton={() => {
                        navigate(`/technological-operation/edit?id=${props.data!.id}`)
                    }}/>}
            </div>
        </form>
    )
}