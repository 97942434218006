import {useDispatch} from "react-redux";
import {useMemo} from "react";
import {bindActionCreators} from "@reduxjs/toolkit";
import {userPhotoActions} from "../store/slices/userPhoto.slice";

export function useUserPhotoActions() {
    const dispatch = useDispatch()

    return useMemo(() =>
        bindActionCreators(userPhotoActions, dispatch), [dispatch])
}