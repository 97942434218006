import React from "react";
import {Control, Controller} from "react-hook-form";

interface InputProps {
    control: Control<any>
    readonly?: boolean
    rules: {}
    name: string
    label?: string
    additionalStyles?: string
    defaultValue?: string | number
    placeholder?: string
    type?: string
}

export function ControlledInputField(props: InputProps) {
    const styles: string = props.readonly !== undefined && props.readonly ? `border-gray-300` : `border-gray-400/[.80] focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600`
    return (
        <Controller control={props.control} name={props.name} rules={props.rules}
                    defaultValue={props.defaultValue === undefined ? "" : props.defaultValue}
                    render={({field: {onChange, value}, fieldState: {error}}) =>
                        <label
                            className="text-base font-normal"
                        > {props.label}
                            <input
                                type={props.type ? props.type : "text"}
                                disabled={props.readonly !== undefined ? props.readonly : false}
                                className={`${props.additionalStyles} ${styles} block bg-white border-2 rounded-md px-3 mt-[2px] h-9 mb-[1px] font-light text-base`}
                                onChange={onChange}
                                value={value}
                                placeholder={props.placeholder !== undefined ? props.placeholder : "Не заполнено"}
                                readOnly={props.readonly !== undefined ? props.readonly : false}
                            />
                            {error && <p className="text-sm font-normal text-red-400">{error.message}</p>}
                        </label>
                    }/>
    )
}