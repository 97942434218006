import {useDispatch} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {tokensActions} from "../store/slices/tokens.slice";
import {useAppSelector} from "./useAppSelector";
import {useEffect, useState} from "react";
import {useLazyGetUserCardQuery} from "../store/backend-api/backend.api";

export const useTokensActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(tokensActions, dispatch)
}

export const useIsAuthorized = () => {
    const tokens = useAppSelector(state => state.localStorage.tokens)
    return !!tokens
}

export function useHasAuthority (authority: string | string[]) {
    const [hasAuthority, setHasAuthority] = useState<boolean>(false)
    const [doGetUserCard, {data: userCard}] = useLazyGetUserCardQuery()
    const tokens = useAppSelector(state => state.localStorage.tokens)

    useEffect(() => {
        if (userCard) {
            if (Array.isArray(authority)) {
                setHasAuthority(authority.some(value => userCard.authorities.includes(value)))
            }
            else {
                setHasAuthority(userCard.authorities.includes(authority))
            }
        }
    }, [userCard, authority]);

    useEffect(() => {
        if (tokens) {
            doGetUserCard()
        }
    }, [tokens]);

    return hasAuthority
}

