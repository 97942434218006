import React, {useEffect, useRef, useState} from "react";
import {ProductionStageProgress} from "../../../model/production-order/ProductionOrder";

export interface ProgressButtonProps {
    data: ProductionStageProgress
}

export function ProgressButton(props: ProgressButtonProps) {
    const [isVisible, setIsVisible] = useState(false)
    const progressButtonRef = useRef<HTMLDivElement>(null)
    const infoBoxRef = useRef<HTMLDivElement>(null)

    function colorButton() {
        if (props.data.percentageProgress === 0) {
            return "bg-gray-400/[.50]"
        }
        if (props.data.percentageProgress === 100) {
            return "bg-green-500/[.60]"
        }
        return "bg-yellow-200"
    }

    function productionStageNameAbbreviation(progress: ProductionStageProgress) {
        const abbreviation = progress.productionStage.name[0] || "–"
        const indicationOfPresencePostponedTasks = progress.hasPostponedTask ? "!" : "";
        return abbreviation + indicationOfPresencePostponedTasks;
    }

    useEffect(() => {
        if (isVisible && progressButtonRef.current && infoBoxRef.current) {
            const buttonRect = progressButtonRef.current.getBoundingClientRect()
            infoBoxRef.current.style.top = `${buttonRect.bottom + window.scrollY + 5}px`
            infoBoxRef.current.style.left = `${buttonRect.left + window.scrollX}px`
        }
    }, [isVisible]);

    return (
        <div>
            <div ref={progressButtonRef} onMouseOver={() => setIsVisible(true)} onMouseOut={() => setIsVisible(false)}
                 className={`my-1 ml-0 mr-2 w-6 h-6 rounded-[3px] flex justify-center ${colorButton()}`}>
                <p className="my-auto">{productionStageNameAbbreviation(props.data)}</p>
            </div>
            {isVisible && <div ref={infoBoxRef}
                               className={`whitespace-nowrap absolute translate-x-[-40%] bg-white rounded border-blue-500 border-[1px] z-50 px-2 py-1 mt-1 shadow-lg`}>
                <p>{props.data.productionStage.name || "–"}</p>
                <p>Прогресс: {props.data.percentageProgress}%</p>
                <p>Затраченное время: {props.data.timeSpent || "–"}</p>
            </div>}
        </div>
    )
}