import React from "react";
import {useNavigate} from "react-router-dom";
import {TablePage} from "../../model/utils/TablePage";
import {SortedOrder} from "../../model/utils/SortedOrder";
import {UserGuideRow} from "../../model/users/UserGuideRow";
import {UserSortedField} from "../../model/users/UserGuideFilter";
import {Table, TableColumn} from "../tables/Table";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {useFiltersActions} from "../../hooks/useFiltersActions";
import {useAppSelector} from "../../hooks/useAppSelector";


export interface TableProps {
    data: TablePage<UserGuideRow>
}

export function UsersTable(props: TableProps) {
    const navigate = useNavigate();
    const {updateFilterPage, updateFilterAndClearData} = useFiltersActions()

    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[TableFilterKeys.USER_GUIDE_FILTER].filter;

    const setSortingBy = (currentSortedField: UserSortedField) => () => {
        if (filter.sortedField !== currentSortedField) {
            updateFilterAndClearData({
                key: TableFilterKeys.USER_GUIDE_FILTER,
                value: {...filter, sortOrder: SortedOrder.ASC, sortedField: currentSortedField, pageNumber: 0}
            })
        } else {
            if (filter.sortOrder === "ASC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.USER_GUIDE_FILTER,
                    value: {...filter, sortOrder: SortedOrder.DSC, pageNumber: 0}
                })
            } else if (filter.sortOrder === "DSC") {
                updateFilterAndClearData({
                    key: TableFilterKeys.USER_GUIDE_FILTER,
                    value: {...filter, sortOrder: null, sortedField: null, pageNumber: 0}
                })
            }
        }
    }

    const idColumn: TableColumn<UserGuideRow> = {
        columnName: 'ID',
        columnFieldName: UserSortedField.ID,
        extractCellContent: row => row.id,
        sortingFunction: setSortingBy(UserSortedField.ID)
    }

    const nameColumn: TableColumn<UserGuideRow> = {
        columnName: 'Имя пользователя',
        columnFieldName: UserSortedField.NAME,
        extractCellContent: row => row.username,
        sortingFunction: setSortingBy(UserSortedField.NAME)
    }

    const subjectColumn: TableColumn<UserGuideRow> = {
        columnName: 'Физ. лицо',
        columnFieldName: UserSortedField.SUBJECT,
        extractCellContent: row => row.subject,
        sortingFunction: setSortingBy(UserSortedField.SUBJECT)
    }

    const administrativeRoleColumn: TableColumn<UserGuideRow> = {
        columnName: 'Административная роль',
        columnFieldName: UserSortedField.ADMINISTRATIVE_ROLE,
        extractCellContent: row => row.administrativeRole ? row.administrativeRole.name : "",
        sortingFunction: setSortingBy(UserSortedField.ADMINISTRATIVE_ROLE)
    }

    const registrationColumn: TableColumn<UserGuideRow> = {
        columnName: 'Регистрация',
        columnFieldName: UserSortedField.REGISTRATION_DATE_TIME,
        extractCellContent: row => row.registrationDate ? String(row.registrationDate) : undefined,
        sortingFunction: setSortingBy(UserSortedField.REGISTRATION_DATE_TIME)
    }

    const lastActivityColumn: TableColumn<UserGuideRow> = {
        columnName: 'Последняя активность',
        columnFieldName: UserSortedField.LAST_ACTIVITY,
        extractCellContent: row => row.lastActivity ? String(row.lastActivity) : "",
        sortingFunction: setSortingBy(UserSortedField.LAST_ACTIVITY)
    }

    function actionOnRowClick(row: UserGuideRow) {
        navigate(`/user?id=${row.id}`)
    }

    return (
        <Table<UserGuideRow>
            tableColumns={[idColumn, nameColumn, subjectColumn, administrativeRoleColumn, registrationColumn, lastActivityColumn]}
            rows={props.data.rows}
            onRowClick={actionOnRowClick}
            tableFilterKey={TableFilterKeys.USER_GUIDE_FILTER}
            pagination={{
                currentPageNumber: props.data.pageNumber,
                quantityOfPages: props.data.pageCount,
                setPageNumber: (newPageNumber) => {
                    updateFilterPage({key: TableFilterKeys.USER_GUIDE_FILTER, value: newPageNumber})
                }
            }}
        />
    )
}

