import {PiMagnifyingGlassMinusThin, PiMagnifyingGlassPlusThin, PiX} from "react-icons/pi";
import React, {useEffect, useState} from "react";
import ReactModal from "react-modal";
import {useGetFileQuery} from "../../../store/backend-api/backend.api";
import {NomenclatureAttachment} from "../../../model/nomenclature/NomenclatureForm";
import { TIFFViewer } from 'react-tiff'
import {cx} from "@emotion/css";
import {styles} from "./ModalForOpenFile.styles";

interface ModalProps {
    modalForOpenFileIsOpen: boolean,
    setModalForOpenFileIsOpen: (value: boolean) => void,
    selectedFile: NomenclatureAttachment,
    setSelectedFile: (value: NomenclatureAttachment | null) => void
}

type fileExtension = "img" | "pdf" | "tiff"

export function isFileOpenable(fileName: string) {
    const extensions = ["jpg", "jpeg", "png", "pdf", "tif", "TIF", "tiff", "TIFF"]
    return extensions.reduce((isOpenable, extension) => isOpenable || fileName.endsWith(extension) , false)
}

export function ModalForOpenFile(props: ModalProps) {
    const {data, isSuccess} = useGetFileQuery(props.selectedFile.fileId)
    const [tiffWidth, setTiffWidth] = useState<number>(100)
    const [fileURL, setFileURL] = useState<string | null>(null)
    const [fileExtension, setFileExtension] = useState<fileExtension | null>(null)

    ReactModal.defaultStyles = {
        overlay: {
            ...ReactModal.defaultStyles.overlay,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
        },
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: "90%",
            maxWidth: "90%",
            overflowX: 'auto',
            overflowY: 'auto'
        }
    }

    function getFileExtension(fileName: string) {
        if (fileName.endsWith("jpg") || fileName.endsWith("jpeg") || fileName.endsWith("png")) {
            setFileExtension("img")
        } else if (fileName.endsWith("pdf")) {
            setFileExtension("pdf")
        } else if (fileName.endsWith("tiff") || fileName.endsWith("TIFF") || fileName.endsWith("tif") || fileName.endsWith("TIF")) {
            setFileExtension("tiff")
        }
    }

    function setTiffScale(sign: string) {
        if (sign === '-') {
            const newWidth = tiffWidth > 50 ? tiffWidth - 10 : 50;
            setTiffWidth(newWidth);
        }
        if (sign === '+') {
            const newWidth = tiffWidth < 200 ? tiffWidth + 10 : 200;
            setTiffWidth(newWidth);
        }
    }

    useEffect(() => {
        getFileExtension(props.selectedFile.fileName)
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setFileURL(URL.createObjectURL(data))
        }
    }, [data]);

    return (
        <ReactModal isOpen={props.modalForOpenFileIsOpen}
                    onRequestClose={() => {
                        props.setModalForOpenFileIsOpen(false)
                        props.setSelectedFile(null)
                        setFileURL(null)
                        setFileExtension(null)
                    }}>
            <div className="flex flex-row justify-between gap-x-4">
                <p className="font-light text-lg -mt-1">{props.selectedFile.fileName}</p>
                {fileExtension === 'tiff' && <div className='flex flex-row gap-x-2 -mt-1'>
                    <PiMagnifyingGlassMinusThin className='w-6 h-6 cursor-pointer' onClick={() => setTiffScale('-')}/>
                    <div>{`${tiffWidth}%`}</div>
                    <PiMagnifyingGlassPlusThin className='w-6 h-6 cursor-pointer' onClick={() => setTiffScale('+')}/>
                </div>}
                <button type={"button"}
                        title="Закрыть"
                        onClick={() => {
                            props.setModalForOpenFileIsOpen(false)
                            props.setSelectedFile(null)
                            setFileURL(null)
                            setFileExtension(null)
                        }}
                        className="-mr-1 -mt-2 text-black/[.30] rounded-[5px]">
                    <PiX className="h-7 w-7"/>
                </button>
            </div>
            {!data && <p className="mt-6 text-base font-light">Загрузка...</p>}
            {fileURL && fileExtension === "img" &&
                <div className="max-w-[80vw] mt-2">
                    <img src={fileURL} title={props.selectedFile.fileName} className="w-[80vw] h-auto"
                         alt={props.selectedFile.fileName}></img>
                </div>}
            {fileURL && fileExtension === "pdf" &&
                <div className="max-w-[80vw] mt-2">
                    <iframe src={fileURL} title={props.selectedFile.fileName}
                            className="w-[80vw] h-[80vh]"></iframe>
                </div>}
            {fileURL && fileExtension === "tiff" &&
                <div className={cx(styles.canvas(tiffWidth))}>
                    <TIFFViewer
                        tiff={fileURL}
                        lang='ru'
                        paginate='bottom'
                        buttonColor='#141414'
                    />
                </div>}{}
        </ReactModal>
    )
}